import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { updateVerifyImage } from "store/actions/ImageVerificationAction";
import { fetchSingleUser } from "store/actions/subAdminAction";

const AddImage = ({ verify, toggleVerify, item }) => {
  const dispatch = useDispatch();
  const { singleUser } = useSelector((state) => state.users);
  const { dropdownData } = useSelector((state) => state.dropdown);
  const { isLoading } = useSelector((state) => state.imageVerification);
  const [industrySectorLabel, setIndustrySectorLabel] = useState("");
  useEffect(() => {
    if (singleUser?.industrySector) {
      const selectedIndustrySector = dropdownData.find(
        (item) => item.id === singleUser.industrySector
      );
      console.log(selectedIndustrySector);
      if (selectedIndustrySector) {
        setIndustrySectorLabel(selectedIndustrySector.option);
      } else {
        setIndustrySectorLabel("");
      }
    } else {
      setIndustrySectorLabel("");
    }
  }, [item, singleUser]);

  useEffect(() => {
    if (item?.memberID) {
      dispatch(fetchSingleUser(item.memberID));
    }
  }, [dispatch, item]);

  const handleUpdateVerifyImage = (status) => {
    dispatch(
      updateVerifyImage({ status: status, userId: item.memberID }, () => {
        toggleVerify();
      })
    );
  };

  return (
    <div>
      <Modal isOpen={verify} toggle={toggleVerify}>
        <ModalHeader toggle={toggleVerify}>Image Preview</ModalHeader>
        <ModalBody>
          <Row className="justify-content-start">
            <Col md="8">
              <p>First Name: {singleUser?.firstName}</p>
            </Col>
            <Col md="8">
              <p>Last Name: {singleUser?.lastName}</p>
            </Col>
            <Col md="8">
              <p>Industry Sector: {industrySectorLabel}</p>
            </Col>
            <Col md="10">
              <img
                className="rounded"
                style={{ objectFit: "contain" }}
                src={item?.fileUrl}
                alt="imagePreview"
                width={250}
                height={150}
              />
            </Col>
            <Col md="8" className="my-3">
              <Button
                color="danger"
                size="sm"
                disabled={isLoading}
                onClick={() => handleUpdateVerifyImage("rejected")}
              >
                Reject
              </Button>

              <Button
                color="success"
                size="sm"
                disabled={isLoading}
                onClick={() => handleUpdateVerifyImage("verified")}
              >
                Verify
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddImage;
