import Repository from "./Repository";

export default {
  getCodesCount(payload) {
    return Repository.post("/stats/codes", payload);
  },
  getRegistrationCount(payload) {
    return Repository.post("/stats/registrations", payload);
  },
  getUsersCount(payload) {
    return Repository.post("/stats/users", payload);
  },
};
