import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { fetchSearchedBrands } from "store/actions/brandAction";
import { customStyles } from "assets/customStyle/reactSelectCustomStyle";
import Select from "react-select";
import { searchOffers } from "store/actions/offerAction";
import { getCodesCount } from "store/actions/statsAction";
import { DropdownIndicator } from "components/DropdownIndicator";

const CodesModal = ({
  isOpen,
  isToggle,
  onSelectedDateRangeChange,
  handleCodeChange,
  selectedDateRange,
  setSelectedDateRange,
  codesDetails,
}) => {
  const [selectedBrand, setSelectedBrand] = useState({});

  const [selectedOffer, setSelectedOffer] = useState({});

  const initialValues = {
    brand: codesDetails?.brand?.value || "",
    offer: codesDetails?.offer?.value || "",
    from: codesDetails?.from || "",
    to: codesDetails?.to || "",
  };

  const validationSchema = Yup.object({
    brand: Yup.string().required("Required"),
    offer: Yup.string().required("Required"),
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
  });

  const dispatch = useDispatch();
  const { searchedBrands } = useSelector((state) => state.brand);
  const { searchedOffers } = useSelector((state) => state.offer);

  const { isGettingCounts } = useSelector((state) => state.stats);

  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      getCodesCount(
        {
          brandName: values?.brand,
          offer: values?.offer,
          from: values?.from,
          to: values?.to,
        },
        () => {
          onSelectedDateRangeChange("codes", true);
          handleCodeChange({
            brand: selectedBrand,
            offer: selectedOffer,
            from: values?.from,
            to: values?.to,
          });

          isToggle();
        }
      )
    );
  };

  let dynamicBrandName = searchedBrands.map((brand) => ({
    label: brand.brandName,
    value: brand.objectID,
  }));

  let dynamicOffers = searchedOffers.map((offer) => ({
    label: offer.offerTitle,
    value: offer.id,
  }));

  useEffect(() => {
    dispatch(fetchSearchedBrands("", 0, 10));
  }, []);

  const clearStates = () => {
    setSelectedBrand({});
    setSelectedOffer({});
  };

  useEffect(() => {
    if (selectedBrand?.value) {
      dispatch(searchOffers(selectedBrand?.value));
    } else {
      dispatch(searchOffers(""));
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (codesDetails?.brand?.value) {
      setSelectedBrand(codesDetails?.brand);
      setSelectedOffer(codesDetails?.offer);
    }
  }, [codesDetails]);

  return (
    <Modal isOpen={isOpen} centered size="md">
      <ModalHeader
        isOpen={isOpen}
        toggle={() => {
          if (!codesDetails?.brand?.value) {
            clearStates();
          }
          isToggle();
        }}
      >
        Codes
      </ModalHeader>
      <ModalBody className="pt-0">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label for="brandName">Brand Name</Label>
                    <Select
                      styles={customStyles}
                      placeholder="Choose option"
                      options={dynamicBrandName}
                      value={selectedBrand}
                      components={{ DropdownIndicator }}
                      onChange={(newValue) => {
                        setFieldValue("brand", newValue?.value);
                        setFieldValue("offer", "");
                        setSelectedBrand(newValue);
                        setSelectedOffer({});
                        // dispatch(searchOffers(newValue?.value));
                      }}
                      onInputChange={(searchQuery) => {
                        dispatch(fetchSearchedBrands(searchQuery, 0, 10));
                      }}
                    />
                    <ErrorMessage
                      name="brand"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>Offer</Label>
                    <Select
                      styles={customStyles}
                      id="offer"
                      placeholder="Choose option"
                      options={dynamicOffers}
                      value={selectedOffer}
                      components={{ DropdownIndicator }}
                      onChange={(newValue) => {
                        setFieldValue("offer", newValue?.value);
                        setSelectedOffer(newValue);
                      }}
                    />
                    <ErrorMessage
                      name="offer"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>From</Label>
                    <Input
                      as="input"
                      type="date"
                      className="form-control"
                      name="from"
                      value={values?.from}
                      onChange={(e) => {
                        setFieldValue("from", e.target.value);
                        setFieldValue("to", "");
                      }}
                    ></Input>
                    <ErrorMessage
                      name="from"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>To</Label>
                    <Input
                      as="input"
                      type="date"
                      className="form-control"
                      name="to"
                      value={values?.to}
                      onChange={(e) => setFieldValue("to", e.target.value)}
                      min={values?.from == "" ? "" : values.from}
                      disabled={values?.from == ""}
                    ></Input>
                    <ErrorMessage
                      name="to"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" className="d-flex justify-content-end">
                  {selectedDateRange?.codes && (
                    <Button
                      type="submit"
                      color="secondary"
                      className="mr-2"
                      onClick={() => {
                        clearStates();
                        handleCodeChange({
                          brand: {},
                          offer: {},
                          from: "",
                          to: "",
                        });
                        onSelectedDateRangeChange("codes", false);
                        isToggle();
                      }}
                    >
                      Display All
                    </Button>
                  )}

                  <Button
                    type="submit"
                    color="primary"
                    disabled={isGettingCounts}
                  >
                    {isGettingCounts ? "Submitting" : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default CodesModal;
