import { SUBMIT_DROPDOWN_START } from "store/types";
import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { SUBMIT_DROPDOWN_SUCCESS } from "store/types";
import { SUBMIT_DROPDOWN_ERROR } from "store/types";
import { toast } from "react-toastify";
import { FETCH_DROPDOWN_START } from "store/types";
import { FETCH_DROPDOWN_SUCCESS } from "store/types";
import { FETCH_DROPDOWN_ERROR } from "store/types";
import { DELETE_DROPDOWN_START } from "store/types";
import { DELETE_DROPDOWN_SUCCESS } from "store/types";
import { DELETE_DROPDOWN_ERROR } from "store/types";
import { UPDATE_DROPDOWN_SUCCESS } from "store/types";
import { UPDATE_DROPDOWN_ERROR } from "store/types";
import { UPDATE_DROPDOWN_START } from "store/types";

// ============CREATE============
export const submitIndustrySectorData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: SUBMIT_DROPDOWN_START });
    if (data.industrySectorIcon) {
      const imageFile = data.industrySectorIcon;
      const fileName = data.industrySectorIcon.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));
      const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
      const storageRef = firebase
        .storage()
        .ref("dropdown icons/" + fileNameWithExtension);
      const uploadTaskSnapshot = await storageRef.put(imageFile);
      const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
      data.industrySectorIcon = downloadURL;
    }

    firebase
      .firestore()
      .collection("industrySectorDropdown")
      .add({
        option: data.industrySectorLabel,
        icon: data.industrySectorIcon,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        dispatch({ type: SUBMIT_DROPDOWN_SUCCESS });
        toast.success("Industry Sector Dropdown Added Successfully");
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: SUBMIT_DROPDOWN_ERROR, payload: error });
      });
  };

// =========GET==========

export const fetchDropdownData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_DROPDOWN_START });
    firebase
      .firestore()
      .collection("industrySectorDropdown")
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
        dispatch({ type: FETCH_DROPDOWN_SUCCESS, payload: data });
      })
      .catch((error) => {
        dispatch({ type: FETCH_DROPDOWN_ERROR, payload: error });
      });
  };
};

// ==========UPDATE=========
export const updateDropdownData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_DROPDOWN_START });
    if (data.industrySectorIcon === null) {
      const { id, industrySectorLabel } = data;
      firebase
        .firestore()
        .collection("industrySectorDropdown")
        .doc(id)
        .update({
          option: industrySectorLabel,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          dispatch({ type: UPDATE_DROPDOWN_SUCCESS });
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: UPDATE_DROPDOWN_ERROR, payload: error });
        });
    } else if (typeof data.industrySectorIcon === "object") {
      let details = await firebase
        .firestore()
        .collection("industrySectorDropdown")
        .doc(data.id)
        .get();
      let { icon } = details.data();
      if (icon) {
        await firebase.storage().refFromURL(icon).delete();
      }
      const imageFile = data.industrySectorIcon;
      const fileName = data.industrySectorIcon.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));
      const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
      const storageRef = firebase
        .storage()
        .ref("dropdown icons/" + fileNameWithExtension);
      const uploadTaskSnapshot = await storageRef.put(imageFile);
      const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
      data.industrySectorIcon = downloadURL;

      firebase
        .firestore()
        .collection("industrySectorDropdown")
        .doc(data.id)
        .update({
          option: data.industrySectorLabel,
          icon: data.industrySectorIcon,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          dispatch({ type: UPDATE_DROPDOWN_SUCCESS });
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: UPDATE_DROPDOWN_ERROR, payload: error });
        });
    }
  };

// ==========DELETE=========
export const deleteDropdown = (id) => async (dispatch) => {
  dispatch({ type: DELETE_DROPDOWN_START });
  const [isFoundInUsers, isFoundInDomains] = await Promise.all([
    firebase
      .firestore()
      .collection("users")
      .where("industrySector", "==", id)
      .get(),
    firebase
      .firestore()
      .collection("domains")
      .where("industrySector", "==", id)
      .get(),
  ]);

  // let isFoundInUsers = await firebase
  //   .firestore()
  //   .collection("users")
  //   .where("industrySector", "==", id)
  //   .get();
  // let isFoundInDomains = await firebase
  //   .firestore()
  //   .collection("domains")
  //   .where("industrySector", "==", id)
  //   .get();

  if (isFoundInUsers?.size > 0) {
    toast.warning(
      "This industry sector is used in a user and you cannot delete it"
    );
    dispatch({
      type: DELETE_DROPDOWN_ERROR,
      payload:
        "This industry sector is used in a user and you cannot delete it",
    });
  } else if (isFoundInDomains?.size > 0) {
    toast.warning(
      "This industry sector is used in a domain and you cannot delete it"
    );
    dispatch({
      type: DELETE_DROPDOWN_ERROR,
      payload:
        "This industry sector is used in a domain and you cannot delete it",
    });
  } else {
    let details = await firebase
      .firestore()
      .collection("industrySectorDropdown")
      .doc(id)
      .get();

    let { icon } = details.data();
    await firebase.storage().refFromURL(icon).delete();
    firebase
      .firestore()
      .collection("industrySectorDropdown")
      .doc(id)
      .delete()
      .then(() => {
        dispatch({ type: DELETE_DROPDOWN_SUCCESS, payload: id });
        toast.success("Industry Sector Deleted Successfully");
      })
      .catch((error) => {
        dispatch({ type: DELETE_DROPDOWN_ERROR, payload: error });
      });
  }
};
