import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

const AddImage = ({ verify, toggleVerify }) => {
  const [userImage, setUserImage] = useState(null);
  const [memberId, setMemberId] = useState("");
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUserImage(file);
  };
  const handleToggle = () => {
    setMemberId("");
    setUserImage(null);
    toggleVerify();
  };

  return (
    <div>
      <Modal isOpen={verify} toggle={toggleVerify}>
        <ModalHeader toggle={toggleVerify}>Add Image</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="exampletext">Member ID</Label>
                  <Input
                    type="text"
                    name="memberId"
                    value={memberId}
                    onChange={(e) => setMemberId(e.target.value)}
                    placeholder="Enter Member ID Here"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="exampletext">Image</Label>
                  <Input
                    type="file"
                    id="domain-file"
                    onChange={handleFileChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>

        {/* Modal Footer */}

        <ModalFooter>
          <Button onClick={handleToggle}>Cancel</Button>
          <Button color="primary" onClick={handleToggle}>
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddImage;
