import Brandheader from "components/Headers/Brandheader";
import Loader from "components/Loader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Container,
  CardHeader,
  CardBody,
  Card,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { updateBrandData } from "store/actions/brandAction";
import { fetchBrands } from "store/actions/brandAction";
import { fetchSingleBrand } from "store/actions/brandAction";
import * as Yup from "yup";

const EditBrandModal = ({
  isEditModalOpen,
  toggleEditBrandModal,
  brandID,
  search,
}) => {
  const {
    brands,
    singleBrand,
    isBrandLoading,
    isUpdateLoading,
    page,
    hitsPerPage,
  } = useSelector((state) => state.brand);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [logo, setLogo] = useState("");
  const [brandImage, setBrandImage] = useState("");
  useEffect(() => {
    setLogo(singleBrand?.brandLogo);
    setBrandImage(singleBrand?.brandImage);
  }, [singleBrand]);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [typingTimeout, setTypingTimeout] = useState(null);

  const initialValues = {
    brandName: singleBrand?.brandName || "",
    brandLogo: singleBrand?.brandLogo || "",
    brandImage: singleBrand?.brandImage || "",
    brandLink: singleBrand?.brandLink || "",
    // brandSubdirectory: singleBrand?.brandSubdirectory || "",
    brandButtonColor: singleBrand?.brandButtonColor || "#000000",
    brandActive: singleBrand?.brandActive || false,
    backLink: singleBrand?.backLink || false,
    // backLinkText: singleBrand?.backLinkText || "",
    // limitedTime: singleBrand?.limitedTime || false,
  };
  const validationSchema = Yup.object().shape({
    brandName: Yup.string()
      .required("Brand Name is required")
      .matches(/^[A-Za-z0-9 .&'?!-]{3,50}$/, "Brand Name is not valid"),
    // .matches(/^[A-Za-z0-9 ]{3,50}$/, "Brand Name is not valid"),
    // brandLogo: Yup.mixed()
    //   .required("Brand Logo is required")
    //   .test(
    //     "fileSize",
    //     "Size must be less than 1 MB",
    //     (value) => value && value.size <= 1024 * 1024
    //   ),
    // brandImage: Yup.mixed()
    //   .required("Brand Image is required")
    //   .test(
    //     "fileSize",
    //     "Size must be less than 1 MB",
    //     (value) => value && value.size <= 1024 * 1024
    //   ),
    brandLink: Yup.string()
      .required("Brand Link is required")
      .matches(
        /\b(?:https?:\/\/)[^\s/$.?#]+\.[^\s]+\b/,
        "Brand Link must be a valid URL"
      ),
    // brandSubdirectory: Yup.string().required("Subdirectory Name is required"),
    brandButtonColor: Yup.string().required("Brand Button Color is required"),
    // limitedTime: Yup.string().required("Limited time is required"),
  });
  const handleLogoChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size <= 1024 * 1024) {
        if (file.type.startsWith("image/")) {
          setFieldValue("brandLogo", file);
        } else {
          toast.error("Selected file is not a valid image.");
          document.getElementById("brandLogo").value = "";
        }
      } else {
        toast.error("File size must be less than 1 MB.");
        document.getElementById("brandLogo").value = "";
      }
    } else {
      document.getElementById("brandLogo").value = "";
    }
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size <= 1024 * 1024) {
        if (file.type.startsWith("image/")) {
          setFieldValue("brandImage", file);
        } else {
          toast.error("Selected file is not a valid image.");
          document.getElementById("brandImage").value = "";
        }
      } else {
        toast.error("File size must be less than 1 MB.");
        document.getElementById("brandImage").value = "";
      }
    } else {
      document.getElementById("brandImage").value = "";
    }
  };

  useEffect(() => {
    // if (params.id) {
    if (brandID) {
      dispatch(fetchSingleBrand(brandID));
    }
  }, [brandID]);
  const handleSubmit = async (values) => {
    try {
      const createSlug = (brandName) => {
        // const sanitizedBrandName = brandName.toLowerCase().replace(/\s+/g, "-");
        // const slug = sanitizedBrandName.replace(/[^a-z0-9-]/g, "");
        // return slug;
        const sanitizedBrandName = brandName.toLowerCase().replace(/\s+/g, "-");
        const slug = sanitizedBrandName.replace(/[.&'?!-]/g, "-");
        const finalSlug = slug.replace(/-+/g, "-").replace(/^-+|-+$/g, "");
        return finalSlug;
      };
      let slug = createSlug(values?.brandName);
      dispatch(
        updateBrandData({ ...values, slug, id: brandID }, () => {
          dispatch(fetchSingleBrand(brandID));
          toggleEditBrandModal("");
          // setTimeout(() => {
          //   dispatch(fetchBrands(page, hitsPerPage));
          // }, 5000);
        })
      );
    } catch (error) {
      console.log("Error checking brand existence:", error);
    }
  };

  return (
    <Modal
      isOpen={isEditModalOpen}
      toggle={() => toggleEditBrandModal("")}
      size="lg"
    >
      {/* <Brandheader />
      <Container fluid className="pb-4"> */}
      {/* <Card> */}
      <ModalHeader toggle={() => toggleEditBrandModal("")}>
        <div className="d-flex justify-content-between">
          <h2>Update Brand</h2>
          {/* <Button onClick={() => history.push("../../admin/brandspage")}>
            Back
          </Button> */}
        </div>
      </ModalHeader>
      <ModalBody>
        {/* {isBrandLoading && <Loader />} */}
        {/* {!isBrandLoading && ( */}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandName">Brand Name</Label>
                    <Field
                      type="text"
                      id="brandName"
                      name="brandName"
                      placeholder="Enter Brand Name Here"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="brandName"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandLogo">Brand Logo</Label>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <div className="d-flex">
                    <FormGroup>
                      <Input
                        id="brandLogo"
                        name="brandLogo"
                        type="file"
                        accept=".jpg, .jpeg, .svg, .png"
                        onChange={(event) => {
                          setLogo("");
                          handleLogoChange(event, formik.setFieldValue);
                        }}
                      />
                      <ErrorMessage
                        name="brandLogo"
                        component="div"
                        className="text-danger"
                      />
                      <small className="text-muted mt-2">
                        Recommended dimensions: 51x54 pixels
                      </small>
                    </FormGroup>
                    {logo?.length > 0 && (
                      <img src={logo} alt="logo" width={45} height={45} />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandImage">Brand Image</Label>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <div className="d-flex">
                    <FormGroup>
                      <Input
                        id="brandImage"
                        name="brandImage"
                        type="file"
                        accept=".jpg, .jpeg, .svg, .png"
                        onChange={(event) => {
                          setBrandImage("");
                          handleImageChange(event, formik.setFieldValue);
                        }}
                      />
                      <ErrorMessage
                        name="brandImage"
                        component="div"
                        className="text-danger"
                      />
                      <small className="text-muted mt-2">
                        Recommended dimensions: 450x161 pixels
                      </small>
                    </FormGroup>
                    {brandImage?.length > 0 && (
                      <img
                        src={brandImage}
                        alt="brand image"
                        width={45}
                        height={45}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandLink">Brand Link</Label>
                    <Field
                      type="text"
                      id="brandLink"
                      name="brandLink"
                      placeholder="Paste Your Link Here"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="brandLink"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  {/* <FormGroup>
                    <Label for="brandSubdirectory">Subdirectory Name</Label>
                    <Field
                      type="text"
                      id="brandSubdirectory"
                      name="brandSubdirectory"
                      placeholder="Paste Your Link Here"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="brandSubdirectory"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup> */}
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandButtonColor">Brand Button Color</Label>
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        background: formik.values.brandButtonColor,
                        borderRadius: "20%",
                        border: "1px solid black",
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    ></div>
                    {isColorPickerOpen && (
                      <SketchPicker
                        color={formik.values.brandButtonColor}
                        onChange={(clr) => {
                          formik.setFieldValue("brandButtonColor", clr.hex);
                          // setIsColorPickerOpen(false);
                          clearTimeout(typingTimeout);
                          setTypingTimeout(
                            setTimeout(() => {
                              setIsColorPickerOpen(false);
                            }, 1500)
                          );
                        }}
                      />
                    )}
                    <ErrorMessage
                      name="brandButtonColor"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandActive">Brand Active</Label>
                    <CustomInput
                      type="switch"
                      id="brandActive"
                      name="brandActive"
                      checked={formik.values.brandActive}
                      onChange={(e) =>
                        formik.setFieldValue("brandActive", e.target.checked)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="backLink">Back Link</Label>
                    <CustomInput
                      type="switch"
                      id="backLink"
                      name="backLink"
                      checked={formik.values.backLink}
                      onChange={(e) =>
                        formik.setFieldValue("backLink", e.target.checked)
                      }
                    />
                  </FormGroup>
                </Col>
                {/* <Col lg="6">
                  {formik.values.backLink && (
                    <FormGroup>
                      <Label for="backLinkText">Input Field</Label>
                      <Field
                        type="text"
                        id="backLinkText"
                        name="backLinkText"
                        placeholder="Put new Url Here"
                        required
                        className="form-control"
                      />
                    </FormGroup>
                  )}
                </Col> */}
              </Row>
              {/* <CardHeader>
                <h2>Brands Content</h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" xl="4">
                    <FormGroup>
                      <Label>
                        <h4>Limited Time</h4>
                      </Label>
                      <CustomInput
                        type="switch"
                        id="limitedTime"
                        name="limitedTime"
                        checked={formik.values.limitedTime}
                        onChange={(e) =>
                          formik.setFieldValue("limitedTime", e.target.checked)
                        }
                      />
                     
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody> */}
              <CardFooter>
                <div className="d-flex justify-content-end">
                  <Button
                    // onClick={() => history.push("../../admin/brandspage")}
                    onClick={() => toggleEditBrandModal("")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isUpdateLoading}
                    color="primary"
                  >
                    {isUpdateLoading ? "Updating" : "Update"}
                  </Button>
                </div>
              </CardFooter>
            </Form>
          )}
        </Formik>
        {/* )} */}
      </ModalBody>
      {/* </Card> */}
      {/* </Container> */}
    </Modal>
  );
};

export default EditBrandModal;
