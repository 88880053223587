import firebase from "../../config/firebase";
import { FETCH_OFFERS_SUCCESS } from "store/types";
import { UPDATE_OFFER_SUCCESS } from "store/types";
import { UPDATE_OFFER_START } from "store/types";
import { DELETE_OFFER_START } from "store/types";
import { DELETE_OFFER_ERROR } from "store/types";
import { DELETE_OFFER_SUCCESS } from "store/types";
import { FETCH_OFFERS_START } from "store/types";
import { UPDATE_OFFER_ERROR } from "store/types";
import { FETCH_OFFERS_ERROR } from "store/types";
import { SUBMIT_OFFER_ERROR } from "store/types";
import { SUBMIT_OFFER_SUCCESS } from "store/types";
import { SUBMIT_OFFER_START } from "store/types";
import { offerIndex } from "./subAdminAction";
import { SET_OFFER_TOTAL_PAGES } from "store/types";
import { ADD_OFFER_PAYLOAD } from "store/types";
import { UPDATE_OFFER_PAYLOAD } from "store/types";
import { toast } from "react-toastify";
import { FETCH_SEARCH_OFFERS_SUCCESS } from "store/types";
import { FETCH_SINGLE_OFFER_SUCCESS } from "store/types";

import { createNullCache } from "@algolia/cache-common";
import { setDeleteLoader } from "./globalContentAction";

// ============CREATE============
export const submitOfferData =
  (offerData, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: SUBMIT_OFFER_START });
    firebase
      .firestore()
      .collection("offers")
      .add({
        ...offerData,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((response) => {
        setTimeout(() => {
          toast.success("Offer Added Successfully");
          dispatch({ type: SUBMIT_OFFER_SUCCESS });
          dispatch({
            type: ADD_OFFER_PAYLOAD,
            payload: { ...offerData, id: response.id, objectID: response.id },
          });
        }, 6000);
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: SUBMIT_OFFER_ERROR, payload: error });
      });
  };

// =========GET==========
export const fetchOffers = (page, hitsPerPage, selectedBrand, sortBy) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_OFFERS_START });

    // console.log("Selected brands", selectedBrand, sortBy, offerIndex);
    // let details = await offerIndex.getSettings();
    let settings = await offerIndex.getSettings();

    let sort = sortBy ? sortBy : "asc";

    settings.ranking = [`${sort}(endDate_unix)`];
    await offerIndex.setSettings(settings);
    console.log("Datdsfdf", sort, settings);

    await new Promise((resolve) =>
      setTimeout(() => {
        resolve();
      }, 2000)
    );

    offerIndex
      .search("", {
        page,
        hitsPerPage,
        filters:
          selectedBrand?.value && selectedBrand?.value != ""
            ? `brandName:${selectedBrand?.value}`
            : "",
      })
      .then((response) => {
        const offers = [];
        response.hits.forEach((doc) => {
          offers.push({ id: doc.objectID, ...doc });
        });
        dispatch({ type: FETCH_OFFERS_SUCCESS, payload: offers });
        dispatch({
          type: SET_OFFER_TOTAL_PAGES,
          payload: response.nbPages,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_OFFERS_ERROR, payload: error });
      });
  };
};
// =========GET OFFERS FOR CODE DROPDOWN==========
export const searchOffers = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("offers")
      .where("brandName", "==", id)
      .where("offerType", "==", "code")
      .get()
      .then((response) => {
        const offers = [];
        response.forEach((doc) => {
          offers.push({ id: doc.id, ...doc.data() });
        });
        dispatch({ type: FETCH_SEARCH_OFFERS_SUCCESS, payload: offers });
      })
      .catch((error) => {
        dispatch({ type: FETCH_OFFERS_ERROR, payload: error });
      });
  };
};

// ==========SINGLE OFFER==============

export const fetchSingleOffer = (id, onSuccess = () => {}) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("offers")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const offer = { id: doc.id, ...doc.data() };
          dispatch({ type: FETCH_SINGLE_OFFER_SUCCESS, payload: offer });
          onSuccess();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// ==========UPDATE==============
export const updateOfferData =
  (offerData, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_OFFER_START });
    const { id, ...updatedData } = offerData;
    firebase
      .firestore()
      .collection("offers")
      .doc(id)
      .update(updatedData)
      .then(() => {
        dispatch({ type: UPDATE_OFFER_SUCCESS });
        dispatch({
          type: UPDATE_OFFER_PAYLOAD,
          payload: { ...offerData, objectID: offerData.id },
        });
        toast.success("Offer Updated Successfully");
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: UPDATE_OFFER_ERROR, payload: error });
      });
  };

// ==========DELETE=========
export const deleteOffer =
  (offerId, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(setDeleteLoader(true));

    try {
      // const offerAssignedToCode = await firebase
      //   .firestore()
      //   .collection("codes")
      //   .where("offer", "==", offerId)
      //   .get();

      await firebase.firestore().collection("offers").doc(offerId).delete();
      setTimeout(() => {
        dispatch(setDeleteLoader(false));
        toast.success("Offer Deleted Successfully");
      }, 5000);
      onSuccess();
      // const offerAssignedToCode = await firebase
      //   .firestore()
      //   .collection("codes")
      //   .where("offer", "==", offerId)
      //   .get();

      // if (offerAssignedToCode.empty) {
      //   await firebase.firestore().collection("offers").doc(offerId).delete();
      //   setTimeout(() => {
      //     dispatch(setDeleteLoader(false));

      //     toast.success("Offer Deleted Successfully");
      //   }, 5000);
      //   onSuccess();
      // } else {
      //   dispatch(setDeleteLoader(false));

      //   toast.error(
      //     "This offer is assigned to one or more codes. Please unassign the offer from the codes before deleting."
      //   );
      // }
    } catch (error) {
      dispatch(setDeleteLoader(false));

      toast.error(error?.response?.data?.error || "There was an error");
    }
  };
