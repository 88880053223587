import React, { useEffect } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
import { toast } from "react-toastify";

const OTP = () => {
  const dispatch = useDispatch();
  const { isLoading, otp, isFetchFunctionLoading } = useSelector(
    (state) => state.globalContent
  );

  useEffect(() => {
    dispatch(fetchGlobalContentData("otp"));
  }, [dispatch]);

  const initialValues = {
    verifyTitle: otp?.verifyTitle || "",
    verifyText: otp?.verifyText || "",
    verifyButtonText: otp?.verifyButtonText || "",
    verifyWithIDText: otp?.verifyWithIDText || "",
    acceptedFormsOfIDTextlink: otp?.acceptedFormsOfIDTextlink || "",
    acceptedFormsOfIDLink: otp?.acceptedFormsOfIDLink || "",
    verifyWithIDButtonText: otp?.verifyWithIDButtonText || "",
    codeMismatchErrorText: otp?.codeMismatchErrorText || "",
    requestNewCodeTextlink: otp?.requestNewCodeTextlink || "",
    verifyWithIDErrorText: otp?.verifyWithIDErrorText || "",
  };

  const validationSchema = Yup.object({
    verifyTitle: Yup.string().required("Required"),
    verifyText: Yup.string().required("Required"),
    verifyButtonText: Yup.string().required("Required"),
    verifyWithIDText: Yup.string().required("Required"),
    acceptedFormsOfIDTextlink: Yup.string().required("Required"),
    acceptedFormsOfIDLink: Yup.string().required("Required"),
    verifyWithIDButtonText: Yup.string().required("Required"),
    codeMismatchErrorText: Yup.string().required("Required"),
    requestNewCodeTextlink: Yup.string().required("Required"),
    verifyWithIDErrorText: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(
      submitGlobalContentData({ pageName: "otp", ...values }, () => {
        toast.success("Data Submitted Successfully");
      })
    );
  };

  return (
    <div>
      {isFetchFunctionLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <Form className="p-4">
            <Row className="mt-3">
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Verify Title</h4>
                  </Label>
                  <Field type="text" name="verifyTitle" as={Input} />
                  <ErrorMessage
                    name="verifyTitle"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Verify Text</h4>
                  </Label>
                  <Field type="textarea" name="verifyText" as={Input} />
                  <ErrorMessage
                    name="verifyText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Verify Button Text</h4>
                  </Label>
                  <Field type="text" name="verifyButtonText" as={Input} />
                  <ErrorMessage
                    name="verifyButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Verify with ID Text</h4>
                  </Label>
                  <Field type="textarea" name="verifyWithIDText" as={Input} />
                  <ErrorMessage
                    name="verifyWithIDText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Accepted Forms of ID Textlink</h4>
                  </Label>
                  <Field
                    type="text"
                    name="acceptedFormsOfIDTextlink"
                    as={Input}
                  />
                  <ErrorMessage
                    name="acceptedFormsOfIDTextlink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Accepted Forms of ID Link (URL)</h4>
                  </Label>
                  <Field type="text" name="acceptedFormsOfIDLink" as={Input} />
                  <ErrorMessage
                    name="acceptedFormsOfIDLink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Verify with ID Button Text</h4>
                  </Label>
                  <Field type="text" name="verifyWithIDButtonText" as={Input} />
                  <ErrorMessage
                    name="verifyWithIDButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Code doesn't Match Text</h4>
                  </Label>
                  <Field type="text" name="codeMismatchErrorText" as={Input} />
                  <ErrorMessage
                    name="codeMismatchErrorText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Request New Code Textlink</h4>
                  </Label>
                  <Field type="text" name="requestNewCodeTextlink" as={Input} />
                  <ErrorMessage
                    name="requestNewCodeTextlink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Verify with ID Text</h4>
                  </Label>
                  <Field type="text" name="verifyWithIDErrorText" as={Input} />
                  <ErrorMessage
                    name="verifyWithIDErrorText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-4">
              <Button
                type="submit"
                disabled={isLoading}
                className="btn btn-success"
              >
                {isLoading ? "Submitting" : "Submit"}
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default OTP;
