import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";
import { submitOfferData } from "store/actions/offerAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { customStyles } from "assets/customStyle/reactSelectCustomStyle";
import Select from "react-select";
import { fetchSearchedBrands } from "store/actions/brandAction";
import { fetchOffers } from "store/actions/offerAction";
import { DropdownIndicator } from "components/DropdownIndicator";

const AddOffers = ({ AddOffer, toggleAddOffer, selectedBrand, sortBy }) => {
  const dispatch = useDispatch();
  const { isLoading, page, hitsPerPage } = useSelector((state) => state.offer);
  const { searchedBrands } = useSelector((state) => state.brand);

  const [dynamicBrandName, setDynamicBrands] = useState([]);
  // let dynamicBrandName = searchedBrands.map((brand) => ({
  //   label: brand.brandName,
  //   value: brand.objectID,
  // }));

  const initialValues = {
    offerTitle: "",
    offerLink: "",
    offerDescription: "",
    offerTerms: "",
    brandName: "",
    startDate: "",
    endDate: "",
    offerType: "",
    limitedTimeText: "",
    dealLink: "",
    limitedTime: false,
    optinInfo: {
      brandEmailOptin: "no",
      optinTitle: "",
      isOptinRequired: false,
      privacyPolicyLink: "",
      optinBodyText1: "",
      optinBodyText2: "",
    },
  };

  const validationSchema = Yup.object({
    offerTitle: Yup.string().required("Required"),
    offerLink: Yup.string()
      .required("Required")
      .matches(
        /\b(?:https?:\/\/)[^\s/$.?#]+\.[^\s]+\b/,
        "It must be a valid URL"
      ),
    offerDescription: Yup.string().required("Required"),
    offerTerms: Yup.string().required("Required"),
    brandName: Yup.string().required("Required"),
    startDate: Yup.string().required("Required"),
    // endDate: Yup.string().test(
    //   "is-upcoming-date",
    //   "End date must be upcoming",
    //   function (value) {
    //     const startDate = this.resolve(Yup.ref("startDate"));
    //     if (!startDate || !value) {
    //       return false;
    //     }

    //     const startDateObj = new Date(startDate);
    //     const endDateObj = new Date(value);
    //     const currentDate = new Date();

    //     if (endDateObj < currentDate || endDateObj <= startDateObj) {
    //       return false;
    //     }

    //     return true;
    //   }
    // ),
    offerType: Yup.string().required("Required"),
    // dealLink: Yup.string().matches(
    //   /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
    //   "It must be a valid URL"
    // ),

    dealLink: Yup.string().when("offerType", (offerType) => {
      if (offerType.length > 0 && offerType[0] === "code") {
        return Yup.string();
      } else {
        return Yup.string()
          .required("Required")
          .matches(
            /\b(?:https?:\/\/)[^\s/$.?#]+\.[^\s]+\b/,
            "It must be a valid URL"
          );
      }
    }),

    endDate: Yup.string().when("limitedTime", (limitedTime) => {
      if (limitedTime.length > 0 && limitedTime[0] === false) {
        return Yup.string();
      } else {
        return Yup.string().required("Required");
      }
    }),
    limitedTimeText: Yup.string().when("limitedTime", (limitedTime) => {
      if (limitedTime.length > 0 && limitedTime[0] === false) {
        return Yup.string();
      } else {
        return Yup.string().required("Required");
      }
    }),
    optinInfo: Yup.object({
      brandEmailOptin: Yup.string().oneOf(["yes", "no"]),
      isOptinRequired: Yup.boolean(),
      optinTitle: Yup.string().when("brandEmailOptin", (brandEmailOptin) => {
        if (brandEmailOptin.length > 0 && brandEmailOptin[0] === "yes") {
          return Yup.string().required("Required");
        } else {
          return Yup.string();
        }
      }),
      privacyPolicyLink: Yup.string().when(
        "brandEmailOptin",
        (brandEmailOptin) => {
          if (brandEmailOptin.length > 0 && brandEmailOptin[0] === "yes") {
            return Yup.string()
              .required("Required")
              .matches(
                /\b(?:https?:\/\/)[^\s/$.?#]+\.[^\s]+\b/,
                "It must be a valid URL"
              );
          } else {
            return Yup.string();
          }
        }
      ),
      optinBodyText1: Yup.string().when(
        "brandEmailOptin",
        (brandEmailOptin) => {
          if (brandEmailOptin.length > 0 && brandEmailOptin[0] === "yes") {
            return Yup.string().required("Required");
          } else {
            return Yup.string();
          }
        }
      ),
      optinBodyText2: Yup.string().when(
        "brandEmailOptin",
        (brandEmailOptin) => {
          if (brandEmailOptin.length > 0 && brandEmailOptin[0] === "yes") {
            return Yup.string().required("Required");
          } else {
            return Yup.string();
          }
        }
      ),
    }),
  });

  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      submitOfferData(values, () => {
        setTimeout(() => {
          resetForm();
          toggleAddOffer();
          dispatch(fetchOffers(page, hitsPerPage, selectedBrand, sortBy));
        }, 6000);
      })
    );
  };
  const currentDate = new Date().toISOString().slice(0, 16);
  useEffect(() => {
    dispatch(fetchSearchedBrands("", page, hitsPerPage));
  }, []);
  useEffect(() => {
    let brands = searchedBrands.map((brand) => ({
      label: brand.brandName,
      value: brand.objectID,
    }));
    setDynamicBrands(brands);
  }, [searchedBrands]);
  return (
    <div>
      <Modal
        isOpen={AddOffer}
        toggle={() => {
          toggleAddOffer();
        }}
      >
        <ModalHeader
          toggle={() => {
            toggleAddOffer();
          }}
        >
          Add Offer
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label for="offerTitle">Offer title</Label>
                      <Field
                        type="text"
                        id="offerTitle"
                        name="offerTitle"
                        placeholder="Type the Offer title here"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="offerTitle"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label for="offerLink">Offer Link</Label>
                      <Field
                        type="text"
                        id="offerLink"
                        name="offerLink"
                        placeholder="Offer Link here"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="offerLink"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label for="offerDescription">Offer Description</Label>
                      <Field
                        as="textarea"
                        id="offerDescription"
                        name="offerDescription"
                        placeholder="Type offerDescription for your Offer"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="offerDescription"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label for="offerTerms">Offer Terms</Label>
                      <Field
                        as="textarea"
                        id="offerTerms"
                        name="offerTerms"
                        className="form-control"
                        placeholder="Type offer terms here"
                      />
                      <ErrorMessage
                        name="offerTerms"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label for="brandName">Brand Name</Label>
                      <Select
                        styles={customStyles}
                        // isClearable={false}
                        components={{ DropdownIndicator }}
                        placeholder="Choose brand"
                        options={dynamicBrandName}
                        // value={inputValue}
                        onChange={(newValue) => {
                          formik.setFieldValue("brandName", newValue?.value);
                        }}
                        onInputChange={(searchQuery) => {
                          dispatch(
                            fetchSearchedBrands(searchQuery, page, hitsPerPage)
                          );
                        }}
                      />
                      <ErrorMessage
                        name="brandName"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label for="offerType">Offer Type</Label>
                      <Field
                        as="select"
                        id="offerType"
                        name="offerType"
                        value={formik?.values?.offerType}
                        className="form-control"
                        onChange={(e) => {
                          formik.setFieldValue("offerType", e.target.value);
                          formik.setFieldValue("dealLink", "");
                        }}
                      >
                        <option value="" disabled>
                          Choose
                        </option>
                        <option value="deal">Deal</option>
                        <option value="code">Code</option>
                      </Field>
                      <ErrorMessage
                        name="offerType"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>

                  {formik.values?.offerType == "deal" && (
                    <Col lg="6" md="6">
                      <FormGroup>
                        <Label for="offerLink">Deal Link</Label>
                        <Field
                          type="text"
                          id="dealLink"
                          name="dealLink"
                          placeholder="Deal link here"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="dealLink"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <Col md="6">
                    <FormGroup>
                      <Label>
                        <h4>Limited Time</h4>
                      </Label>
                      <CustomInput
                        type="switch"
                        id="limitedTime"
                        name="limitedTime"
                        onChange={(e) =>
                          formik.setFieldValue("limitedTime", e.target.checked)
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label for="startDate">Start date</Label>
                      <Field
                        type="datetime-local"
                        id="startDate"
                        name="startDate"
                        className="form-control"
                        min={currentDate}
                      />
                      <ErrorMessage
                        name="startDate"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label for="endDate">End date</Label>
                      <Field
                        type="datetime-local"
                        id="endDate"
                        name="endDate"
                        min={
                          formik.values?.startDate == ""
                            ? currentDate
                            : formik.values.startDate
                        }
                        className="form-control"
                        disabled={formik.values?.startDate == ""}
                      />
                      <ErrorMessage
                        name="endDate"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label for="limitedTimeText">Limited Time Text</Label>
                      <Field
                        type="text"
                        id="limitedTimeText"
                        name="limitedTimeText"
                        placeholder=""
                        className="form-control"
                      />{" "}
                      <ErrorMessage
                        name="limitedTimeText"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="12">
                    <FormGroup>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="mr-2 brand-optin-checkbox"
                          id="brandEmailOptin"
                          name="brandEmailOptin"
                          checked={
                            formik.values.optinInfo.brandEmailOptin === "yes"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              "optinInfo.brandEmailOptin",
                              e.target.checked ? "yes" : "no"
                            )
                          }
                        />
                        <Label className="mb-0">Brand Email Optin</Label>
                      </div>
                    </FormGroup>
                  </Col>
                  {formik.values.optinInfo.brandEmailOptin === "yes" && (
                    <>
                      <Col lg="12" md="12">
                        <FormGroup>
                          <Label for="optinTitle">Optin Title</Label>
                          <Field
                            type="text"
                            id="optinTitle"
                            name="optinInfo.optinTitle"
                            placeholder="Type the Optin title here"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="optinInfo.optinTitle"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12" md="12">
                        <FormGroup>
                          <Label for="optinBodyText1">Optin Body Text 1</Label>
                          <Field
                            as="textarea"
                            id="optinBodyText1"
                            name="optinInfo.optinBodyText1"
                            placeholder="Type Optin Body Text 1"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="optinInfo.optinBodyText1"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12" md="12">
                        <FormGroup>
                          <Label for="optinBodyText1">Optin Body Text 2</Label>
                          <Field
                            as="textarea"
                            id="optinBodyText2"
                            name="optinInfo.optinBodyText2"
                            placeholder="Type Optin Body Text 2"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="optinInfo.optinBodyText2"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="12" md="12">
                        <FormGroup>
                          <Label for="privacyPolicyLink">
                            Privacy Policy link
                          </Label>
                          <Field
                            type="text"
                            id="privacyPolicyLink"
                            name="optinInfo.privacyPolicyLink"
                            placeholder="Privacy Policy Link here"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="optinInfo.privacyPolicyLink"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12" lg="12">
                        <FormGroup>
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="mr-2 brand-optin-checkbox"
                              id="isOptinRequired"
                              name="isOptinRequired"
                              checked={formik.values.optinInfo.isOptinRequired}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "optinInfo.isOptinRequired",
                                  e.target.checked
                                )
                              }
                            />
                            <Label className="mb-0">Optin is required</Label>
                          </div>
                          <ErrorMessage
                            name="optinInfo.isOptinRequired"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>

                <Row className="justify-content-end px-3">
                  <Button
                    color="secondary"
                    onClick={() => {
                      toggleAddOffer();
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isLoading} color="primary">
                    {isLoading ? "Submitting" : "Submit"}
                  </Button>{" "}
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddOffers;
