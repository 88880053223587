import React from "react";
import Select, { components } from "react-select";

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fa fa-search"></i>{" "}
    </components.DropdownIndicator>
  );
};
