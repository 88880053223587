import { FETCH_GLOBAL_CONTENT_START } from "store/types";
import firebase from "../../config/firebase";
import {
  SUBMIT_GLOBAL_CONTENT_START,
  SUBMIT_GLOBAL_CONTENT_SUCCESS,
  SUBMIT_GLOBAL_CONTENT_ERROR,
  FETCH_GLOBAL_CONTENT_SUCCESS,
  FETCH_GLOBAL_CONTENT_ERROR,
} from "store/types";
import { SET_DELETION_LOADER } from "store/types";
import { v4 as uuidv4 } from "uuid";

// ============CREATE CONTENT============

export const submitGlobalContentData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: SUBMIT_GLOBAL_CONTENT_START });

    const firestore = firebase.firestore();
    const querySnapshot = await firestore
      .collection("globalContent")
      .where("pageName", "==", data.pageName)
      .get();

    if (querySnapshot.empty) {
      firestore
        .collection("globalContent")
        .add({
          ...data,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          dispatch({ type: SUBMIT_GLOBAL_CONTENT_SUCCESS });
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: SUBMIT_GLOBAL_CONTENT_ERROR, payload: error });
        });
    } else {
      const docRef = querySnapshot.docs[0].ref;
      docRef
        .update({
          ...data,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          dispatch({ type: SUBMIT_GLOBAL_CONTENT_SUCCESS });
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: SUBMIT_GLOBAL_CONTENT_ERROR, payload: error });
        });
    }
  };

export const fetchGlobalContentData = (pageName) => async (dispatch) => {
  dispatch({ type: FETCH_GLOBAL_CONTENT_START });

  try {
    const firestore = firebase.firestore();
    const querySnapshot = await firestore
      .collection("globalContent")
      .where("pageName", "==", pageName)
      .get();

    if (!querySnapshot.empty) {
      const docData = querySnapshot.docs[0].data();
      dispatch({
        type: FETCH_GLOBAL_CONTENT_SUCCESS,
        payload: { pageName, data: docData },
      });
    } else {
      dispatch({
        type: FETCH_GLOBAL_CONTENT_SUCCESS,
        payload: { pageName, data: {} },
      });
    }
  } catch (error) {
    dispatch({ type: FETCH_GLOBAL_CONTENT_ERROR, payload: error });
  }
};

export const setDeleteLoader = (val) => async (dispatch) => {
  dispatch({ type: SET_DELETION_LOADER, payload: val });
};

export const submitDefaultGlobalContentData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: SUBMIT_GLOBAL_CONTENT_START });
    let { defaultKiiLogo, defaultHeaderBackground } = data;

    const firestore = firebase.firestore();
    const querySnapshot = await firestore
      .collection("globalContent")
      .where("pageName", "==", data.pageName)
      .get();

    if (querySnapshot.empty) {
      if (defaultHeaderBackground) {
        const imageFile = data.defaultHeaderBackground;
        const fileName = data.defaultHeaderBackground.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("default images/" + fileNameWithExtension);
        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
        data.defaultHeaderBackground = downloadURL;
      }
      if (defaultKiiLogo) {
        const imageFile = data.defaultKiiLogo;
        const fileName = data.defaultKiiLogo.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("default images/" + fileNameWithExtension);
        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
        data.defaultKiiLogo = downloadURL;
      }

      firestore
        .collection("globalContent")
        .add({
          ...data,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          dispatch({ type: SUBMIT_GLOBAL_CONTENT_SUCCESS });
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: SUBMIT_GLOBAL_CONTENT_ERROR, payload: error });
        });
    } else {
      const docRef = querySnapshot.docs[0].ref;

      let prevData = querySnapshot.docs[0].data();

      if (defaultHeaderBackground) {
        if (prevData && prevData.defaultHeaderBackground) {
          await firebase
            .storage()
            .refFromURL(prevData.defaultHeaderBackground)
            .delete();
        }
        const imageFile = data.defaultHeaderBackground;
        const fileName = data.defaultHeaderBackground.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("default images/" + fileNameWithExtension);
        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
        data.defaultHeaderBackground = downloadURL;
      }
      if (defaultKiiLogo) {
        if (prevData && prevData.defaultKiiLogo) {
          await firebase.storage().refFromURL(prevData.defaultKiiLogo).delete();
        }
        const imageFile = data.defaultKiiLogo;
        const fileName = data.defaultKiiLogo.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("default images/" + fileNameWithExtension);
        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
        data.defaultKiiLogo = downloadURL;
      }

      console.log("Going to updated", data);

      docRef
        .update({
          ...data,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          dispatch({ type: SUBMIT_GLOBAL_CONTENT_SUCCESS });
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: SUBMIT_GLOBAL_CONTENT_ERROR, payload: error });
        });
    }
  };
