import SubAdminRespository from "./SubAdminRespository";
import StatsRepository from "./StatsRepository";

const repositories = {
  subAdmin: SubAdminRespository,
  stats: StatsRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
