import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { DropdownIndicator } from "components/DropdownIndicator";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { fetchCodesData } from "store/actions/codeAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateCodeData } from "store/actions/codeAction";
import { customStyles } from "assets/customStyle/reactSelectCustomStyle";
import Select from "react-select";
import { searchOffers } from "store/actions/offerAction";
import { fetchSingleOffer } from "store/actions/offerAction";
import { fetchSingleBrand } from "store/actions/brandAction";

const EditCode = ({ editCodeOpen, editCodeToggle, codeDetails }) => {
  const dispatch = useDispatch();
  const [selectedOffer, setSelectedOffer] = useState(null);
  const { singleBrand } = useSelector((state) => state.brand);
  const { isLoading, page, hitsPerPage } = useSelector((state) => state.code);
  const { searchedOffers, singleOffer } = useSelector((state) => state.offer);
  let dynamicOffers = searchedOffers.map((offer) => ({
    label: `${offer.offerTitle} (${offer?.id})`,
    value: offer.id,
  }));
  useEffect(() => {
    if (!codeDetails) {
      setSelectedOffer(null);
    }
  }, [dispatch]);

  useEffect(() => {
    if (codeDetails) {
      dispatch(fetchSingleOffer(codeDetails?.offer));
    }
    if (codeDetails?.brandName) {
      dispatch(fetchSingleBrand(codeDetails?.brandName));
      dispatch(searchOffers(codeDetails?.brandName));
    }
  }, [codeDetails]);

  useEffect(() => {
    setSelectedOffer({
      label: `${singleOffer?.offerTitle} (${singleOffer?.id})`,
      value: singleOffer?.objectID,
    });
  }, [singleOffer]);

  const initialValues = {
    offer: codeDetails?.offer || "",
    generic: codeDetails?.code || "",
    showCodeType: codeDetails?.showCodeType || "",
  };

  const validationSchema = Yup.object({
    offer: Yup.string().required("Required"),
    generic: Yup.string().required("Required"),
    showCodeType: Yup.string().required("Required"),
  });
  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      updateCodeData({ ...values, id: codeDetails.id }, () => {
        editCodeToggle();
        resetForm();
        setSelectedOffer(null);
        dispatch(searchOffers(""));

        // setTimeout(() => {
        //   dispatch(fetchCodesData(page, hitsPerPage));
        // }, 5000);
      })
    );
  };

  const getBrandName = () => {
    if (singleBrand?.id) {
      return singleBrand?.brandName + " (" + singleBrand?.id + ")";
    } else {
      return "";
    }
  };

  const getOfferName = () => {
    if (singleOffer?.id) {
      return singleOffer?.offerTitle + " (" + singleOffer?.id + ")";
    } else {
      return;
    }
  };

  return (
    <div>
      <Modal isOpen={editCodeOpen}>
        <ModalHeader
          toggle={() => {
            dispatch(searchOffers(""));
            editCodeToggle();
          }}
        >
          Update Code
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <Row>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Brand Name</Label>
                      <Field
                        type="text"
                        className="form-control"
                        value={getBrandName()}
                        disabled
                        // name="brandName"
                        // required
                      />
                      {/* <ErrorMessage
                        name="brandName"
                        component="div"
                        className="text-danger"
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Offer</Label>
                      <Field
                        type="text"
                        className="form-control"
                        value={getOfferName()}
                        disabled
                        // name="brandName"
                        // required
                      />

                      {/* <Select
                        styles={customStyles}
                      
                        components={{ DropdownIndicator }}
                        placeholder="Choose option"
                        options={dynamicOffers}
                        value={selectedOffer}
                        onChange={(newValue) => {
                          setSelectedOffer(newValue);
                          setFieldValue("offer", newValue?.value);
                        }}
                      
                      />
                      <ErrorMessage
                        name="offer"
                        component="div"
                        className="text-danger"
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Show Code Type</Label>
                      <Field
                        as="select"
                        className="form-control"
                        name="showCodeType"
                      >
                        <option defaultValue={0}>Select</option>
                        <option value="qrCode">Qr Code</option>
                        <option value="textCode">Text Code</option>
                      </Field>
                      <ErrorMessage
                        name="showCodeType"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Code</Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="generic"
                        required
                      />
                      <ErrorMessage
                        name="generic"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-end px-3">
                  <Button
                    color="secondary"
                    disabled={isLoading}
                    onClick={() => {
                      editCodeToggle();
                      setSelectedOffer(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isLoading} color="primary">
                    {isLoading ? "Updating" : "Update"}
                  </Button>{" "}
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditCode;
