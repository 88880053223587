import React, { useEffect } from "react";
import { Col, Button, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
// import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
// import { customStyles } from "assets/customStyle/reactSelectCustomStyle";
// import IndustrySector from "components/Modals/IndustrySector";
// import firebase from "../../config/firebase";
// import { v4 as uuidv4 } from "uuid";

// const components = {
//   DropdownIndicator: null,
// };
// const createOption = (label) => ({
//   label,
//   value: label,
// });

const MyAccountDetails = () => {
  // const [inputValue, setInputValue] = useState("");
  // const [industryModal, setIndustryModal] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, myAccountDetails, isFetchFunctionLoading } = useSelector(
    (state) => state.globalContent
  );

  useEffect(() => {
    dispatch(fetchGlobalContentData("myAccountDetails"));
  }, [dispatch]);

  const initialValues = {
    myAccountDetailsTitle: myAccountDetails?.myAccountDetailsTitle || "",
    myAccountDetailsText: myAccountDetails?.myAccountDetailsText || "",
    optInText: myAccountDetails?.optInText || "",
    optInTextCheckboxYesOptions:
      myAccountDetails?.optInTextCheckboxYesOptions || "",
    optInTextCheckboxNoOptions:
      myAccountDetails?.optInTextCheckboxNoOptions || "",
    updateButtonText: myAccountDetails?.updateButtonText || "",
    // industrySectorDropdown: myAccountDetails?.industrySectorDropdown || [],
    // pronounFieldOptions: myAccountDetails?.pronounFieldOptions || [],
  };

  const validationSchema = Yup.object({
    myAccountDetailsTitle: Yup.string().required("Required"),
    myAccountDetailsText: Yup.string().required("Required"),
    optInText: Yup.string().required("Required"),
    optInTextCheckboxYesOptions: Yup.string().required("Required"),
    optInTextCheckboxNoOptions: Yup.string().required("Required"),
    updateButtonText: Yup.string().required("Required"),
    // industrySectorDropdown: Yup.array()
    //   .min(1, "At least one option is required")
    //   .required("Required"),
    // pronounFieldOptions: Yup.array()
    //   .min(1, "At least one option is required")
    //   .required("Required"),
  });

  // const handleKeyDown = (event, formik) => {
  //   if (!inputValue) return;
  //   switch (event.key) {
  //     case "Tab":
  //       formik.setFieldValue("pronounFieldOptions", [
  //         ...formik.values.pronounFieldOptions,
  //         createOption(inputValue),
  //       ]);
  //       setInputValue("");
  //       event.preventDefault();
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleSubmit = async (values) => {
    // if (values.industrySectorDropdown) {
    //   const updatedOptions = await Promise.all(
    //     values.industrySectorDropdown.map(async (data) => {
    //       const imageFile = data.icon;
    //       const fileName = data.icon.name;
    //       const fileExtension = fileName.slice(fileName.lastIndexOf("."));
    //       const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
    //       const storageRef = firebase
    //         .storage()
    //         .ref("dropdownIcons/" + fileNameWithExtension);
    //       const uploadTaskSnapshot = await storageRef.put(imageFile);
    //       const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
    //       return { ...data, icon: downloadURL };
    //     })
    //   );
    //   values.industrySectorDropdown = updatedOptions;
    // }
    // const pronounOptions = values.pronounFieldOptions.map((option) => {
    // //Remove the "__isNew__" property from each option
    //   const { __isNew__, ...cleanOption } = option;
    //   return cleanOption;
    // });

    // values.pronounFieldOptions = pronounOptions;

    dispatch(
      submitGlobalContentData(
        { pageName: "myAccountDetails", ...values },
        () => {
          toast.success("Data Submitted Successfully");
        }
      )
    );
  };

  // const toggleIndustryModal = () => {
  //   setIndustryModal(!industryModal);
  // };

  return (
    <div>
      {isFetchFunctionLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form className="p-4">
              <Row className="mt-3">
                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>My Account Details Title</h4>
                    </Label>
                    <Field
                      type="text"
                      name="myAccountDetailsTitle"
                      as={Input}
                    />
                    <ErrorMessage
                      name="myAccountDetailsTitle"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>

                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>My Account Details Text</h4>
                    </Label>
                    <Field
                      type="textarea"
                      name="myAccountDetailsText"
                      as={Input}
                    />
                    <ErrorMessage
                      name="myAccountDetailsText"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>Opt In Text</h4>
                    </Label>
                    <Field type="textarea" name="optInText" as={Input} />
                    <ErrorMessage
                      name="optInText"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>Opt In Text Checkbox Yes Option</h4>
                    </Label>
                    <Field
                      type="text"
                      name="optInTextCheckboxYesOptions"
                      as={Input}
                    />
                    <ErrorMessage
                      name="optInTextCheckboxYesOptions"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>Opt In Text Checkbox No Option</h4>
                    </Label>
                    <Field
                      type="text"
                      name="optInTextCheckboxNoOptions"
                      as={Input}
                    />
                    <ErrorMessage
                      name="optInTextCheckboxNoOptions"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>Update Button Text</h4>
                    </Label>
                    <Field type="text" name="updateButtonText" as={Input} />
                    <ErrorMessage
                      name="updateButtonText"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                {/* <Col lg="6" xl="4" onClick={toggleIndustryModal}>
                  <FormGroup>
                    <Label>
                      <h4>Industry Sector Dropdown</h4>
                    </Label>
                    <Button block type="button">
                      Add
                    </Button>
                    <ErrorMessage
                      name="industrySectorDropdown"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                {industryModal && (
                  <IndustrySector
                    show={industryModal}
                    toggle={toggleIndustryModal}
                    formik={formik}
                  />
                )} */}
                {/* <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>Pronoun Field Dropdown Options</h4>
                    </Label>
                    <CreatableSelect
                      components={components}
                      isMulti
                      styles={customStyles}
                      isClearable={true}
                      inputValue={inputValue}
                      onInputChange={(newValue) => setInputValue(newValue)}
                      onKeyDown={(event) => handleKeyDown(event, formik)}
                      placeholder="Enter option and press tab..."
                      options={formik.values.pronounFieldOptions}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={(newValue) => {
                        formik.setFieldValue("pronounFieldOptions", newValue);
                      }}
                    />
                    <ErrorMessage
                      name="pronounFieldOptions"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col> */}
              </Row>
              <div className="d-flex justify-content-end mb-4">
                <Button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-success"
                >
                  {isLoading ? "Submitting" : "Submit"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default MyAccountDetails;
