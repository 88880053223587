import Loader from "components/Loader";
import CodesModal from "components/Modals/dashboard/CodesModal";
import RegistrationModal from "components/Modals/dashboard/RegistrationModal";
import UsersModal from "components/Modals/dashboard/UsersModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { fetchStatsData } from "store/actions/statsAction";

import firebase from "../../config/firebase";

const Header = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    stats,
    codesCount,
    usersCount,
    registrationCount,
    isGettingCounts,
  } = useSelector((state) => state.stats);

  const [codesModal, setCodesModal] = useState(false);

  const [usersModal, setUserModal] = useState(false);
  const [registrationModal, setRegistrationModal] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState({
    codes: false,
    users: false,
    registrations: false,
  });

  const [codesDetails, setCodesDetails] = useState({
    brand: {},
    offer: {},
    from: "",
    to: "",
  });

  const [usersDetails, setUsersDetails] = useState({
    from: "",
    to: "",
  });

  const [registrationsDetails, setRegistrationsDetails] = useState({
    from: "",
    to: "",
  });

  const handleCodeChange = (obj) => {
    setCodesDetails(obj);
  };
  const handleUsersChange = (obj) => {
    setUsersDetails(obj);
  };

  const handleRegistrationsChange = (obj) => {
    setRegistrationsDetails(obj);
  };

  const onSelectedDateRangeChange = (name, val) => {
    setSelectedDateRange((prev) => ({ ...prev, [name]: val }));
  };

  const codesToggle = () => {
    setCodesModal(!codesModal);
  };

  const usersToggle = () => {
    setUserModal(!usersModal);
  };
  const registrationToggle = () => {
    setRegistrationModal(!registrationModal);
  };

  useEffect(() => {
    dispatch(fetchStatsData());
  }, []);

  console.log("Usersdfsdf", usersDetails);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body  justify-content-around">
            {/* <Button
              className="config check my-3"
              onClick={async () => {
                const remoteConfig = firebase.remoteConfig();
                await remoteConfig.fetchAndActivate();
                remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

                // Get the welcome message from remote config
                const welcomeMsg = remoteConfig.getString(
                  "alogliaClientSecret"
                );
                console.log("Welcom", welcomeMsg);
              }}
            >
              Config Check
            </Button> */}
            {!isLoading && (
              <Row>
                <Col lg="6">
                  <Card className="card-stats mb-4 ">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Brands
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {stats[0]?.brands}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2"></span>{" "}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className="card-stats mb-4 ">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Offers
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {" "}
                            {stats[0]?.offers}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2"></span>{" "}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className="card-stats mb-4 ">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Code
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {" "}
                            {selectedDateRange?.codes
                              ? codesCount
                              : stats[0]?.codes}
                          </span>
                        </div>
                        <Col className="col-auto d-flex flex-column align-items-end">
                          <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                            <i className="fas fa-code" />
                          </div>
                          <Button
                            size="sm"
                            className="mt-2"
                            onClick={codesToggle}
                          >
                            Select date range
                          </Button>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">Test Brand</span>{" "}
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Users
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {/* {" "}
                            {stats[0]?.users} */}
                            {selectedDateRange?.users
                              ? usersCount
                              : stats[0]?.users}
                          </span>
                        </div>
                        <Col className="col-auto d-flex flex-column align-items-end">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                          <Button
                            size="sm"
                            className="mt-2"
                            onClick={usersToggle}
                          >
                            Select date range
                          </Button>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2"></span>{" "}
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6">
                  <Card className="card-stats mb-4 ">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Registration
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {selectedDateRange?.registrations
                              ? registrationCount
                              : stats[0]?.registrations}
                          </span>
                        </div>
                        <Col className="col-auto d-flex flex-column align-items-end">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                          <Button
                            size="sm"
                            className="mt-2"
                            onClick={registrationToggle}
                          >
                            Select date range
                          </Button>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2"></span>{" "}
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {isLoading && <Loader />}
          </div>
        </Container>
        <CodesModal
          isOpen={codesModal}
          isToggle={codesToggle}
          onSelectedDateRangeChange={onSelectedDateRangeChange}
          handleCodeChange={handleCodeChange}
          selectedDateRange={selectedDateRange}
          codesDetails={codesDetails}
        />
        <UsersModal
          isOpen={usersModal}
          isToggle={usersToggle}
          onSelectedDateRangeChange={onSelectedDateRangeChange}
          handleUsersChange={handleUsersChange}
          selectedDateRange={selectedDateRange}
          usersDetails={usersDetails}
        />
        <RegistrationModal
          isOpen={registrationModal}
          isToggle={registrationToggle}
          onSelectedDateRangeChange={onSelectedDateRangeChange}
          selectedDateRange={selectedDateRange}
          handleRegistrationsChange={handleRegistrationsChange}
          registrationsDetails={registrationsDetails}
        />
      </div>
    </>
  );
};

export default Header;
