import OnlyHeader from "components/Headers/OnlyHeader";
import Loader from "components/Loader";
import AddDomain from "components/Modals/AddDomain";
import EditDomain from "components/Modals/EditDomain";
import { Field } from "formik";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Card,
  CardHeader,
  Table,
  Media,
  Button,
  CardFooter,
  FormGroup,
  Input,
} from "reactstrap";
import { deleteDomain } from "store/actions/domainAction";
import { fetchDomainsData } from "store/actions/domainAction";
import { fetchDropdownData } from "store/actions/dropdownAction";
import { SET_DOMAIN_PAGE } from "store/types";
import { BsDownload } from "react-icons/bs";
import ConfirmationModal from "components/Modals/ConfirmationModal";

const Verifieddomain = () => {
  // const [searchQuery, setSearchQuery] = useState("");
  let searchTimeout;

  const [filterBy, setFilterBy] = useState("all");
  const [search, setSearch] = useState("");
  const [filterField, setfilterField] = useState("");
  const dispatch = useDispatch();
  const { domainData, isLoading, totalPages, page, hitsPerPage } = useSelector(
    (state) => state.domain
  );
  const { dropdownData } = useSelector((state) => state.dropdown);
  const [verify, setVerify] = useState(false);
  const [EditVerify, setEditVerify] = useState(false);
  const [showIndustry, setShowIndustry] = useState(false);

  const toggleeditverify = () => {
    setEditVerify(!EditVerify);
  };
  const toggleVerify = () => {
    setVerify(!verify);
  };

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [confirmationId, setConfirmationId] = useState("");

  const [industrySector, setIndustrySector] = useState("");

  const confirmationToggle = () => {
    setConfirmationModal(!confirmationModal);
  };

  function handlePageClick({ selected: selectedPage }) {
    dispatch({ type: SET_DOMAIN_PAGE, payload: selectedPage });
  }
  const [editDomain, seteditDomain] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  useEffect(() => {
    fetchDomains();
    // dispatch(fetchDomainsData(page, hitsPerPage, filterBy, filterField));
  }, [dispatch, page, selectedIndustry]);
  const handleSearch = (val) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      dispatch(fetchDomainsData(page, hitsPerPage, filterField, val));
    }, 700);
  };

  const fetchDomains = () => {
    if (filterBy != "search") {
      let filterByVal =
        selectedIndustry != "" && filterBy == "industry"
          ? selectedIndustry
          : filterBy;
      dispatch(fetchDomainsData(page, hitsPerPage, filterByVal, filterField));
    } else {
      handleSearch(search);
    }
  };
  console.log("Filter by", filterBy);

  useEffect(() => {
    console.log("This is going to run..", filterBy);
    if (filterBy != "industry" && filterBy != "search") {
      dispatch(fetchDomainsData(page, hitsPerPage, filterBy, filterField));
    }
  }, [filterBy]);

  useEffect(() => {
    dispatch(fetchDropdownData());
  }, []);

  const handleDownload = () => {
    // Define the header row
    const header = "ID,Approved Domains";

    // Convert domainData to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      header +
      "\n" +
      domainData
        .map((domain) => `${domain.id},${domain.domainName}`)
        .join("\n");

    // Create a dynamic link element and trigger the download
    const linkElement = document.createElement("a");
    linkElement.setAttribute("href", encodeURI(csvContent));
    linkElement.setAttribute("download", "domainData.csv");
    document.body.appendChild(linkElement);

    linkElement.click();

    document.body.removeChild(linkElement);
  };

  const handleEdit = (verified) => {
    toggleeditverify();
    seteditDomain(verified);
  };

  return (
    <>
      <OnlyHeader />
      <AddDomain
        verify={verify}
        toggleVerify={toggleVerify}
        filterBy={filterBy}
        filterField={filterField}
        fetchDomains={fetchDomains}
      />
      <EditDomain
        EditVerify={EditVerify}
        toggleeditverify={toggleeditverify}
        editDomain={editDomain}
        filterBy={filterBy}
        filterField={filterField}
        fetchDomains={fetchDomains}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0">Domains</h3>
                <div className="d-flex align-items-center">
                  <Input
                    type="select"
                    id="offerType"
                    name="offerType"
                    className="form-control mr-2"
                    value={filterBy}
                    onChange={(e) => {
                      if (e.target.value === "search") {
                        setShowIndustry(false);
                        setFilterBy(e.target.value);
                        setfilterField("domainName");
                      } else if (e.target.value === "industry") {
                        setSearch("");
                        setShowIndustry(true);
                        setFilterBy(e.target.value);
                        setfilterField("industrySector");
                      } else if (e.target.value === "all") {
                        setShowIndustry(false);
                        setSearch("");

                        setFilterBy("");
                        setfilterField("");
                      } else {
                        setSearch("");

                        setShowIndustry(false);
                        setfilterField("domainType");
                        setFilterBy(e.target.value);
                        dispatch({ type: SET_DOMAIN_PAGE, payload: 0 });
                      }
                      setSelectedIndustry("");
                    }}
                  >
                    {/* <option value="">Filter By</option> */}
                    <option value="all">All</option>
                    <option value="search">Search</option>
                    <option value="default">Default</option>
                    <option value="special">Special</option>
                    <option value="industry">Industry Sector</option>
                  </Input>
                  {showIndustry && (
                    <Input
                      type="select"
                      id="offerType"
                      name="offerType"
                      className="form-control"
                      value={selectedIndustry}
                      onChange={(e) => {
                        // setFilterBy(e.target.value);
                        setSelectedIndustry(e.target.value);
                        dispatch({ type: SET_DOMAIN_PAGE, payload: 0 });
                      }}
                    >
                      <option value="" disabled>
                        Select Industry
                      </option>
                      {dropdownData.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.option}
                        </option>
                      ))}
                    </Input>
                  )}
                  {filterBy == "search" && (
                    <Input
                      type="text"
                      value={search}
                      placeholder="Search by domain "
                      onChange={(e) => {
                        setSearch(e.target.value);

                        if (page != 0) {
                          dispatch({ type: SET_DOMAIN_PAGE, payload: 0 });
                        } else {
                          handleSearch(e.target.value);
                        }
                      }}
                    />
                  )}
                  <Button outline onClick={handleDownload} className="ml-2">
                    <BsDownload size={20} />
                  </Button>
                </div>
                <div>
                  <Button onClick={toggleVerify}>Add Domain</Button>
                </div>
              </CardHeader>
              <Table className=" table-flush  " responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Approved Domains</th>
                    <th scope="col">Industry Sector</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    domainData.map((verified, index) => (
                      <tr key={index}>
                        <td>{verified.id}</td>
                        <th scope="row">
                          <Media>
                            <span className="mb-0 text-sm">
                              {verified.domainName}
                            </span>
                          </Media>
                        </th>
                        <td>
                          {dropdownData.map((item) => {
                            if (item.id === verified.industrySector) {
                              return item.option;
                            }
                          })}
                        </td>
                        <td>
                          <Button
                            className="btn btn-success"
                            size="sm"
                            onClick={() => {
                              handleEdit(verified);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            size="sm"
                            className="btn btn-danger"
                            onClick={() => {
                              setConfirmationId(verified?.id);
                              setIndustrySector(verified?.industrySector);
                              confirmationToggle();
                              // dispatch(
                              //   deleteDomain(verified.id, () => {
                              //     setTimeout(() => {
                              //       dispatch(
                              //         fetchDomainsData(
                              //           domainData.length > 1
                              //             ? page
                              //             : page === 0
                              //             ? page
                              //             : page - 1,
                              //           hitsPerPage,
                              //           filterBy,
                              //           filterField
                              //         )
                              //       );
                              //     }, 5000);
                              //   })
                              // )
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {isLoading && <Loader />}
              {!isLoading && (
                <CardFooter className="py-4 d-flex justify-content-end">
                  <nav>
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={totalPages}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link "}
                      nextLinkClassName={"pagination__link border "}
                      pageLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={page}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>

        <ConfirmationModal
          isOpen={confirmationModal}
          isToggle={confirmationToggle}
          id={confirmationId}
          handleDeletion={() => {
            dispatch(
              deleteDomain(confirmationId, industrySector, () => {
                setTimeout(() => {
                  confirmationToggle();

                  if (filterBy != "search") {
                    let filterByVal =
                      selectedIndustry != "" && filterBy == "industry"
                        ? selectedIndustry
                        : filterBy;
                    dispatch(
                      fetchDomainsData(
                        domainData.length > 1
                          ? page
                          : page === 0
                          ? page
                          : page - 1,
                        hitsPerPage,
                        filterByVal,
                        filterField
                      )
                    );
                  } else {
                    handleSearch(search);
                  }
                }, 5000);
              })
            );
          }}
        />
      </Container>
    </>
  );
};

export default Verifieddomain;
