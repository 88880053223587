import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { fetchSearchedBrands } from "store/actions/brandAction";
import { customStyles } from "assets/customStyle/reactSelectCustomStyle";
import Select from "react-select";
import { searchOffers } from "store/actions/offerAction";
import { getRegistrationCount } from "store/actions/statsAction";

const RegistrationModal = ({
  isOpen,
  isToggle,
  onSelectedDateRangeChange,
  registrationsDetails,
  handleRegistrationsChange,
  selectedDateRange,
}) => {
  const initialValues = {
    from: registrationsDetails?.from || "",
    to: registrationsDetails?.to || "",
  };
  const { isGettingCounts } = useSelector((state) => state.stats);
  const validationSchema = Yup.object({
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
  });

  const dispatch = useDispatch();

  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      getRegistrationCount(
        {
          from: values?.from,
          to: values?.to,
        },
        () => {
          onSelectedDateRangeChange("registrations", true);
          handleRegistrationsChange({ from: values?.from, to: values?.to });

          isToggle();
        }
      )
    );
  };
  return (
    <Modal isOpen={isOpen} centered size="md">
      <ModalHeader isOpen={isOpen} toggle={isToggle}>
        Registrations
      </ModalHeader>
      <ModalBody className="pt-0">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>From</Label>
                    <Input
                      as="input"
                      type="date"
                      className="form-control"
                      name="from"
                      value={values?.from}
                      onChange={(e) => {
                        setFieldValue("from", e.target.value);
                        setFieldValue("to", "");
                      }}
                    ></Input>
                    <ErrorMessage
                      name="from"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>To</Label>
                    <Input
                      as="input"
                      type="date"
                      className="form-control"
                      name="to"
                      value={values?.to}
                      onChange={(e) => setFieldValue("to", e.target.value)}
                      min={values?.from == "" ? "" : values.from}
                      disabled={values?.from == ""}
                    ></Input>
                    <ErrorMessage
                      name="to"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" className="d-flex justify-content-end">
                  {selectedDateRange?.registrations && (
                    <Button
                      type="submit"
                      color="secondary"
                      className="mr-2"
                      onClick={() => {
                        handleRegistrationsChange({
                          from: "",
                          to: "",
                        });
                        onSelectedDateRangeChange("registrations", false);
                        isToggle();
                      }}
                    >
                      Display All
                    </Button>
                  )}
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isGettingCounts}
                  >
                    {isGettingCounts ? "Submitting" : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default RegistrationModal;
