import { Field, Form, Formik, ErrorMessage } from "formik";
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { useHistory } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import firebase from "../../config/firebase";
import * as Yup from "yup";
import Brandheader from "components/Headers/Brandheader";
import { useDispatch, useSelector } from "react-redux";
import { submitBrandData } from "store/actions/brandAction";
import { fetchBrands } from "store/actions/brandAction";
import { contains } from "jquery";
import { toast } from "react-toastify";
import { fetchSearchedBrands } from "store/actions/brandAction";
import { fetchQueryBrand } from "store/actions/brandAction";

const AddBrandModal = ({ isModalOpen, toggleBrandModal, search }) => {
  const { searchedBrands, isBrandLoading, page, hitsPerPage } = useSelector(
    (state) => state.brand
  );
  const dispatch = useDispatch();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [showInputField, setShowInputField] = useState(false);

  const [typingTimeout, setTypingTimeout] = useState(null);

  const history = useHistory();
  const initialValues = {
    brandName: "",
    brandLogo: "",
    brandImage: "",
    brandLink: "",
    // brandSubdirectory: "",
    brandButtonColor: "#25b6ab",
    brandActive: false,
    backLink: false,
    // backLinkText: "",
    // limitedTime: false,
    // congratulationsCode: "",
    // congratulationsContinue: "",
    // returnBrandkiiSite: "",
  };

  const validationSchema = Yup.object().shape({
    brandName: Yup.string()
      .required("Brand Name is required")
      .matches(/^[A-Za-z0-9 .&'?!-]{3,50}$/, "Brand Name is not valid"),
    // .matches(/^[A-Za-z0-9 ]{3,50}$/, "Brand Name is not valid"),
    brandLogo: Yup.mixed()
      .required("Brand Logo is required")
      .test(
        "fileSize",
        "Size must be less than 1 MB",
        // (value) => value && value.size <= 1024 * 1024
        (value) => !value || value.size <= 1024 * 1024
      ),
    brandImage: Yup.mixed()
      .required("Brand Image is required")
      .test(
        "fileSize",
        "Size must be less than 1 MB",
        (value) => !value || value.size <= 1024 * 1024
      ),
    brandLink: Yup.string()
      .required("Brand Link is required")
      .matches(
        /\b(?:https?:\/\/)[^\s/$.?#]+\.[^\s]+\b/,
        "Brand Link must be a valid URL"
      ),
    // brandSubdirectory: Yup.string().required("Subdirectory Name is required"),
    brandButtonColor: Yup.string().required("Brand Button Color is required"),
    // limitedTime: Yup.string().required("Limited time is required"),
    // congratulationsCode: Yup.string().required("Brand Link is required"),
    // congratulationsContinue: Yup.string().required("Brand Link is required"),
    // returnBrandkiiSite: Yup.string().required("Brand Link is required"),
  });

  const handleLogoChange = (event, setFieldValue) => {
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];

      if (file && file.type.startsWith("image/")) {
        setFieldValue("brandLogo", file);
      } else {
        toast.error("Selected file is not a valid image.");
        setFieldValue("brandLogo", null);
        document.getElementById("brandLogo").value = "";
      }
    } else {
      setFieldValue("brandLogo", null);
      document.getElementById("brandLogo").value = "";
    }
  };

  const handleImageChange = (event, setFieldValue) => {
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        setFieldValue("brandImage", file);
      } else {
        toast.error("Selected file is not a valid image.");
        setFieldValue("brandImage", null);
        document.getElementById("brandImage").value = "";
      }
    } else {
      setFieldValue("brandImage", null);
      document.getElementById("brandImage").value = "";
    }
  };

  const handleSwitchToggle = (event, setFieldValue) => {
    const { checked } = event.target;
    setShowInputField(checked);
    setFieldValue("backLink", checked);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      dispatch(
        submitBrandData(values, () => {
          setTimeout(() => {
            resetForm();
            toggleBrandModal();
            dispatch(fetchBrands(page, hitsPerPage, search));
          }, 5000);
        })
      );
    } catch (error) {
      console.log("Error checking brand existence:", error);
    }
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggleBrandModal} size="lg">
      {/* <Brandheader /> */}
      {/* <Container fluid className="pb-4"> */}
      {/* <Card className="mb-4"> */}
      <ModalHeader toggle={toggleBrandModal}>
        <div className="d-flex justify-content-between">
          <h2>Add Brand</h2>
          {/* <Button onClick={() => history.push("../admin/brandspage")}>
            Back
          </Button> */}
        </div>
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandName">Brand Name</Label>
                    <Field
                      type="text"
                      id="brandName"
                      name="brandName"
                      placeholder="Enter Brand Name Here"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="brandName"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandLogo">Brand Logo</Label>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Input
                      id="brandLogo"
                      name="brandLogo"
                      type="file"
                      accept=".jpg, .jpeg, .svg, .png"
                      onChange={(event) => {
                        console.log("Run.. on change of logo");
                        handleLogoChange(event, formik.setFieldValue);
                      }}
                    />
                    <ErrorMessage
                      name="brandLogo"
                      component="div"
                      className="text-danger"
                    />
                    <small className="text-muted mt-2">
                      Recommended dimensions: 51x54 pixels
                    </small>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandImage">Brand Image</Label>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Input
                      id="brandImage"
                      name="brandImage"
                      type="file"
                      accept=".jpg, .jpeg, .svg, .png"
                      onChange={(event) =>
                        handleImageChange(event, formik.setFieldValue)
                      }
                    />
                    <ErrorMessage
                      name="brandImage"
                      component="div"
                      className="text-danger"
                    />
                    <small className="text-muted mt-2">
                      Recommended dimensions: 450x161 pixels
                    </small>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandLink">Brand Link</Label>
                    <Field
                      type="url"
                      id="brandLink"
                      name="brandLink"
                      placeholder="Paste Your Link Here"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="brandLink"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  {/* <FormGroup>
                    <Label for="brandSubdirectory">Subdirectory Name</Label>
                    <Field
                      type="text"
                      id="brandSubdirectory"
                      name="brandSubdirectory"
                      placeholder="Paste Your Link Here"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="brandSubdirectory"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup> */}
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandButtonColor">Brand Button Color</Label>
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        background: formik.values.brandButtonColor,
                        borderRadius: "20%",
                        border: "1px solid black",
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    ></div>
                    {isColorPickerOpen && (
                      <SketchPicker
                        color={formik.values.brandButtonColor}
                        onChange={(clr) => {
                          formik.setFieldValue("brandButtonColor", clr.hex);

                          clearTimeout(typingTimeout);
                          setTypingTimeout(
                            setTimeout(() => {
                              setIsColorPickerOpen(false);
                            }, 1500)
                          );

                          // setIsColorPickerOpen(false);
                        }}
                      />
                    )}
                    <ErrorMessage
                      name="brandButtonColor"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="brandActive">Brand Active</Label>
                    <CustomInput
                      type="switch"
                      id="brandActive"
                      name="brandActive"
                      onChange={(e) =>
                        formik.setFieldValue("brandActive", e.target.checked)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="backLink">Back Link</Label>
                    <CustomInput
                      type="switch"
                      id="backLink"
                      name="backLink"
                      onChange={(event) =>
                        handleSwitchToggle(event, formik.setFieldValue)
                      }
                    />
                  </FormGroup>
                </Col>
                {/* <Col lg="6">
                  {showInputField && (
                    <FormGroup>
                      <Label for="backLinkText">Input Field</Label>
                      <Field
                        type="url"
                        id="backLinkText"
                        name="backLinkText"
                        placeholder="Put new Url Here"
                        required
                        className="form-control"
                      />
                    </FormGroup>
                  )}
                </Col> */}
              </Row>
              {/* <CardHeader>
                <h2>Brands Content</h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" xl="4">
                    <FormGroup>
                      <Label>
                        <h4>Limited Time</h4>
                      </Label>
                      <CustomInput
                        type="switch"
                        id="limitedTime"
                        name="limitedTime"
                        onChange={(e) =>
                          formik.setFieldValue("limitedTime", e.target.checked)
                        }
                      />

                    
                    </FormGroup>
                  </Col>
                  
                </Row>
              </CardBody> */}
              <CardFooter>
                <div className="d-flex justify-content-end">
                  {/* <Button onClick={() => history.push("../admin/brandspage")}> */}
                  <Button onClick={toggleBrandModal}>Cancel</Button>
                  <Button
                    type="submit"
                    disabled={isBrandLoading}
                    color="primary"
                  >
                    {isBrandLoading ? "Submitting" : "Submit"}
                  </Button>
                </div>
              </CardFooter>
            </Form>
          )}
        </Formik>
      </ModalBody>
      {/* </Card> */}
      {/* </Container> */}
    </Modal>
  );
};

export default AddBrandModal;
