import firebase from "../../config/firebase";
import {
  FETCH_STATS_START,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_ERROR,
} from "store/types";

import { RepositoryFactory } from "repository/RepositoryFactory";
import { toast } from "react-toastify";
let Stats = RepositoryFactory.get("stats");

export const fetchStatsData = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_STATS_START });
    const querySnapshot = await firebase
      .firestore()
      .collection("dashboardStats")
      .get();

    let stats = [];
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      stats = [{ id: doc.id, ...doc.data() }];
    }
    dispatch({ type: FETCH_STATS_SUCCESS, payload: stats });
  } catch (error) {
    dispatch({ type: FETCH_STATS_ERROR, payload: error });
  }
};

export const getStatsCountLoader = (val) => async (dispatch) => {
  dispatch({ type: "SET_GET_STATS_COUNT_LOADER", payload: val });
};

export const getCodesCount =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(getStatsCountLoader(true));
      let { data } = await Stats.getCodesCount(payload);
      dispatch(getStatsCountLoader(false));
      dispatch({ type: "SET_CODES_COUNT", payload: data?.codes });
      onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(getStatsCountLoader(false));
      dispatch({ type: "SET_CODES_COUNT", payload: 0 });
    }
  };
export const getUsersCount =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(getStatsCountLoader(true));
      let { data } = await Stats.getUsersCount(payload);
      dispatch({ type: "SET_USERS_COUNT", payload: data?.users });
      dispatch(getStatsCountLoader(false));
      onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({ type: "SET_USERS_COUNT", payload: 0 });

      dispatch(getStatsCountLoader(false));
    }
  };
export const getRegistrationCount =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(getStatsCountLoader(true));
      let { data } = await Stats.getRegistrationCount(payload);
      dispatch({
        type: "SET_REGISTRATION_COUNT",
        payload: data?.registrations,
      });
      dispatch(getStatsCountLoader(false));
      onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({ type: "SET_REGISTRATION_COUNT", payload: 0 });

      dispatch(getStatsCountLoader(false));
    }
  };
