import React, { useEffect } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
import { toast } from "react-toastify";

const JoinFree = () => {
  const dispatch = useDispatch();
  const { isLoading, joinFree, isFetchFunctionLoading } = useSelector(
    (state) => state.globalContent
  );

  useEffect(() => {
    dispatch(fetchGlobalContentData("joinFree"));
  }, [dispatch]);

  const initialValues = {
    joinTitle: joinFree?.joinTitle || "",
    joinText: joinFree?.joinText || "",
    submitButtonText: joinFree?.submitButtonText || "",
    termsConditionsText: joinFree?.termsConditionsText || "",
    termsOfServiceLink: joinFree?.termsOfServiceLink || "",
    privacyPolicyLink: joinFree?.privacyPolicyLink || "",
    cookiePolicyLink: joinFree?.cookiePolicyLink || "",
    passwordMismatchErrorText: joinFree?.passwordMismatchErrorText || "",
    emailAlreadyExistsText: joinFree?.emailAlreadyExistsText || "",
    accNotVerifiedText: joinFree?.accNotVerifiedText || "",
  };

  const validationSchema = Yup.object({
    joinTitle: Yup.string().required("Required"),
    joinText: Yup.string().required("Required"),
    submitButtonText: Yup.string().required("Required"),
    termsConditionsText: Yup.string().required("Required"),
    termsOfServiceLink: Yup.string().required("Required"),
    privacyPolicyLink: Yup.string().required("Required"),
    cookiePolicyLink: Yup.string().required("Required"),
    passwordMismatchErrorText: Yup.string().required("Required"),
    emailAlreadyExistsText: Yup.string().required("Required"),
    accNotVerifiedText: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(
      submitGlobalContentData({ pageName: "joinFree", ...values }, () => {
        toast.success("Data Submitted Successfully");
      })
    );
  };

  return (
    <div>
      {isFetchFunctionLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <Form className="p-4">
            <Row className="mt-3">
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Join Title</h4>
                  </Label>
                  <Field type="text" name="joinTitle" as={Input} />
                  <ErrorMessage
                    name="joinTitle"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Join Text</h4>
                  </Label>
                  <Field type="textarea" name="joinText" as={Input} />
                  <ErrorMessage
                    name="joinText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Submit Button Text</h4>
                  </Label>
                  <Field type="text" name="submitButtonText" as={Input} />
                  <ErrorMessage
                    name="submitButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Terms & Conditions Text</h4>
                  </Label>
                  <Field type="text" name="termsConditionsText" as={Input} />
                  <ErrorMessage
                    name="termsConditionsText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Terms of Service Link</h4>
                  </Label>
                  <Field type="url" name="termsOfServiceLink" as={Input} />
                  <ErrorMessage
                    name="termsOfServiceLink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Privacy Policy Link</h4>
                  </Label>
                  <Field type="url" name="privacyPolicyLink" as={Input} />
                  <ErrorMessage
                    name="privacyPolicyLink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Cookie Policy Link</h4>
                  </Label>
                  <Field type="url" name="cookiePolicyLink" as={Input} />
                  <ErrorMessage
                    name="cookiePolicyLink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Password doesn't Match Text</h4>
                  </Label>
                  <Field
                    type="textarea"
                    name="passwordMismatchErrorText"
                    as={Input}
                  />
                  <ErrorMessage
                    name="passwordMismatchErrorText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Email Already Exists Text</h4>
                  </Label>
                  <Field
                    type="textarea"
                    name="emailAlreadyExistsText"
                    as={Input}
                  />
                  <ErrorMessage
                    name="emailAlreadyExistsText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Account not Verified Text</h4>
                  </Label>
                  <Field type="textarea" name="accNotVerifiedText" as={Input} />
                  <ErrorMessage
                    name="accNotVerifiedText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-4">
              <Button
                type="submit"
                disabled={isLoading}
                className="btn btn-success"
              >
                {isLoading ? "Submitting" : "Submit"}
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default JoinFree;
