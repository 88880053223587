import {
  FETCH_DOMAINS_START,
  FETCH_DOMAINS_SUCCESS,
  FETCH_DOMAINS_ERROR,
  SUBMIT_DOMAIN_START,
  SUBMIT_DOMAIN_SUCCESS,
  SUBMIT_DOMAIN_ERROR,
  UPDATE_DOMAIN_START,
  UPDATE_DOMAIN_SUCCESS,
  UPDATE_DOMAIN_ERROR,
  DELETE_DOMAIN_START,
  DELETE_DOMAIN_SUCCESS,
  DELETE_DOMAIN_ERROR,
  SET_DOMAIN_PAGE,
  SET_DOMAIN_TOTAL_PAGES,
  ADD_DOMAIN_PAYLOAD,
  UPDATE_DOMAIN_PAYLOAD,
} from "../types";

const initialState = {
  domainData: [],
  page: 0,
  totalPages: 0,
  hitsPerPage: 10,
  isLoading: false,
  error: null,
};

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOMAINS_START:
    case SUBMIT_DOMAIN_START:
    case UPDATE_DOMAIN_START:
    case DELETE_DOMAIN_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_DOMAINS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        domainData: action.payload,
      };
    case ADD_DOMAIN_PAYLOAD:
      return {
        ...state,
        domainData: [action.payload, ...state.domainData],
      };
    case UPDATE_DOMAIN_PAYLOAD:
      return {
        ...state,
        domainData: state.domainData.map((domain) =>
          domain.id == action.payload.id ? action.payload : domain
        ),
      };

    case SUBMIT_DOMAIN_SUCCESS:
    case UPDATE_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case SET_DOMAIN_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    case SET_DOMAIN_PAGE:
      console.log(action.payload);
      return {
        ...state,
        page: action.payload,
      };
    case DELETE_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        domainData: state.domainData.filter(
          (domain) => domain.id !== action.payload
        ),
      };
    case FETCH_DOMAINS_ERROR:
    case SUBMIT_DOMAIN_ERROR:
    case UPDATE_DOMAIN_ERROR:
    case DELETE_DOMAIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default domainReducer;
