import Loader from "components/Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { fetchDropdownData } from "store/actions/dropdownAction";
import { updateDropdownData } from "store/actions/dropdownAction";
import { deleteDropdown } from "store/actions/dropdownAction";
import { submitIndustrySectorData } from "store/actions/dropdownAction";
import * as Yup from "yup";

const IndustrySector = ({ show, toggle }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [preview, setPreview] = useState("");
  const dispatch = useDispatch();
  const { dropdownData, isLoading } = useSelector((state) => state.dropdown);

  const initialValues = {
    industrySectorLabel: isEdit ? editData.option : "",
    industrySectorIcon: null,
  };

  const validationSchema = Yup.object({
    industrySectorLabel: Yup.string().required("Required"),
    industrySectorIcon: Yup.mixed()
      .required("Required")
      .test("fileSize", "Max file size: 1MB", (value) => {
        if (value) {
          const fileSizeInBytes = value.size;
          const maxSizeInBytes = 1048576; // 1MB
          return fileSizeInBytes <= maxSizeInBytes;
        }
        return true;
      }),
  });
  const validationSchema2 = Yup.object({
    industrySectorLabel: Yup.string().required("Required"),
  });

  const handleCancel = () => {
    toggle();
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    if (isEdit) {
      dispatch(
        updateDropdownData({ ...values, id: editData.id }, () => {
          dispatch(fetchDropdownData());
          setSubmitting(false);
          resetForm();
          setIsEdit(false);
          setEditData();
          setPreview("");
          document.getElementById("iconInput").value = "";
        })
      );
    } else {
      dispatch(
        submitIndustrySectorData(values, () => {
          dispatch(fetchDropdownData());
          setSubmitting(false);
          resetForm();
          document.getElementById("iconInput").value = "";
        })
      );
    }
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setEditData(data);
    setPreview(data.icon);
  };

  useEffect(() => {
    dispatch(fetchDropdownData());
  }, []);

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Industry Sectors</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={isEdit ? validationSchema2 : validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        <h4>Industry Sector Field Dropdown Options</h4>
                      </Label>
                      <Field
                        as={Input}
                        type="text"
                        name="industrySectorLabel"
                        required
                      />
                      <ErrorMessage
                        name="industrySectorLabel"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" className="d-flex align-items-center">
                    <FormGroup>
                      <Label>
                        <h4>Industry Sector Icon</h4>
                      </Label>
                      <Input
                        id="iconInput"
                        type="file"
                        name="industrySectorIcon"
                        onChange={(event) => {
                          setPreview("");
                          formik.setFieldValue(
                            "industrySectorIcon",
                            event.currentTarget.files[0]
                          );
                        }}
                        accept=".jpg, .jpeg, .png,.svg"
                      />
                      <ErrorMessage
                        name="industrySectorIcon"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                    {preview.length > 0 && (
                      <img src={preview} width={45} height={45} alt="preview" />
                    )}
                  </Col>
                </Row>
                {dropdownData.length > 0 && (
                  <div className="industry-table-body mb-3">
                    <Table>
                      <thead className="thead-light">
                        <tr>
                          <th>Option</th>
                          <th>Icon</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading &&
                          dropdownData.map((industrySector, index) => (
                            <tr key={index}>
                              <td>{industrySector.option}</td>
                              <td>
                                {industrySector.icon && (
                                  <img
                                    height={40}
                                    width={40}
                                    src={industrySector.icon}
                                    alt="icon"
                                  />
                                )}
                              </td>
                              <td>
                                <Button
                                  size="sm"
                                  className="btn btn-success"
                                  onClick={() => handleEdit(industrySector)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn btn-danger"
                                  disabled={isEdit}
                                  onClick={() =>
                                    dispatch(deleteDropdown(industrySector.id))
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                )}
                {isLoading && <Loader />}
                <div className="d-flex justify-content-end">
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {isEdit
                      ? formik.isSubmitting
                        ? "Updating"
                        : "Update"
                      : formik.isSubmitting
                      ? "Submitting"
                      : "Submit"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default IndustrySector;
