import React, { useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateDomainData } from "store/actions/domainAction";
import { fetchDomainsData } from "store/actions/domainAction";

const EditDomain = ({
  EditVerify,
  toggleeditverify,
  editDomain,
  filterBy,
  filterField,
  fetchDomains,
}) => {
  const dispatch = useDispatch();
  const { dropdownData } = useSelector((state) => state.dropdown);
  const { page, hitsPerPage, isLoading } = useSelector((state) => state.domain);
  const initialValues = {
    // industrySector:
    //   dropdownData?.find(
    //     (s) =>
    //       s?.option.toLowerCase() === editDomain?.industrySector?.toLowerCase()
    //   ) || "",
    industrySector: editDomain?.industrySector,
    domainName: editDomain?.domainName || "",
  };
  // useEffect(() => {
  //   const sectorObj = dropdownData.find(
  //     (item) =>
  //       item?.id?.toLowerCase() === editDomain?.industrySector?.toLowerCase()
  //   );
  //   initialValues.industrySector = sectorObj ? sectorObj.option : "";

  //   console.log("THis is current industry sector");
  // }, [editDomain]);

  const validationSchema = Yup.object().shape({
    domainName: Yup.string()
      .required("Domain Name is required")
      .matches(
        /^(?:\*(?:\.[a-zA-Z0-9-]+)*\.)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
        "Invalid domain name format"
      ),
    industrySector: Yup.string().required("Industry Sector is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      updateDomainData({ ...values, id: editDomain.id }, () => {
        // setSubmitting(false);
        toggleeditverify();
        // dispatch(fetchDomainsData(page, hitsPerPage, filterBy, filterField));
        resetForm();
        fetchDomains();
      })
    );
  };
  return (
    <div>
      <Modal isOpen={EditVerify} toggle={toggleeditverify}>
        <ModalHeader toggle={toggleeditverify}>Update Domain</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="industrySector">Select Industry Sector</Label>
                      <Field
                        as="select"
                        id="industrySector"
                        name="industrySector"
                        className="form-control"
                        value={formik?.values?.industrySector}
                      >
                        <option value="" disabled>
                          Choose
                        </option>
                        {dropdownData.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item.option}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="industrySector"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label for="domainsName">Domain Name</Label>
                      <Field
                        type="text"
                        name="domainName"
                        id="domainName"
                        className="form-control"
                        placeholder="Enter Domain Name Here"
                      />
                      <ErrorMessage
                        name="domainName"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button onClick={toggleeditverify}>Cancel</Button>
                  <Button
                    color="primary"
                    type="submit"
                    // disabled={formik.isSubmitting}
                    disabled={isLoading}
                  >
                    {isLoading ? "Updating" : "Update"}
                    {/* {formik.isSubmitting ? "Updating" : "Update"} */}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditDomain;
