import {
  FETCH_GLOBAL_CONTENT_START,
  FETCH_GLOBAL_CONTENT_SUCCESS,
  FETCH_GLOBAL_CONTENT_ERROR,
  SUBMIT_GLOBAL_CONTENT_START,
  SUBMIT_GLOBAL_CONTENT_SUCCESS,
  SUBMIT_GLOBAL_CONTENT_ERROR,
  SET_DELETION_LOADER,
} from "../types";

const initialState = {
  offer: [],
  login: [],
  codeIssue: [],
  joinFree: [],
  yourDetails: [],
  emailVerify: [],
  otp: [],
  imageVerify: [],
  manualReview: [],
  verifiedPage: [],
  myAccountMenu: [],
  myAccountDetails: [],
  dropdowns: [],

  isLoading: false,
  isFetchFunctionLoading: false,
  error: null,

  isDeletingInfo: false,
  defaultContent: {},
};

const globalContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_GLOBAL_CONTENT_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SUBMIT_GLOBAL_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case SUBMIT_GLOBAL_CONTENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case FETCH_GLOBAL_CONTENT_START:
      return {
        ...state,
        isFetchFunctionLoading: true,
        error: null,
      };
    case FETCH_GLOBAL_CONTENT_SUCCESS:
      return {
        ...state,
        [action.payload.pageName]: action.payload.data,
        isFetchFunctionLoading: false,
        error: null,
      };
    case FETCH_GLOBAL_CONTENT_ERROR:
      return {
        ...state,
        isFetchFunctionLoading: false,
        error: action.payload,
      };
    case SET_DELETION_LOADER:
      return {
        ...state,
        isDeletingInfo: action.payload,
      };
    default:
      return state;
  }
};

export default globalContentReducer;
