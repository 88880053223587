import React, { useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";

import IndustrySector from "components/Modals/IndustrySector";
import PronounDropdown from "components/Modals/PronounDropdown";

const Dropdowns = () => {
  const [industryModal, setIndustryModal] = useState(false);
  const [isPronounDropdown, setIsPronounDropdown] = useState(false);

  const toggleIndustryModal = () => {
    setIndustryModal(!industryModal);
  };
  const togglePronounModal = () => {
    setIsPronounDropdown(!isPronounDropdown);
  };

  return (
    <div>
      <Row className="mt-3">
        <Col lg="6" xl="4" onClick={toggleIndustryModal}>
          <FormGroup>
            <Label>
              <h4>Industry Sector Dropdown</h4>
            </Label>
            <Button block type="button">
              Add
            </Button>
          </FormGroup>
        </Col>
        {industryModal && (
          <IndustrySector show={industryModal} toggle={toggleIndustryModal} />
        )}
        {isPronounDropdown && (
          <PronounDropdown
            show={isPronounDropdown}
            toggle={togglePronounModal}
          />
        )}
        <Col lg="6" xl="4" onClick={togglePronounModal}>
          <FormGroup>
            <Label>
              <h4>Pronoun Field Dropdown Options</h4>
            </Label>
            <Button block type="button">
              Add
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default Dropdowns;
