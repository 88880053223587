import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import store from "store";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
const App = () => {
  const { uid } = useSelector((state) => state.authUser);
  // const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   console.log(
  //     "App js called,App js called,App js called,App js called,App js called,App js called,App js called,App js called,App js called,App js called,App js called,App js called,App js called"
  //   );
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000);
  // }, []);

  // if (isLoading) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center p-5">
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <BrowserRouter>
      {!uid ? (
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Redirect from="/" to="/admin/index" />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default App;
