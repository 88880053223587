import React, { useEffect } from "react";
import { useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import OnlyHeader from "components/Headers/OnlyHeader";
import AddCode from "components/Modals/AddCode";
import EditCode from "components/Modals/EditCode";
import { fetchCodesData } from "store/actions/codeAction";
import { deleteCode } from "store/actions/codeAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import ReactPaginate from "react-paginate";
import { SET_CODE_PAGE } from "store/types";
import { fetchSingleOffer } from "store/actions/offerAction";
import { fetchSearchedBrands } from "store/actions/brandAction";
import { customStyles } from "assets/customStyle/reactSelectCustomStyle";
import Select from "react-select";
import { setDeleteLoader } from "store/actions/globalContentAction";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { DropdownIndicator } from "components/DropdownIndicator";

const Codes = () => {
  const { codeData, isLoading, hitsPerPage, page, totalPages, progress } =
    useSelector((state) => state.code);
  const { singleOfferList } = useSelector((state) => state.offer);
  const dispatch = useDispatch();
  const { searchedBrands } = useSelector((state) => state.brand);

  const [AddCodeOpen, setAddCodeOpen] = useState(false);
  const [editCodeOpen, setEditCodeOpen] = useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [selectedBrand, setSelectedBrand] = useState({
    label: "All",
    value: "",
  });
  const [brands, setBrands] = useState([{ label: "All", value: "" }]);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [confirmationId, setConfirmationId] = useState("");

  const confirmationToggle = () => {
    setConfirmationModal(!confirmationModal);
  };

  const addCodeToggle = (data) => {
    // setCodeDetails(data);
    setAddCodeOpen(!AddCodeOpen);
  };
  const editCodeToggle = (data) => {
    setCodeDetails(data);
    setEditCodeOpen(!editCodeOpen);
  };

  const [codeDetails, setCodeDetails] = useState();

  useEffect(() => {
    dispatch(fetchCodesData(page, hitsPerPage, selectedBrand, sortBy));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(fetchCodesData(0, hitsPerPage, selectedBrand, sortBy));
    dispatch({ type: SET_CODE_PAGE, payload: 0 });
  }, [selectedBrand, sortBy]);

  // useEffect(() => {
  //   codeData.forEach((item) => {
  //     dispatch(fetchSingleOffer(item.offer));
  //   });
  // }, [codeData]);
  const handleDeleteCode = (id) => {
    dispatch(
      deleteCode(id, () => {
        setTimeout(() => {
          dispatch(
            fetchCodesData(
              codeData.length > 1 ? page : page === 0 ? page : page - 1,
              hitsPerPage,
              selectedBrand,
              sortBy
            )
          );
        }, 5000);
      })
    );
  };
  function handlePageClick({ selected: selectedPage }) {
    dispatch({ type: SET_CODE_PAGE, payload: selectedPage });
  }
  useEffect(() => {
    let tempBrands = searchedBrands.map((brand) => ({
      label: brand.brandName,
      value: brand.objectID,
    }));
    setBrands([{ label: "All", value: "" }, ...tempBrands]);
  }, [searchedBrands]);

  return (
    <>
      <AddCode
        AddCodeOpen={AddCodeOpen}
        addCodeToggle={addCodeToggle}
        sortBy={sortBy}
        selectedBrand={selectedBrand}
        // codeDetails={codeDetails}
      />
      <EditCode
        editCodeOpen={editCodeOpen}
        editCodeToggle={editCodeToggle}
        codeDetails={codeDetails}
      />
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex flex-lg-row flex-column justify-content-between">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0">Code</h3>

                  <Button
                    className="d-lg-none d-block"
                    onClick={() => addCodeToggle(null)}
                  >
                    Add Code
                  </Button>
                </div>

                <div className="d-flex mt-lg-0 mt-2">
                  <FormGroup className="ml-lg-2 ml-0 react-autocomplete">
                    <Label for="brandName">Brand </Label>
                    <Select
                      styles={customStyles}
                      // isClearable={false}
                      components={{ DropdownIndicator }}
                      placeholder="Choose option"
                      options={brands}
                      value={selectedBrand}
                      onChange={(newValue) => {
                        setSelectedBrand(newValue);
                      }}
                      onInputChange={(searchQuery) => {
                        if (searchQuery && searchQuery != "") {
                          console.log("RUn..");
                          dispatch(
                            fetchSearchedBrands(searchQuery, page, hitsPerPage)
                          );
                        }
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="ml-2">
                    <Label>Sort By Created At</Label>
                    <Input
                      type="select"
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                    >
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </Input>
                  </FormGroup>
                </div>
                <div className="d-lg-block d-none">
                  <Button onClick={() => addCodeToggle(null)}>Add Code</Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Offer</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    codeData &&
                    codeData.map((code, index) => (
                      <tr key={index}>
                        <td>{code.id}</td>

                        <td>
                          <span className="mb-0 text-sm">
                            {code?.offerDetails?.offerTitle}
                            {/* {singleOfferList.map((item) => {
                              if (item.id === code.offer) {
                                return item.offerTitle;
                              }
                            })} */}
                          </span>
                        </td>
                        <td>
                          <span className="mb-0 text-sm">
                            {code?.brandDetails?.brandName}
                          </span>
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn btn-success"
                            onClick={() => {
                              editCodeToggle(code);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            size="sm"
                            className="btn btn-danger"
                            // onClick={() => handleDeleteCode(code.id)}
                            onClick={() => {
                              setConfirmationId(code?.id);
                              confirmationToggle();
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {isLoading && <Loader />}
              {!isLoading && (
                <CardFooter className="py-4 d-flex justify-content-end">
                  <nav>
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={totalPages}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link "}
                      nextLinkClassName={"pagination__link border "}
                      pageLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={page}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>

        <ConfirmationModal
          isOpen={confirmationModal}
          isToggle={confirmationToggle}
          id={confirmationId}
          handleDeletion={() => {
            dispatch(
              deleteCode(confirmationId, () => {
                setTimeout(() => {
                  confirmationToggle();
                  dispatch(
                    fetchCodesData(
                      codeData.length > 1 ? page : page === 0 ? page : page - 1,
                      hitsPerPage,
                      selectedBrand,
                      sortBy
                    )
                  );
                }, 5000);
              })
            );
          }}
        />
      </Container>
    </>
  );
};
export default Codes;
