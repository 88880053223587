import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  Input,
} from "reactstrap";
import avatar from "../../assets/img/brand/avatar.jpg";
import { ErrorMessage, Form, Field, Formik } from "formik";
import * as Yup from "yup";
import { fetchPronouns } from "store/actions/pronounAction";
import { useDispatch, useSelector } from "react-redux";
import { fetchDropdownData } from "store/actions/dropdownAction";
import { updateMember } from "store/actions/subAdminAction";
import { fetchMembersData } from "store/actions/subAdminAction";

const AddMemberModal = ({ editToggle, editMember, editMemberData }) => {
  const dispatch = useDispatch();
  const { pronounData } = useSelector((state) => state.pronoun);
  const { dropdownData } = useSelector((state) => state.dropdown);
  const { hitsPerPage, memberPage } = useSelector((state) => state.users);

  const initialValues = {
    image: editMemberData?.image || "",
    firstName: editMemberData?.firstName || "",
    lastName: editMemberData?.lastName || "",
    dateRegistered: editMemberData?.dateRegistered || "",
    primaryEmail: editMemberData?.primaryEmail || "",
    gender: editMemberData?.gender || "",
    secondaryEmail: editMemberData?.secondaryEmail || "",
    dateVerified: editMemberData?.dateVerified || "",
    industrySector: editMemberData?.industrySector || "",
    dateEmailOpt: editMemberData?.dateEmailOpt || "",
    emailOptIn: editMemberData?.emailOptIn || "",
    referrerSource: editMemberData?.referrerSource || "",
    verificationMethod: editMemberData?.verificationMethod || "",
    verified: editMemberData?.verified || "",
  };

  const validationSchema = Yup.object().shape({
    // image: Yup.mixed().required("ID Image is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    primaryEmail: Yup.string()
      .email("Invalid email")
      .required("Primary Email is required"),
    // gender: Yup.string().required("Gender is required"),
    // secondaryEmail: Yup.string().email("Invalid email"),
    industrySector: Yup.string().required("Industry Sector is required"),
    // referrerSource: Yup.string().required("Referrer Source is required"),
  });
  const handleSwitch = (setFieldValue, e) => {
    let value = e.target.checked;
    setFieldValue("verified", value === true ? "yes" : "no");
  };

  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      updateMember({ ...values, id: editMemberData.id }, () => {
        editToggle();
        resetForm();
      })
    );
  };

  useEffect(() => {
    dispatch(fetchPronouns());
    dispatch(fetchDropdownData());
  }, []);
  return (
    <div>
      <Modal isOpen={editMember} toggle={editToggle}>
        <ModalHeader toggle={editToggle}>Edit Member</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <Row className="align-items-center">
                  <Col lg="8" md="8">
                    <FormGroup>
                      <Label>ID Image</Label>
                      <Input
                        // className="form-control"
                        id="image"
                        type="file"
                        accept=".png, .svg, .jpg, .jpeg"
                        name="image"
                        onChange={(e) =>
                          setFieldValue("image", e.currentTarget.files[0])
                        }
                      />
                      <ErrorMessage
                        name="image"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" md="4">
                    {values.image && (
                      <div className="d-flex align-items-center">
                        {typeof values.image === "object" ? (
                          <img
                            src={
                              URL.createObjectURL(values.image) || values.image
                            }
                            alt="image"
                            id="image"
                            width={60}
                            height={60}
                            className="rounded"
                          />
                        ) : (
                          <img
                            src={values.image}
                            alt="image"
                            id="image"
                            width={60}
                            height={60}
                            className="rounded"
                          />
                        )}
                        <div
                          style={{ marginLeft: 10, cursor: "pointer" }}
                          onClick={() => {
                            setFieldValue("image", "");
                            document.getElementById("image").value = "";
                          }}
                        >
                          X
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label>First Name</Label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder="Enter Member First Name"
                        name="firstName"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder="Enter Member Last Name"
                        name="lastName"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Primary Email</Label>
                      <Field
                        className="form-control"
                        type="email"
                        name="primaryEmail"
                        readOnly
                      />
                      <ErrorMessage
                        name="primaryEmail"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  {editMemberData?.secondaryEmail && (
                    <Col lg="12" md="12">
                      <FormGroup>
                        <Label>Work Email</Label>
                        <Field
                          className="form-control"
                          readOnly
                          name="secondaryEmail"
                        />
                        <ErrorMessage
                          name="secondaryEmail"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Gender</Label>
                      <Field as="select" className="form-control" name="gender">
                        <option value="" disabled>
                          Choose
                        </option>
                        {pronounData.map((pronoun, index) => (
                          <option key={index} value={pronoun.id}>
                            {pronoun.pronounOption}
                          </option>
                        ))}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Email Opt in</Label>
                      <Field
                        className="form-control"
                        id="emailOptIn"
                        type="text"
                        name="emailOptIn"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Date Email Opt in updated</Label>
                      <Field
                        className="form-control"
                        id="exampledate"
                        // type="datetime-local"
                        disabled
                        name="endDate"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Industry Sector</Label>
                      <Field
                        as="select"
                        className="form-control"
                        id="industrySector"
                        type="text"
                        name="industrySector"
                      >
                        <option value="" disabled>
                          Choose
                        </option>
                        {dropdownData.map((dropdown, index) => (
                          <option key={index} value={dropdown.id}>
                            {dropdown.option}
                          </option>
                        ))}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Referrer Source</Label>
                      <Field
                        className="form-control"
                        id="referrerSource"
                        type="text"
                        readOnly
                        name="referrerSource"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label>Date Registered</Label>
                      <Field
                        className="form-control"
                        disabled
                        // type="datetime-local"
                        name="dateRegistered"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label>Verified</Label>
                      <CustomInput
                        type="switch"
                        id="verified"
                        name="verified"
                        onChange={(e) => handleSwitch(setFieldValue, e)}
                        checked={values.verified === "yes" ? true : false}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label>Date Verified</Label>
                      <Field
                        className="form-control"
                        disabled
                        // type="datetime-local"
                        name="dateVerified"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label for="exampleEmail">Verification Method</Label>
                      <Field
                        as="select"
                        name="verificationMethod"
                        className="form-control"
                        disabled
                      >
                        <option value="" disabled>
                          Choose
                        </option>
                        <option value="Email">Email</option>
                        <option value="Id">Id</option>
                      </Field>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-end px-3">
                  <Button onClick={editToggle}>Cancel</Button>{" "}
                  <Button color="primary" type="submit">
                    {isSubmitting ? "Updating" : "Update"}
                  </Button>{" "}
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddMemberModal;
