import React from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
import { toast } from "react-toastify";

const ImageVerify = () => {
  const dispatch = useDispatch();
  const { isLoading, imageVerify, isFetchFunctionLoading } = useSelector(
    (state) => state.globalContent
  );

  React.useEffect(() => {
    dispatch(fetchGlobalContentData("imageVerify"));
  }, [dispatch]);

  const initialValues = {
    verifyTitle: imageVerify?.verifyTitle || "",
    verifyText: imageVerify?.verifyText || "",
    acceptedFormsIDTextlink: imageVerify?.acceptedFormsIDTextlink || "",
    acceptedFormsIDLink: imageVerify?.acceptedFormsIDLink || "",
    backToEmailVerifyText: imageVerify?.backToEmailVerifyText || "",
    backToEmailVerifyButtonText: imageVerify?.backToEmailVerifyButtonText || "",
    submitButtonText: imageVerify?.submitButtonText || "",
    errorImageUploadFailed: imageVerify?.errorImageUploadFailed || "",
  };

  const validationSchema = Yup.object({
    verifyTitle: Yup.string().required("Required"),
    verifyText: Yup.string().required("Required"),
    acceptedFormsIDTextlink: Yup.string().required("Required"),
    acceptedFormsIDLink: Yup.string().required("Required"),
    backToEmailVerifyText: Yup.string().required("Required"),
    backToEmailVerifyButtonText: Yup.string().required("Required"),
    submitButtonText: Yup.string().required("Required"),
    errorImageUploadFailed: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(
      submitGlobalContentData({ pageName: "imageVerify", ...values }, () => {
        toast.success("Data Submitted Successfully");
      })
    );
  };

  return (
    <div>
      {isFetchFunctionLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <Form className="p-4">
            <Row className="mt-3">
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Verify Title</h4>
                  </Label>
                  <Field type="text" name="verifyTitle" as={Input} />
                  <ErrorMessage
                    name="verifyTitle"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Verify Text</h4>
                  </Label>
                  <Field type="textarea" name="verifyText" as={Input} />
                  <ErrorMessage
                    name="verifyText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Accepted Forms of ID Textlink</h4>
                  </Label>
                  <Field
                    type="text"
                    name="acceptedFormsIDTextlink"
                    as={Input}
                  />
                  <ErrorMessage
                    name="acceptedFormsIDTextlink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Accepted Forms of ID Link (URL)</h4>
                  </Label>
                  <Field type="text" name="acceptedFormsIDLink" as={Input} />
                  <ErrorMessage
                    name="acceptedFormsIDLink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Back to Email Verify Text</h4>
                  </Label>
                  <Field type="text" name="backToEmailVerifyText" as={Input} />
                  <ErrorMessage
                    name="backToEmailVerifyText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Back to Email Verify Button Text</h4>
                  </Label>
                  <Field
                    type="text"
                    name="backToEmailVerifyButtonText"
                    as={Input}
                  />
                  <ErrorMessage
                    name="backToEmailVerifyButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Submit Button Text</h4>
                  </Label>
                  <Field type="text" name="submitButtonText" as={Input} />
                  <ErrorMessage
                    name="submitButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Image Upload Failed</h4>
                  </Label>
                  <Field
                    type="textarea"
                    name="errorImageUploadFailed"
                    as={Input}
                  />
                  <ErrorMessage
                    name="errorImageUploadFailed"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-4">
              <Button
                type="submit"
                disabled={isLoading}
                className="btn btn-success"
              >
                {isLoading ? "Submitting" : "Submit"}
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default ImageVerify;
