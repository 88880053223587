import axios from "axios";
const baseURL = "https://us-central1-kii-admin.cloudfunctions.net/app/api/v1";

export default axios.create({
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    // Authorization:
    //   "Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=",
  },
});
