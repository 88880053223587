import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Button,
  Tooltip,
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import OnlyHeader from "components/Headers/OnlyHeader";
import AddOffers from "components/Modals/AddOffers";
import { useDispatch, useSelector } from "react-redux";
import { fetchOffers } from "store/actions/offerAction";
import { deleteOffer } from "store/actions/offerAction";
import Loader from "components/Loader";
import ReactPaginate from "react-paginate";
import { SET_OFFER_PAGE } from "store/types";
import { fetchSingleBrand } from "store/actions/brandAction";
import EditOffers from "components/Modals/EditOffers";
import { DropdownIndicator } from "components/DropdownIndicator";

import { customStyles } from "assets/customStyle/reactSelectCustomStyle";
import Select, { components } from "react-select";
import { fetchSearchedBrands } from "store/actions/brandAction";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { toast } from "react-toastify";

const OffersPage = () => {
  const dispatch = useDispatch();
  const [AddOffer, setAddOffer] = useState(false);
  const [editOffer, setEditOffer] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({});

  const [brands, setBrands] = useState([{ label: "All", value: "" }]);

  const { searchedBrands } = useSelector((state) => state.brand);
  const [sortBy, setSortBy] = useState("desc");
  const [selectedBrand, setSelectedBrand] = useState({
    label: "All",
    value: "",
  });

  const toggle = (offerId) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [offerId]: !prevState[offerId],
    }));
  };
  const { offers, isLoading, hitsPerPage, page, totalPages } = useSelector(
    (state) => state.offer
  );
  const { singleBrandList } = useSelector((state) => state.brand);

  const toggleAddOffer = () => {
    setAddOffer(!AddOffer);
  };
  const toggleEditOffer = (data) => {
    setEditOffer(!editOffer);
    setOfferDetails(data);
  };

  const [offerDetails, setOfferDetails] = useState();
  useEffect(() => {
    dispatch(fetchOffers(page, hitsPerPage, selectedBrand, sortBy));
    setTooltipOpen(false);
  }, [dispatch, page, selectedBrand, sortBy]);

  useEffect(() => {
    dispatch(fetchOffers(page, hitsPerPage, selectedBrand, sortBy));
    setTooltipOpen(false);
    dispatch({ type: SET_OFFER_PAGE, payload: 0 });
  }, [selectedBrand, sortBy]);

  useEffect(() => {
    offers.forEach((item) => {
      dispatch(fetchSingleBrand(item.brandName));
    });
  }, [offers]);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [confirmationId, setConfirmationId] = useState("");

  const confirmationToggle = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteOffer = (id) => {
    dispatch(
      deleteOffer(id, () => {
        setTimeout(() => {
          dispatch(
            fetchOffers(
              offers.length > 1 ? page : page === 0 ? page : page - 1,
              hitsPerPage,
              selectedBrand,
              sortBy
            )
          );
        }, 5000);
      })
    );
  };
  function handlePageClick({ selected: selectedPage }) {
    dispatch({ type: SET_OFFER_PAGE, payload: selectedPage });
  }

  useEffect(() => {
    if (isLoading) {
      setTooltipOpen(false);
    }
  }, [isLoading]);
  const [copied, setCopied] = useState("");
  const [optinCopied, setOptinCopied] = useState("");
  const [embedScriptCopied, setEmbedScriptCopied] = useState("");

  const copyToClipBoard = (text, idx) => {
    navigator.clipboard.writeText(text);
    setCopied(idx);
    setTimeout(() => {
      setCopied("");
    }, 3000);
  };

  const copyOptinToClipBoard = (text, idx) => {
    navigator.clipboard.writeText(text);
    setOptinCopied(idx);
    setTimeout(() => {
      setOptinCopied("");
    }, 3000);
    toast.success("Optin url copied!");
  };

  const copyEmbedScriptToClipBoard = (text, idx) => {
    navigator.clipboard.writeText(text);
    setEmbedScriptCopied(idx);
    setTimeout(() => {
      setEmbedScriptCopied("");
    }, 3000);
    toast.success("Embed script copied!");
  };

  useEffect(() => {
    let tempBrands = searchedBrands.map((brand) => ({
      label: brand.brandName,
      value: brand.objectID,
    }));
    setBrands([{ label: "All", value: "" }, ...tempBrands]);
  }, [searchedBrands]);

  const truncateString = (inputString) => {
    if (inputString.length <= 10) {
      return inputString;
    } else {
      return inputString.substring(0, 10) + "...";
    }
  };

  return (
    <>
      <AddOffers
        toggleAddOffer={toggleAddOffer}
        AddOffer={AddOffer}
        selectedBrand={selectedBrand}
        sortBy={sortBy}
      />
      <EditOffers
        toggleEditOffer={toggleEditOffer}
        editOffer={editOffer}
        offerDetails={offerDetails}
      />

      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex flex-lg-row flex-column justify-content-between">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0 ">Offers</h3>

                  <Button
                    className="d-lg-none d-block"
                    onClick={() => toggleAddOffer(null)}
                  >
                    Add Offers
                  </Button>
                </div>
                {/* <h3 className="mb-0 ">Offers</h3> */}
                <div className="d-flex mt-lg-0 mt-2">
                  <FormGroup className="ml-lg-3 ml-0 react-autocomplete ">
                    <Label for="brandName">Brand </Label>
                    <Select
                      styles={customStyles}
                      // isClearable={false}
                      components={{ DropdownIndicator }}
                      placeholder="Choose option"
                      options={brands}
                      value={selectedBrand}
                      onChange={(newValue) => {
                        setSelectedBrand(newValue);
                      }}
                      onInputChange={(searchQuery) => {
                        if (searchQuery && searchQuery != "") {
                          dispatch(
                            fetchSearchedBrands(searchQuery, page, hitsPerPage)
                          );
                        }
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="ml-2">
                    <Label>Sort By End Date</Label>
                    <Input
                      type="select"
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                    >
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </Input>
                  </FormGroup>
                </div>

                <div className="d-lg-block d-none">
                  <Button onClick={() => toggleAddOffer(null)}>
                    Add Offers
                  </Button>
                </div>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Title</th>
                    {/* <th scope="col">Description</th> */}
                    <th scope="col">Brand Name</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    offers.length > 0 &&
                    offers.map((offer, index) => (
                      <tr key={offer.id}>
                        <td>{offer.id}</td>
                        <th scope="row" id={`offertitle_${index}`}>
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {/* {offer.offerTitle} */}
                                {truncateString(offer?.offerTitle)}{" "}
                              </span>
                              <UncontrolledTooltip
                                placement="bottom"
                                target={`#offertitle_${index}`}
                                autohide={false}
                              >
                                {offer?.offerTitle}
                              </UncontrolledTooltip>
                            </Media>
                          </Media>
                        </th>
                        {/* <td id={`suggestion_${index}`}>
                          {offer.offerDescription.length > 28
                            ? `${offer.offerDescription.substring(0, 27)}...`
                            : offer.offerDescription}
                        </td>
                        <UncontrolledTooltip
                          placement="bottom"
                          target={`#suggestion_${index}`}
                          autohide={false}
                        >
                          {offer?.offerDescription}
                        </UncontrolledTooltip> */}

                        <td>
                          {singleBrandList.map((item) => {
                            if (item.id === offer.brandName) {
                              return item.brandName;
                            }
                          })}
                        </td>
                        <td>{offer.endDate}</td>

                        <td>
                          <Button
                            size="sm"
                            className={`${
                              copied == offer?.id
                                ? "text-white bg-primary"
                                : "btn btn-secondary"
                            }  `}
                            onClick={() => {
                              let brand = singleBrandList.find(
                                (item) => item.id === offer.brandName
                              );
                              let url = `https://secure.wearekii.co.uk/${brand?.slug}/offerID=${offer?.id}`;
                              copyToClipBoard(url, offer?.id);
                            }}
                            disabled={copied == offer?.id}
                          >
                            {copied == offer?.id ? (
                              "Copied"
                            ) : (
                              <i className="fa fa-copy"></i>
                            )}
                          </Button>
                          <Button
                            size="sm"
                            className={`${
                              embedScriptCopied === offer?.id
                                ? "text-white bg-primary"
                                : "btn btn-secondary"
                            }  `}
                            onClick={() => {
                              let brand = singleBrandList.find(
                                (item) => item.id === offer.brandName
                              );
                              let script = `<script
                                src="https://bit.ly/4dOS1iR"
                                data-location="/${brand?.slug}/offerID=${offer?.id}"
                              ></script>`;
                              copyEmbedScriptToClipBoard(script, offer?.id);
                            }}
                            disabled={embedScriptCopied === offer?.id}
                          >
                            {embedScriptCopied === offer?.id
                              ? "Copied"
                              : "Embed Script"}
                          </Button>
                          <Button
                            size="sm"
                            className={`${
                              optinCopied === offer?.id
                                ? "text-white bg-primary"
                                : "btn btn-secondary"
                            }  `}
                            onClick={() => {
                              let brand = singleBrandList.find(
                                (item) => item.id === offer.brandName
                              );
                              let url = `https://us-central1-kii-admin.cloudfunctions.net/app/optin-logs/${brand?.id}/${offer?.id}`;
                              copyOptinToClipBoard(url, offer?.id);
                            }}
                            disabled={optinCopied === offer?.id}
                          >
                            {optinCopied === offer?.id
                              ? "Copied"
                              : "Copy Optin"}
                          </Button>

                          <Button
                            size="sm"
                            className="btn bg-primary text-white"
                            onClick={() => {
                              let brand = singleBrandList.find(
                                (item) => item.id === offer.brandName
                              );
                              let url = `https://secure.wearekii.co.uk/${brand?.slug}/offerID=${offer?.id}`;

                              window.open(url, "_blank");
                            }}
                          >
                            Preview
                          </Button>
                          <Button
                            size="sm"
                            className="btn btn-success"
                            onClick={() => {
                              toggleEditOffer(offer);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            size="sm"
                            className="btn btn-danger"
                            // onClick={() => handleDeleteOffer(offer.id)}
                            onClick={() => {
                              setConfirmationId(offer?.id);
                              confirmationToggle();
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {isLoading && <Loader />}
              {!isLoading && (
                <CardFooter className="py-4 d-flex justify-content-end">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={totalPages}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link "}
                      nextLinkClassName={"pagination__link border "}
                      pageLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={page}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
        <ConfirmationModal
          isOpen={confirmationModal}
          isToggle={confirmationToggle}
          id={confirmationId}
          handleDeletion={() => {
            dispatch(
              deleteOffer(confirmationId, () => {
                setTimeout(() => {
                  confirmationToggle();
                  dispatch(
                    fetchOffers(
                      offers.length > 1 ? page : page === 0 ? page : page - 1,
                      hitsPerPage,
                      selectedBrand,
                      sortBy
                    )
                  );
                }, 5000);
              })
            );
          }}
        />
      </Container>
    </>
  );
};

export default OffersPage;
