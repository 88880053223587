import firebase from "../../config/firebase";
import { ADD_SUBADMIN_ERROR } from "store/types";
import { ADD_SUBADMIN_SUCCESS } from "store/types";
import { ADD_SUBADMIN_START } from "store/types";
import { RepositoryFactory } from "repository/RepositoryFactory";
import { toast } from "react-toastify";
import { FETCH_SUBADMINS_START } from "store/types";
import { FETCH_SUBADMINS_SUCCESS } from "store/types";
import { FETCH_SUBADMINS_ERROR } from "store/types";
import { UPDATE_SUBADMIN_START } from "store/types";
import { UPDATE_SUBADMIN_SUCCESS } from "store/types";
import { UPDATE_SUBADMIN_ERROR } from "store/types";
import { DELETE_SUBADMIN_START } from "store/types";
import { DELETE_SUBADMIN_SUCCESS } from "store/types";
import { DELETE_SUBADMIN_ERROR } from "store/types";
import algoliasearch from "algoliasearch";
import { createNullCache } from "@algolia/cache-common";
import { SET_TOTAL_PAGES } from "store/types";
import { FETCH_MEMBERS_START } from "store/types";
import { FETCH_MEMBERS_SUCCESS } from "store/types";
import { FETCH_MEMBERS_ERROR } from "store/types";
import { SET_TOTAL_MEMBER_PAGES } from "store/types";
import { DELETE_MEMBER_SUCCESS } from "store/types";
import { ADD_SUBADMIN_PAYLOAD } from "store/types";
import { UPDATE_SUBADMIN_PAYLOAD } from "store/types";
import { UPDATE_MEMBER_PAYLOAD } from "store/types";
import { v4 as uuidv4 } from "uuid";
import { FETCH_SINGLE_USER_START } from "store/types";
import { FETCH_SINGLE_USER_SUCCESS } from "store/types";
import { FETCH_SINGLE_USER_ERROR } from "store/types";
import { setDeleteLoader } from "./globalContentAction";

// const client = algoliasearch("838S836EIV", "814d068b753072eda3a7892d9c4ceee1", {
//   responsesCache: createNullCache(),
// });

const client = algoliasearch("838S836EIV", "257be4e2ad2324c1eab0ba1f9758fce5", {
  responsesCache: createNullCache(),
});
// const client = algoliasearch(
//   "838S836EIV",
//   process.env.REACT_APP_ALGOLIA_SECRET,
//   {
//     responsesCache: createNullCache(),
//   }
// );
let User = RepositoryFactory.get("subAdmin");
export const brandsIndex = client.initIndex("brands");
export const offerIndex = client.initIndex("offers");
export const codesIndex = client.initIndex("codes");
export const domainIndex = client.initIndex("domains");
const memberIndex = client.initIndex("members");
const usersIndex = client.initIndex("users");
// ============CREATE==============
export const createSubAdmin =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      let currentUser = firebase.auth()?.currentUser;
      dispatch({ type: ADD_SUBADMIN_START });
      const response = await User.createUser({
        ...data,
        userType: "sub-admin",
        createdById: currentUser?.uid,
      });
      if (response) {
        console.log(response);
        setTimeout(() => {
          toast.success("SubAdmin Created Successfully");
          dispatch({ type: ADD_SUBADMIN_SUCCESS, response });
          dispatch({
            type: ADD_SUBADMIN_PAYLOAD,
            payload: {
              ...response.data.user,
              id: response.data.user.userId,
            },
          });
        }, 5000);
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: ADD_SUBADMIN_ERROR, error });
      toast.error(error?.response?.data?.error || "There was an error");
    }
  };

// =========READ============
export const fetchSubAdminsData = (page, hitsPerPage) => async (dispatch) => {
  dispatch({ type: FETCH_SUBADMINS_START });
  const searchParams = {
    page,
    hitsPerPage,
    filters: "userType:sub-admin",
  };
  usersIndex
    .search("", searchParams)
    .then((response) => {
      console.log(response);
      const subAdmins = [];
      response.hits.forEach((doc) => {
        subAdmins.push({ id: doc.objectID, ...doc });
      });
      dispatch({ type: FETCH_SUBADMINS_SUCCESS, payload: subAdmins });
      dispatch({ type: SET_TOTAL_PAGES, payload: response.nbPages });
    })
    .catch((error) =>
      dispatch({ type: FETCH_SUBADMINS_ERROR, payload: error })
    );
};
export const fetchMembersData =
  (page, hitsPerPage, searchQuery) => async (dispatch) => {
    dispatch({ type: FETCH_MEMBERS_START });
    const filters = searchQuery ? `${searchQuery}` : "";

    memberIndex
      .search(filters, {
        page,
        hitsPerPage,
      })
      .then((response) => {
        const members = [];
        response.hits.forEach((doc) => {
          members.push({ id: doc.objectID, ...doc });
        });
        dispatch({ type: FETCH_MEMBERS_SUCCESS, payload: members });
        dispatch({ type: SET_TOTAL_MEMBER_PAGES, payload: response.nbPages });
      })
      .catch((error) =>
        dispatch({ type: FETCH_MEMBERS_ERROR, payload: error })
      );
  };

// ==========GET SINGLE BRAND=========
export const fetchSingleUser = (id, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SINGLE_USER_START });
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const user = { id: doc.id, ...doc.data() };
          dispatch({ type: FETCH_SINGLE_USER_SUCCESS, payload: user });
          onSuccess(user);
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_SINGLE_USER_ERROR, payload: error });
      });
  };
};
// =============UPDATE=============
export const updateSubAdmin =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SUBADMIN_START });
      const response = await User.updateUser(data);
      if (response) {
        toast.success("SubAdmin Updated Successfully");
        dispatch({ type: UPDATE_SUBADMIN_SUCCESS, response });
        dispatch({
          type: UPDATE_SUBADMIN_PAYLOAD,
          payload: { ...data, id: data.userId },
        });
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: UPDATE_SUBADMIN_ERROR, error });
      toast.error(error?.response?.data?.error || "There was an error");
    }
  };

export const updateMember =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SUBADMIN_START });
      let details = await firebase
        .firestore()
        .collection("users")
        .doc(data.id)
        .get();
      let { image } = details.data();
      if (typeof data.image === "object") {
        if (image) {
          await firebase.storage().refFromURL(image).delete();
        }
        const imageFile = data.image;
        const fileName = data.image.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("member images/" + fileNameWithExtension);
        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
        data.image = downloadURL;
      }

      const response = firebase
        .firestore()
        .collection("users")
        .doc(data.id)
        .update({
          ...data,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      if (response) {
        toast.success("Member Updated Successfully");
        dispatch({ type: UPDATE_SUBADMIN_SUCCESS, response });
        dispatch({
          type: UPDATE_MEMBER_PAYLOAD,
          payload: data,
        });
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: UPDATE_SUBADMIN_ERROR, error });
      toast.error(error?.response?.data?.error || "There was an error");
    }
  };

// =============DELETE==============
export const deleteSubAdmin =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      // dispatch({ type: DELETE_SUBADMIN_START });
      dispatch(setDeleteLoader(true));
      const response = await User.deleteUser(data);
      if (response) {
        onSuccess();
      }
    } catch (error) {
      // dispatch({ type: DELETE_SUBADMIN_ERROR, error });
      dispatch(setDeleteLoader(false));
      toast.error(error?.response?.data?.error || "There was an error");
    }
  };
export const deleteMember =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(setDeleteLoader(true));
      // dispatch({ type: DELETE_SUBADMIN_START });
      const response = await User.deleteUser(data);
      if (response) {
        setTimeout(() => {
          toast.success("Member Deleted Successfully");
          // dispatch({ type: DELETE_MEMBER_SUCCESS, payload: data.userId });
          dispatch(setDeleteLoader(false));
        }, 5000);
        onSuccess();
      }
    } catch (error) {
      // dispatch({ type: DELETE_SUBADMIN_ERROR, error });
      dispatch(setDeleteLoader(false));

      toast.error(error?.response?.data?.error || "There was an error");
    }
  };

export const fetchAllMembersData =
  (onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    memberIndex
      .search("", { hitsPerPage: 50000 })
      .then((response) => {
        const members = [];
        response.hits.forEach((doc) => {
          members.push({ id: doc.objectID, ...doc });
        });
        onSuccess(members);
      })
      .catch((error) => {
        alert(error?.message || "");
        onError();
      });
  };
