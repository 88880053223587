import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { FETCH_DOMAINS_START } from "store/types";
import { FETCH_DOMAINS_ERROR } from "store/types";
import { UPDATE_DOMAIN_SUCCESS } from "store/types";
import { DELETE_DOMAIN_START } from "store/types";
import { DELETE_DOMAIN_ERROR } from "store/types";
import { DELETE_DOMAIN_SUCCESS } from "store/types";
import { UPDATE_DOMAIN_ERROR } from "store/types";
import { UPDATE_DOMAIN_START } from "store/types";
import { FETCH_DOMAINS_SUCCESS } from "store/types";
import { SUBMIT_DOMAIN_ERROR } from "store/types";
import { SUBMIT_DOMAIN_SUCCESS } from "store/types";
import { SUBMIT_DOMAIN_START } from "store/types";
import { domainIndex } from "./subAdminAction";
import { SET_DOMAIN_TOTAL_PAGES } from "store/types";

// ============CREATE============

import * as XLSX from "xlsx";
import { ADD_DOMAIN_PAYLOAD } from "store/types";
import { UPDATE_DOMAIN_PAYLOAD } from "store/types";
import { setDeleteLoader } from "./globalContentAction";

export const submitDomainData =
  (data, onSuccess = () => {}) =>
  async (dispatch, getState) => {
    dispatch({ type: SUBMIT_DOMAIN_START });

    const { dropdownData } = getState().dropdown;
    const findSectorId = (sectorName) => {
      if (sectorName) {
        const sectorObj = dropdownData.find(
          (s) => s.option.toLowerCase() === sectorName.toLowerCase()
        );
        return sectorObj ? sectorObj.id : null;
      }
    };

    const isSpecialDomain = (domain) => {
      const regex = /^\*\..*/; //* before the first .
      return regex.test(domain);
    };

    const saveDomain = async (domain, sectorId) => {
      const domainType = isSpecialDomain(domain) ? "special" : "default";
      const response = await firebase.firestore().collection("domains").add({
        domainName: domain,
        industrySector: sectorId,
        domainType: domainType,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      dispatch({
        type: ADD_DOMAIN_PAYLOAD,
        payload: {
          domainName: domain,
          industrySector: sectorId,
          domainType: domainType,
          id: response.id,
          objectID: response.id,
        },
      });

      return response;
    };

    if (data.activeTab === "domainName") {
      const sectorId = findSectorId(data.industrySector);
      if (sectorId) {
        saveDomain(data.domainName, sectorId)
          .then((response) => {
            setTimeout(() => {
              dispatch({ type: SUBMIT_DOMAIN_SUCCESS });
              toast.success("Domain Name Added Successfully");
            }, 5000);
            onSuccess();
          })
          .catch((error) => {
            dispatch({ type: SUBMIT_DOMAIN_ERROR, payload: error });
          });
      } else {
        dispatch({
          type: SUBMIT_DOMAIN_ERROR,
          payload: "Sector not found for domain",
        });
        toast.error("Sector not found for domain");
      }
    } else if (data.activeTab === "multipleDomains") {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const data = new Uint8Array(arrayBuffer);

        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        const domainArray = sheetData
          .map((row) => row)
          .filter((value) => value.sector !== "");
        if (domainArray.length > 10000) {
          toast.error(
            "Access denied. Maximum capacity reached. Only 10000 records can be added at this time."
          );
          dispatch({
            type: SUBMIT_DOMAIN_ERROR,
            payload:
              "Access denied. Maximum capacity reached. Only 20 records can be added at this time.",
          });
          return;
        }
        const domainPattern =
          /^(?:\*(?:\.[a-zA-Z0-9-]+)*\.)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

        const saveDomainPromises = domainArray.map((domain) => {
          const sectorId = findSectorId(domain.sector || domain.Sector);
          const domainValue = domain.domain || domain.Domain;
          if (sectorId && domainPattern.test(domainValue)) {
            return saveDomain(domainValue, sectorId);
          } else {
            return Promise.resolve(); // Ignore the invalid sector or domain
          }
        });

        Promise.all(saveDomainPromises)
          .then((response) => {
            console.log(response);
            if (response.every((result) => result === undefined)) {
              toast.error("Industry Sector not found for any domain");
              dispatch({
                type: SUBMIT_DOMAIN_ERROR,
                payload: "No domains were added successfully",
              });
            } else {
              setTimeout(() => {
                dispatch({ type: SUBMIT_DOMAIN_SUCCESS });
                toast.success("Multiple Domains Added Successfully");
              }, 5000);
              onSuccess();
            }
          })
          .catch((error) => {
            dispatch({ type: SUBMIT_DOMAIN_ERROR, payload: error });
          });
      };

      fileReader.readAsArrayBuffer(data.domainFile);
    }
  };

// =========GET==========
export const fetchDomainsData = (page, hitsPerPage, filterBy, filterField) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DOMAINS_START });
    console.log("Fetching..", page, hitsPerPage, filterBy, filterField);
    const searchQuery =
      filterBy == "domainName" && filterField ? `${filterField}` : "";
    console.log("Search Query", searchQuery);
    let obj = {
      page,
      hitsPerPage,
    };
    if (
      filterBy != "domainName" ||
      (filterBy == "domainName" && filterField == "")
    ) {
      obj = {
        ...obj,
        filters:
          filterBy && filterBy != "all" && filterBy != "domainName"
            ? `${filterField}:${filterBy}`
            : "",
      };
    }
    console.log("Obj", obj);
    domainIndex
      .search(searchQuery, obj)
      .then((querySnapshot) => {
        console.log("Query snaps..", querySnapshot);
        const domains = [];
        querySnapshot.hits.forEach((doc) => {
          domains.push({ id: doc.objectID, ...doc });
        });
        dispatch({ type: FETCH_DOMAINS_SUCCESS, payload: domains });
        dispatch({
          type: SET_DOMAIN_TOTAL_PAGES,
          payload: querySnapshot.nbPages,
          // payload: Math.ceil(querySnapshot.nbHits / querySnapshot.hitsPerPage),
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_DOMAINS_ERROR, payload: error });
      });
  };
};

// ==========UPDATE==============
export const updateDomainData =
  (data, onSuccess = () => {}) =>
  async (dispatch, getState) => {
    dispatch({ type: UPDATE_DOMAIN_START });

    const isSpecialDomain = (domain) => {
      const regex = /^\*\..*/; // Regex pattern to match * before the first .
      return regex.test(domain);
    };

    const saveDomain = async (domain) => {
      const domainType = isSpecialDomain(domain) ? "special" : "default";
      const { id, domainName, industrySector } = data;
      console.log(data);
      const { dropdownData } = getState().dropdown;
      // const findSectorId = (sectorName) => {
      //   if (sectorName) {
      //     const sectorObj = dropdownData.find(
      //       (s) => s.option.toLowerCase() === sectorName.toLowerCase()
      //     );
      //     return sectorObj ? sectorObj.id : null;
      //   }
      // };
      // const sectorId = findSectorId(data.industrySector);
      const response = await firebase
        .firestore()
        .collection("domains")
        .doc(id)
        .update({
          domainName,
          industrySector,
          domainType,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      // dispatch({
      //   type: UPDATE_DOMAIN_PAYLOAD,
      //   payload: { ...data, objectID: data.id, industrySector: sectorId },
      // });
      return response;
    };
    saveDomain(data.domainName)
      .then(() => {
        console.log("Is going to updated");
        // dispatch({ type: UPDATE_DOMAIN_SUCCESS });
        setTimeout(() => {
          toast.success("Domain Name Updated Successfully");

          onSuccess();
        }, 5000);
      })
      .catch((error) => {
        dispatch({ type: UPDATE_DOMAIN_ERROR, payload: error });
      });
  };

// ==========DELETE=========
export const deleteDomain = (id, industrySector, onSuccess = () => {}) => {
  return async (dispatch) => {
    // dispatch({ type: DELETE_DOMAIN_START });
    dispatch(setDeleteLoader(true));

    firebase
      .firestore()
      .collection("domains")
      .doc(id)
      .delete()
      .then(() => {
        setTimeout(() => {
          // dispatch({ type: DELETE_DOMAIN_SUCCESS, payload: id });
          dispatch(setDeleteLoader(false));
          toast.success("Domain Deleted Successfully");
        }, 5000);
        onSuccess();
      })
      .catch((error) => {
        // dispatch({ type: DELETE_DOMAIN_ERROR, payload: error });
        dispatch(setDeleteLoader(false));
        toast.error(error.message);
      });
    // let domainAlreadyUsed = await firebase
    //   .firestore()
    //   .collection("users")
    //   .where("industrySector", "==", industrySector)
    //   .where("verificationMethod", "==", "email")
    //   .where("verified", "==", "yes")
    //   .get();

    // console.log("industry sector", industrySector);

    // if (domainAlreadyUsed?.size > 0) {
    //   console.log("industry sector", "DD", domainAlreadyUsed?.docs[0].data());

    //   toast.warning(
    //     "this domain industry sector is associated with user so you can't delete it"
    //   );
    //   dispatch(setDeleteLoader(false));
    // } else {
    //   firebase
    //     .firestore()
    //     .collection("domains")
    //     .doc(id)
    //     .delete()
    //     .then(() => {
    //       setTimeout(() => {
    //         // dispatch({ type: DELETE_DOMAIN_SUCCESS, payload: id });
    //         dispatch(setDeleteLoader(false));
    //         toast.success("Domain Deleted Successfully");
    //       }, 5000);
    //       onSuccess();
    //     })
    //     .catch((error) => {
    //       dispatch(setDeleteLoader(false));
    //       toast.error(error.message);
    //     });
    // }
  };
};
