import {
  FETCH_STATS_START,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_ERROR,
} from "store/types";

const initialState = {
  stats: [],
  isLoading: false,
  error: null,
  codesCount: 0,
  usersCount: 0,
  registrationCount: 0,
  isGettingCounts: false,
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stats: action.payload,
      };
    case FETCH_STATS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case "SET_GET_STATS_COUNT_LOADER":
      return {
        ...state,
        isGettingCounts: action.payload,
      };
    case "SET_CODES_COUNT":
      return {
        ...state,
        codesCount: action.payload,
      };
    case "SET_USERS_COUNT":
      return {
        ...state,
        usersCount: action.payload,
      };
    case "SET_REGISTRATION_COUNT":
      return {
        ...state,
        registrationCount: action.payload,
      };

    default:
      return state;
  }
};

export default statsReducer;
