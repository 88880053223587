// Root Reducer

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import subAdminReducer from "./subAdminReducer";
import authUserReducer from "./authUser";
import offerReducer from "./offerReducer";
import codeReducer from "./codeReducer";
import globalContentReducer from "./globalContentReducer";
import brandReducer from "./brandReducer";
import statsReducer from "./statsReducer";
import domainReducer from "./domainReducer";
import dropdownReducer from "./dropdownReducer";
import pronounReducer from "./pronounReducer";
import imageVerificationReducer from "./imageVerificationReducer";

export let rootReducer = combineReducers({
  auth: authReducer,
  authUser: authUserReducer,
  stats: statsReducer,
  users: subAdminReducer,
  brand: brandReducer,
  offer: offerReducer,
  code: codeReducer,
  domain: domainReducer,
  dropdown: dropdownReducer,
  pronoun: pronounReducer,
  globalContent: globalContentReducer,
  imageVerification: imageVerificationReducer,
});

export default rootReducer;
