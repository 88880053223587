import OnlyHeader from "components/Headers/OnlyHeader";
import Loader from "components/Loader";
import AddImage from "components/Modals/AddImage";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import PreviewImage from "components/Modals/PreviewImage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Card,
  CardHeader,
  Table,
  Media,
  Button,
  CardFooter,
  Input,
} from "reactstrap";
import { deleteImageVerification } from "store/actions/ImageVerificationAction";
import { fetchMoreImageVerification } from "store/actions/ImageVerificationAction";
import { fetchImageVerification } from "store/actions/ImageVerificationAction";
import { fetchImageVerificationData } from "store/actions/ImageVerificationAction";
import { fetchDropdownData } from "store/actions/dropdownAction";

const ImageVerification = () => {
  const dispatch = useDispatch();
  const [verify, setVerify] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const {
    imageVerificationData,
    lastVisible,
    totalCount,
    isLoading,
    isGettingMore,
    isGettingData,
  } = useSelector((state) => state.imageVerification);
  const toggleVerify = () => {
    setVerify(!verify);
  };
  const toggleImagePreview = () => {
    setShowImagePreview(!showImagePreview);
  };

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [confirmationId, setConfirmationId] = useState("");

  const confirmationToggle = () => {
    setConfirmationModal(!confirmationModal);
  };

  useEffect(() => {
    // dispatch(fetchImageVerificationData());
    if (imageVerificationData.length == 0) {
      dispatch(fetchImageVerification());
    }
    dispatch(fetchDropdownData());
  }, [dispatch]);
  const handleLoadMore = () => {
    // if (lastVisible) {
    //   dispatch(fetchImageVerificationData(lastVisible));
    // }

    dispatch(
      fetchMoreImageVerification(
        imageVerificationData?.[imageVerificationData?.length - 1]
      )
    );
  };

  const [search, setSearch] = useState("");

  const getFilteredData = () => {
    if (imageVerificationData?.length > 0) {
      if (search != "") {
        let obj = imageVerificationData?.filter(
          (data) => data?.memberID.toLowerCase() == search.toLowerCase()
        );
        return obj;
      } else {
        return imageVerificationData;
      }
    } else {
      return [];
    }
  };

  return (
    <>
      <OnlyHeader />
      <AddImage verify={verify} toggleVerify={toggleVerify} />
      <PreviewImage
        item={previewImage}
        verify={showImagePreview}
        toggleVerify={toggleImagePreview}
      />
      {/* <EditDomain
        EditVerify={EditVerify}
        toggleeditverify={toggleeditverify}
        editDomain={editDomain}
        seteditDomain={seteditDomain}
      /> */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-start">
                <h3 className="mb-0">Image Verification</h3>
                <div className="d-flex ml-4">
                  {" "}
                  <Input
                    type="text"
                    value={search}
                    placeholder="Search by member id"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>

                {/* <div>
                  <Button onClick={toggleVerify}>Add Image</Button>
                </div> */}
              </CardHeader>
              <Table className=" table-flush  position-relative" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Avatar</th>
                    <th scope="col">Member ID</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!isGettingData &&
                    getFilteredData().map((item, index) => (
                      <tr key={index}>
                        <td scope="row">
                          <Media>
                            <Media>
                              <span className="mb-0 text-sm">{item.id}</span>
                            </Media>
                          </Media>
                        </td>
                        <td scope="row">
                          <Media>
                            <Media
                              onClick={() => {
                                setPreviewImage(item);
                                setShowImagePreview(true);
                              }}
                            >
                              <img
                                className={`rounded-circle`}
                                src={item.fileUrl}
                                alt="image"
                                width={42}
                                height={42}
                              />
                            </Media>
                          </Media>
                        </td>
                        <td scope="row">
                          <Media>
                            <Media>
                              <span className="mb-0 text-sm">
                                {item.memberID}
                              </span>
                            </Media>
                          </Media>
                        </td>
                        <td scope="row">
                          <Button
                            className="mb-0 text-sm"
                            onClick={() => {
                              setPreviewImage(item);
                              setShowImagePreview(true);
                            }}
                            size="sm"
                          >
                            View
                          </Button>
                          <Button
                            size="sm"
                            className="btn btn-danger"
                            onClick={() => {
                              setConfirmationId(item?.id);
                              confirmationToggle();
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {isGettingData && <Loader />}
              <CardFooter className="py-4 d-flex justify-content-end">
                {imageVerificationData?.length >= 10 && search == "" && (
                  <Button
                    outline
                    onClick={handleLoadMore}
                    disabled={isGettingMore}
                  >
                    Load more
                  </Button>
                )}
                {/* <nav>
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
        <ConfirmationModal
          isOpen={confirmationModal}
          isToggle={confirmationToggle}
          id={confirmationId}
          handleDeletion={() => {
            dispatch(
              deleteImageVerification(confirmationId, () => {
                confirmationToggle();
              })
            );
          }}
        />
      </Container>
    </>
  );
};

export default ImageVerification;
