import React, { useEffect } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const { isLoading, isFetchFunctionLoading, login } = useSelector(
    (state) => state.globalContent
  );
  const initialValues = {
    loginText: login?.loginText || "",
    recaptchaText: login?.recaptchaText || "",
    joinNowText: login?.joinNowText || "",
    joinNowButtonText: login?.joinNowButtonText || "",
    emailNotRecognisedText: login?.emailNotRecognisedText || "",
    passwordMismatchText: login?.passwordMismatchText || "",
    accNotVerifiedText: login?.accNotVerifiedText || "",
  };

  useEffect(() => {
    dispatch(fetchGlobalContentData("login"));
  }, [dispatch]);

  const validationSchema = Yup.object({
    loginText: Yup.string().required("Required"),
    recaptchaText: Yup.string().required("Required"),
    joinNowText: Yup.string().required("Required"),
    joinNowButtonText: Yup.string().required("Required"),
    emailNotRecognisedText: Yup.string().required("Required"),
    passwordMismatchText: Yup.string().required("Required"),
    accNotVerifiedText: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(
      submitGlobalContentData({ pageName: "login", ...values }, () => {
        toast.success("Data Submitted Successfully");
      })
    );
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {() => (
          <Form className="p-4">
            <Row className="mt-3">
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Login Button Text</h4>
                  </Label>
                  <Field type="text" name="loginText" as={Input} />
                  <ErrorMessage
                    name="loginText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Recaptcha Text</h4>
                  </Label>
                  <Field type="text" name="recaptchaText" as={Input} />
                  <ErrorMessage
                    name="recaptchaText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Join Now Text</h4>
                  </Label>
                  <Field type="text" name="joinNowText" as={Input} />
                  <ErrorMessage
                    name="joinNowText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Join Now Button Text</h4>
                  </Label>
                  <Field type="text" name="joinNowButtonText" as={Input} />
                  <ErrorMessage
                    name="joinNowButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Email not Recognised Text</h4>
                  </Label>
                  <Field
                    type="textarea"
                    name="emailNotRecognisedText"
                    as={Input}
                  />
                  <ErrorMessage
                    name="emailNotRecognisedText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Password doesn't Match Text</h4>
                  </Label>
                  <Field
                    type="textarea"
                    name="passwordMismatchText"
                    as={Input}
                  />
                  <ErrorMessage
                    name="passwordMismatchText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Error - Account not verified Text</h4>
                  </Label>
                  <Field type="textarea" name="accNotVerifiedText" as={Input} />
                  <ErrorMessage
                    name="accNotVerifiedText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-4">
              <Button
                type="submit"
                disabled={isLoading}
                className="btn btn-success"
              >
                {isLoading ? "Submitting" : "Submit"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
