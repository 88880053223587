import React, { useEffect } from "react";
import { useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import "assets/scss/pagination.scss";
import OnlyHeader from "components/Headers/OnlyHeader";
import AddUser from "components/Modals/AddUser";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubAdminsData } from "store/actions/subAdminAction";
import Loader from "components/Loader";
import { deleteSubAdmin } from "store/actions/subAdminAction";
import { SET_PAGE } from "store/types";
import ReactPaginate from "react-paginate";
import { DELETE_SUBADMIN_SUCCESS } from "store/types";
import { toast } from "react-toastify";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { setDeleteLoader } from "store/actions/globalContentAction";
import { useHistory } from "react-router-dom";

const Users = () => {
  const [userDetails, setUserDetails] = useState(null);
  const { user } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const { subAdmins, isSubAdminPageLoading, hitsPerPage, page, totalPages } =
    useSelector((state) => state.users);
  const [addUser, setAddUser] = useState(false);
  const toggleAddUser = () => {
    setAddUser(!addUser);
  };

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [confirmationId, setConfirmationId] = useState("");

  const confirmationToggle = () => {
    setConfirmationModal(!confirmationModal);
  };

  useEffect(() => {
    dispatch(fetchSubAdminsData(page, hitsPerPage));
  }, [dispatch, page]);

  function handlePageClick({ selected: selectedPage }) {
    dispatch({ type: SET_PAGE, payload: selectedPage });
  }

  useEffect(() => {
    if (user?.userType == "sub-admin") {
      history.push("/admin/index");
    }
  }, []);
  return (
    <>
      <AddUser
        toggleAddUser={toggleAddUser}
        addUser={addUser}
        userDetails={userDetails}
      />

      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0">Sub Admins</h3>
                <Button
                  onClick={() => {
                    toggleAddUser();
                    setUserDetails(null);
                  }}
                >
                  Add Sub Admin
                </Button>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!isSubAdminPageLoading &&
                    subAdmins.map((user, index) => (
                      <tr key={index}>
                        <td scope="row">{user.id}</td>
                        <td scope="row">{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.primaryEmail}</td>
                        <td>
                          <Button
                            size="sm"
                            className="btn btn-success"
                            onClick={() => {
                              toggleAddUser();
                              setUserDetails(user);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            size="sm"
                            className="btn btn-danger"
                            onClick={() => {
                              setConfirmationId(user?.id);
                              confirmationToggle();
                              // dispatch(
                              //   deleteSubAdmin({ userId: user.id }, () => {
                              //     setTimeout(() => {
                              //       dispatch(
                              //         fetchSubAdminsData(
                              //           subAdmins.length > 1
                              //             ? page
                              //             : page === 0
                              //             ? page
                              //             : page - 1,
                              //           hitsPerPage
                              //         )
                              //       );
                              //       dispatch({
                              //         type: DELETE_SUBADMIN_SUCCESS,
                              //         payload: "Deleted",
                              //       });
                              //       toast.success(
                              //         "SubAdmin Deleted Successfully"
                              //       );
                              //     }, 5000);
                              //   })
                              // )
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {isSubAdminPageLoading && <Loader />}
              {!isSubAdminPageLoading && (
                <CardFooter className="py-4 d-flex justify-content-end">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel="..."
                      pageCount={totalPages}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link "}
                      nextLinkClassName={"pagination__link border "}
                      pageLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={page}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>

        <ConfirmationModal
          isOpen={confirmationModal}
          isToggle={confirmationToggle}
          id={confirmationId}
          handleDeletion={() => {
            dispatch(
              deleteSubAdmin({ userId: confirmationId }, () => {
                setTimeout(() => {
                  dispatch(
                    fetchSubAdminsData(
                      subAdmins.length > 1
                        ? page
                        : page === 0
                        ? page
                        : page - 1,
                      hitsPerPage
                    )
                  );
                  dispatch(setDeleteLoader(false));
                  confirmationToggle();
                  // dispatch({
                  //   type: DELETE_SUBADMIN_SUCCESS,
                  //   payload: "Deleted",
                  // });
                  toast.success("Sub-Admin Deleted Successfully");
                }, 5000);
              })
            );
          }}
        />
      </Container>
    </>
  );
};
export default Users;
