import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createSubAdmin } from "store/actions/subAdminAction";
import { fetchSubAdminsData } from "store/actions/subAdminAction";
import { updateSubAdmin } from "store/actions/subAdminAction";

const AddUser = ({ toggleAddUser, addUser, userDetails }) => {
  const { isSubAdminPageLoading, page, hitsPerPage } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch();
  const initialValues = {
    firstName: userDetails?.firstName || "",
    lastName: userDetails?.lastName || "",
    primaryEmail: userDetails?.primaryEmail || "",
    password: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    primaryEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
    password: Yup.string()
      .min(7, "Password must be at least 7 characters")
      .required("Required"),
  });
  const validationSchema2 = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    primaryEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
  });

  const handleSubmit = (values) => {
    if (userDetails) {
      dispatch(
        updateSubAdmin({ ...values, userId: userDetails.id }, () => {
          toggleAddUser();
          // setTimeout(() => {
          //   dispatch(fetchSubAdminsData(page, hitsPerPage));
          // }, 5000);
        })
      );
    } else {
      dispatch(
        createSubAdmin(values, () => {
          setTimeout(() => {
            toggleAddUser();
            dispatch(fetchSubAdminsData(page, hitsPerPage));
          }, 5000);
        })
      );
    }
  };

  const handleToggle = () => {
    toggleAddUser();
  };

  return (
    <div>
      <Modal isOpen={addUser} toggle={toggleAddUser}>
        <ModalHeader toggle={toggleAddUser}>
          {userDetails ? "Update" : "Add"} Sub Admin
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={
              userDetails ? validationSchema2 : validationSchema
            }
            onSubmit={handleSubmit}
          >
            <Form>
              <Row>
                <Col lg="12" md="12">
                  <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12" md="12">
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12" md="12">
                  <FormGroup>
                    <Label for="primaryEmail">Email</Label>
                    <Field
                      type="text"
                      id="primaryEmail"
                      name="primaryEmail"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="primaryEmail"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                {!userDetails && (
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Field
                        type="password"
                        id="password"
                        name="password"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <ModalFooter>
                <Button onClick={handleToggle} disabled={isSubAdminPageLoading}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubAdminPageLoading}
                >
                  {userDetails
                    ? isSubAdminPageLoading
                      ? "Updating"
                      : "Update"
                    : isSubAdminPageLoading
                    ? "Submitting"
                    : "Submit"}
                </Button>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddUser;
