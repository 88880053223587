import React, { useEffect } from "react";
import { Col, Button, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
import { toast } from "react-toastify";

const ManualReview = () => {
  const dispatch = useDispatch();
  const { isLoading, manualReview, isFetchFunctionLoading } = useSelector(
    (state) => state.globalContent
  );

  useEffect(() => {
    dispatch(fetchGlobalContentData("manualReview"));
  }, [dispatch]);

  const initialValues = {
    reviewTitle: manualReview?.reviewTitle || "",
    reviewText: manualReview?.reviewText || "",
    acceptedFormsIDTextlink: manualReview?.acceptedFormsIDTextlink || "",
    acceptedFormsIDLink: manualReview?.acceptedFormsIDLink || "",
    returnToSiteButtonText: manualReview?.returnToSiteButtonText || "",
    backToEmailVerifyText: manualReview?.backToEmailVerifyText || "",
    backToEmailVerifyButtonText:
      manualReview?.backToEmailVerifyButtonText || "",
  };

  const validationSchema = Yup.object({
    reviewTitle: Yup.string().required("Required"),
    reviewText: Yup.string().required("Required"),
    acceptedFormsIDTextlink: Yup.string().required("Required"),
    acceptedFormsIDLink: Yup.string().required("Required"),
    returnToSiteButtonText: Yup.string().required("Required"),
    backToEmailVerifyText: Yup.string().required("Required"),
    backToEmailVerifyButtonText: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(
      submitGlobalContentData({ pageName: "manualReview", ...values }, () => {
        toast.success("Data Submitted Successfully");
      })
    );
  };

  return (
    <div>
      {isFetchFunctionLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <Form className="p-4">
            <Row className="mt-3">
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Review Title</h4>
                  </Label>
                  <Field type="text" name="reviewTitle" as={Input} />
                  <ErrorMessage
                    name="reviewTitle"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Review Text</h4>
                  </Label>
                  <Field type="textarea" name="reviewText" as={Input} />
                  <ErrorMessage
                    name="reviewText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Accepted Forms of ID Textlink</h4>
                  </Label>
                  <Field
                    type="text"
                    name="acceptedFormsIDTextlink"
                    as={Input}
                  />
                  <ErrorMessage
                    name="acceptedFormsIDTextlink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Accepted Forms of ID Link (URL)</h4>
                  </Label>
                  <Field type="text" name="acceptedFormsIDLink" as={Input} />
                  <ErrorMessage
                    name="acceptedFormsIDLink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Return to Site Button Text</h4>
                  </Label>
                  <Field type="text" name="returnToSiteButtonText" as={Input} />
                  <ErrorMessage
                    name="returnToSiteButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Back to Email Verify Text</h4>
                  </Label>
                  <Field type="text" name="backToEmailVerifyText" as={Input} />
                  <ErrorMessage
                    name="backToEmailVerifyText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Back to Email Verify Button Text</h4>
                  </Label>
                  <Field
                    type="text"
                    name="backToEmailVerifyButtonText"
                    as={Input}
                  />
                  <ErrorMessage
                    name="backToEmailVerifyButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-4">
              <Button
                type="submit"
                disabled={isLoading}
                className="btn btn-success"
              >
                {isLoading ? "Submitting" : "Submit"}
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default ManualReview;
