import {
  FETCH_CODES_START,
  FETCH_CODES_SUCCESS,
  FETCH_CODES_ERROR,
  SUBMIT_CODE_START,
  SUBMIT_CODE_SUCCESS,
  SUBMIT_CODE_ERROR,
  UPDATE_CODE_START,
  UPDATE_CODE_SUCCESS,
  UPDATE_CODE_ERROR,
  DELETE_CODE_START,
  DELETE_CODE_SUCCESS,
  DELETE_CODE_ERROR,
  SET_CODE_TOTAL_PAGES,
  SET_CODE_PAGE,
  ADD_CODE_PAYLOAD,
  UPDATE_CODE_PAYLOAD,
} from "../types";

const initialState = {
  codeData: [],
  page: 0,
  totalPages: 0,
  hitsPerPage: 10,
  isLoading: false,
  error: null,
  progress: 0,
};

const codeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CODES_START:
    case SUBMIT_CODE_START:
    case UPDATE_CODE_START:
    case DELETE_CODE_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_CODES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        codeData: action.payload,
      };
    case ADD_CODE_PAYLOAD:
      return {
        ...state,
        codeData: [action.payload, ...state.codeData],
      };
    case UPDATE_CODE_PAYLOAD:
      return {
        ...state,
        isLoading: false,
        codeData: state.codeData.map((code) =>
          code.id == action.payload.id ? { ...code, ...action.payload } : code
        ),
      };
    case SET_CODE_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    case SET_CODE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case SUBMIT_CODE_SUCCESS:
    case UPDATE_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        codeData: state.codeData.filter((code) => code.id !== action.payload),
      };
    case FETCH_CODES_ERROR:
    case SUBMIT_CODE_ERROR:
    case UPDATE_CODE_ERROR:
    case DELETE_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case "UPDATE_CODES_PROGRESS":
      console.log("UPdating state", action.payload);
      return {
        ...state,
        progress: action.payload,
      };
    default:
      return state;
  }
};

export default codeReducer;
