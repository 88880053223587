export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const LOGOUT = "LOGOUT";
export const REGISTER_LOADER = "REGISTER_LOADER";
export const LOGIN_LOADER = "LOGIN_LOADER";
export const RESET_PASSWORD = "RESET_PASSWORD";

// ============Stats Action Types============
export const FETCH_STATS_START = "FETCH_STATS_START";
export const FETCH_STATS_SUCCESS = "FETCH_STATS_SUCCESS";
export const FETCH_STATS_ERROR = "FETCH_STATS_ERROR";

// ============Offers Action Types============
export const SUBMIT_OFFER_START = "SUBMIT_OFFER_START";
export const SUBMIT_OFFER_SUCCESS = "SUBMIT_OFFER_SUCCESS";
export const SUBMIT_OFFER_ERROR = "SUBMIT_OFFER_ERROR";

export const FETCH_SEARCH_OFFERS_SUCCESS = "FETCH_SEARCH_OFFERS_SUCCESS";
export const FETCH_SINGLE_OFFER_SUCCESS = "FETCH_SINGLE_OFFER_SUCCESS";

export const ADD_OFFER_PAYLOAD = "ADD_OFFER_PAYLOAD";
export const UPDATE_OFFER_PAYLOAD = "UPDATE_OFFER_PAYLOAD";

export const FETCH_OFFERS_START = "FETCH_OFFERS_START";
export const FETCH_OFFERS_SUCCESS = "FETCH_OFFERS_SUCCESS";
export const FETCH_OFFERS_ERROR = "FETCH_OFFERS_ERROR";

export const SET_OFFER_PAGE = "SET_OFFER_PAGE";
export const SET_OFFER_TOTAL_PAGES = "SET_OFFER_TOTAL_PAGES";

export const UPDATE_OFFER_START = "UPDATE_OFFER_START";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_ERROR = "UPDATE_OFFER_ERROR";

export const DELETE_OFFER_START = "DELETE_OFFER_START";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_ERROR = "DELETE_OFFER_ERROR";

// ============CODE Action Types============

export const SUBMIT_CODE_START = "SUBMIT_CODE_START";
export const SUBMIT_CODE_SUCCESS = "SUBMIT_CODE_SUCCESS";
export const SUBMIT_CODE_ERROR = "SUBMIT_CODE_ERROR";

export const ADD_CODE_PAYLOAD = "ADD_CODE_PAYLOAD";
export const UPDATE_CODE_PAYLOAD = "UPDATE_CODE_PAYLOAD";

export const FETCH_CODES_START = "FETCH_CODES_START";
export const FETCH_CODES_SUCCESS = "FETCH_CODES_SUCCESS";
export const FETCH_CODES_ERROR = "FETCH_CODES_ERROR";

export const SET_CODE_TOTAL_PAGES = "SET_CODE_TOTAL_PAGES";
export const SET_CODE_PAGE = "SET_CODE_PAGE";

export const UPDATE_CODE_START = "UPDATE_CODE_START";
export const UPDATE_CODE_SUCCESS = "UPDATE_CODE_SUCCESS";
export const UPDATE_CODE_ERROR = "UPDATE_CODE_ERROR";

export const DELETE_CODE_START = "DELETE_CODE_START";
export const DELETE_CODE_SUCCESS = "DELETE_CODE_SUCCESS";
export const DELETE_CODE_ERROR = "DELETE_CODE_ERROR";

// ============GLOBAL Action Types============
export const SUBMIT_GLOBAL_CONTENT_START = "SUBMIT_GLOBAL_CONTENT_START";
export const SUBMIT_GLOBAL_CONTENT_SUCCESS = "SUBMIT_GLOBAL_CONTENT_SUCCESS";
export const SUBMIT_GLOBAL_CONTENT_ERROR = "SUBMIT_GLOBAL_CONTENT_ERROR";

export const FETCH_GLOBAL_CONTENT_START = "FETCH_GLOBAL_CONTENT_START";
export const FETCH_GLOBAL_CONTENT_SUCCESS = "FETCH_GLOBAL_CONTENT_SUCCESS";
export const FETCH_GLOBAL_CONTENT_ERROR = "FETCH_GLOBAL_CONTENT_ERROR";

// ============Brand Action Types============
export const SUBMIT_BRAND_START = "SUBMIT_BRAND_START";
export const SUBMIT_BRAND_SUCCESS = "SUBMIT_BRAND_SUCCESS";
export const SUBMIT_BRAND_ERROR = "SUBMIT_BRAND_ERROR";

export const ADD_BRAND_PAYLOAD = "ADD_BRAND_PAYLOAD";
export const UPDATE_BRAND_PAYLOAD = "UPDATE_BRAND_PAYLOAD";

export const FETCH_BRANDS_START = "FETCH_BRANDS_START";
export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_ERROR = "FETCH_BRANDS_ERROR";

export const FETCH_SEARCH_BRANDS_SUCCESS = "FETCH_SEARCH_BRANDS_SUCCESS";

export const FETCH_SINGLE_BRAND_START = "FETCH_SINGLE_BRAND_START";
export const FETCH_SINGLE_BRAND_SUCCESS = "FETCH_SINGLE_BRAND_SUCCESS";
export const FETCH_SINGLE_BRAND_ERROR = "FETCH_SINGLE_BRAND_ERROR";

export const SET_BRAND_TOTAL_PAGES = "SET_BRAND_TOTAL_PAGES";
export const SET_BRAND_PAGE = "SET_BRAND_PAGE";

export const UPDATE_BRAND_START = "UPDATE_BRAND_START";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_ERROR = "UPDATE_BRAND_ERROR";

export const DELETE_BRAND_START = "DELETE_BRAND_START";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_ERROR = "DELETE_BRAND_ERROR";

// ============Sub Admin Action Types============
export const ADD_SUBADMIN_START = "ADD_SUBADMIN_START";
export const ADD_SUBADMIN_SUCCESS = "ADD_SUBADMIN_SUCCESS";
export const ADD_SUBADMIN_ERROR = "ADD_SUBADMIN_ERROR";

export const ADD_SUBADMIN_PAYLOAD = "ADD_SUBADMIN_PAYLOAD";
export const UPDATE_SUBADMIN_PAYLOAD = "UPDATE_SUBADMIN_PAYLOAD";
export const UPDATE_MEMBER_PAYLOAD = "UPDATE_MEMBER_PAYLOAD";

export const FETCH_SUBADMINS_START = "FETCH_SUBADMINS_START";
export const FETCH_SUBADMINS_SUCCESS = "FETCH_SUBADMINS_SUCCESS";
export const FETCH_SUBADMINS_ERROR = "FETCH_SUBADMINS_ERROR";

export const FETCH_SINGLE_USER_START = "FETCH_SINGLE_USER_START";
export const FETCH_SINGLE_USER_SUCCESS = "FETCH_SINGLE_USER_SUCCESS";
export const FETCH_SINGLE_USER_ERROR = "FETCH_SINGLE_USER_ERROR";

export const FETCH_MEMBERS_START = "FETCH_MEMBERS_START";
export const FETCH_MEMBERS_SUCCESS = "FETCH_MEMBERS_SUCCESS";
export const FETCH_MEMBERS_ERROR = "FETCH_MEMBERS_ERROR";

export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_TOTAL_MEMBER_PAGES = "SET_TOTAL_MEMBER_PAGES";
export const SET_MEMBER_PAGE = "SET_MEMBER_PAGE";
export const SET_PAGE = "SET_PAGE";

export const UPDATE_SUBADMIN_START = "UPDATE_SUBADMIN_START";
export const UPDATE_SUBADMIN_SUCCESS = "UPDATE_SUBADMIN_SUCCESS";
export const UPDATE_SUBADMIN_ERROR = "UPDATE_SUBADMIN_ERROR";

export const DELETE_SUBADMIN_START = "DELETE_SUBADMIN_START";
export const DELETE_SUBADMIN_SUCCESS = "DELETE_SUBADMIN_SUCCESS";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_SUBADMIN_ERROR = "DELETE_SUBADMIN_ERROR";

// ============DOMAIN Action Types============

export const SUBMIT_DOMAIN_START = "SUBMIT_DOMAIN_START";
export const SUBMIT_DOMAIN_SUCCESS = "SUBMIT_DOMAIN_SUCCESS";
export const SUBMIT_DOMAIN_ERROR = "SUBMIT_DOMAIN_ERROR";

export const ADD_DOMAIN_PAYLOAD = "ADD_DOMAIN_PAYLOAD";
export const UPDATE_DOMAIN_PAYLOAD = "UPDATE_DOMAIN_PAYLOAD";

export const FETCH_DOMAINS_START = "FETCH_DOMAINS_START";
export const FETCH_DOMAINS_SUCCESS = "FETCH_DOMAINS_SUCCESS";
export const FETCH_DOMAINS_ERROR = "FETCH_DOMAINS_ERROR";

export const SET_DOMAIN_TOTAL_PAGES = "SET_DOMAIN_TOTAL_PAGES";
export const SET_DOMAIN_PAGE = "SET_DOMAIN_PAGE";

export const UPDATE_DOMAIN_START = "UPDATE_DOMAIN_START";
export const UPDATE_DOMAIN_SUCCESS = "UPDATE_DOMAIN_SUCCESS";
export const UPDATE_DOMAIN_ERROR = "UPDATE_DOMAIN_ERROR";

export const DELETE_DOMAIN_START = "DELETE_DOMAIN_START";
export const DELETE_DOMAIN_SUCCESS = "DELETE_DOMAIN_SUCCESS";
export const DELETE_DOMAIN_ERROR = "DELETE_DOMAIN_ERROR";

// ============DROPDOWN Action Types============
export const SUBMIT_DROPDOWN_START = "SUBMIT_DROPDOWN_START";
export const SUBMIT_DROPDOWN_SUCCESS = "SUBMIT_DROPDOWN_SUCCESS";
export const SUBMIT_DROPDOWN_ERROR = "SUBMIT_DROPDOWN_ERROR";

export const FETCH_DROPDOWN_START = "FETCH_DROPDOWN_START";
export const FETCH_DROPDOWN_SUCCESS = "FETCH_DROPDOWN_SUCCESS";
export const FETCH_DROPDOWN_ERROR = "FETCH_DROPDOWN_ERROR";

export const UPDATE_DROPDOWN_START = "UPDATE_DROPDOWN_START";
export const UPDATE_DROPDOWN_SUCCESS = "UPDATE_DROPDOWN_SUCCESS";
export const UPDATE_DROPDOWN_ERROR = "UPDATE_DROPDOWN_ERROR";

export const DELETE_DROPDOWN_START = "DELETE_DROPDOWN_START";
export const DELETE_DROPDOWN_SUCCESS = "DELETE_DROPDOWN_SUCCESS";
export const DELETE_DROPDOWN_ERROR = "DELETE_DROPDOWN_ERROR";
// ============DROPDOWN Action Types============
export const SUBMIT_PRONOUN_START = "SUBMIT_PRONOUN_START";
export const SUBMIT_PRONOUN_SUCCESS = "SUBMIT_PRONOUN_SUCCESS";
export const SUBMIT_PRONOUN_ERROR = "SUBMIT_PRONOUN_ERROR";

export const FETCH_PRONOUN_START = "FETCH_PRONOUN_START";
export const FETCH_PRONOUN_SUCCESS = "FETCH_PRONOUN_SUCCESS";
export const FETCH_PRONOUN_ERROR = "FETCH_PRONOUN_ERROR";

export const UPDATE_PRONOUN_START = "UPDATE_PRONOUN_START";
export const UPDATE_PRONOUN_SUCCESS = "UPDATE_PRONOUN_SUCCESS";
export const UPDATE_PRONOUN_ERROR = "UPDATE_PRONOUN_ERROR";

export const DELETE_PRONOUN_START = "DELETE_PRONOUN_START";
export const DELETE_PRONOUN_SUCCESS = "DELETE_PRONOUN_SUCCESS";
export const DELETE_PRONOUN_ERROR = "DELETE_PRONOUN_ERROR";

// =====================IMAGE VERIFICATION ======================

export const FETCH_IMG_VERIFICATION_START = "FETCH_IMG_VERIFICATION_START";
export const FETCH_IMG_VERIFICATION_SUCCESS = "FETCH_IMG_VERIFICATION_SUCCESS";
export const FETCH_IMG_VERIFICATION_ERROR = "FETCH_IMG_VERIFICATION_ERROR";

export const UPDATE_VERIFY_IMAGE_START = "UPDATE_VERIFY_IMAGE_START";
export const UPDATE_VERIFY_IMAGE_SUCCESS = "UPDATE_VERIFY_IMAGE_SUCCESS";
export const UPDATE_VERIFY_IMAGE_ERROR = "UPDATE_VERIFY_IMAGE_ERROR";

export const SET_DELETION_LOADER = "SET_DELETION_LOADER";
