import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import { submitDefaultGlobalContentData } from "store/actions/globalContentAction";

const DefaultGlobalContent = () => {
  const dispatch = useDispatch();
  const { isLoading, defaultContent, isFetchFunctionLoading } = useSelector(
    (state) => state.globalContent
  );

  const [defaultKiiLogo, setDefaultKiiLogo] = useState("");
  const [defaultHeaderBackground, setDefaultHeaderBackground] = useState("");

  useEffect(() => {
    dispatch(fetchGlobalContentData("defaultContent"));
  }, [dispatch]);

  const initialValues = {
    defaultOfferTitle: defaultContent?.defaultOfferTitle || "",
    limitedText1: defaultContent?.limitedText1 || "",
    limitedText2: defaultContent?.limitedText2 || "",
    defaultOfferDescription: defaultContent?.defaultOfferDescription || "",
    defaultKiiLogo: "",
    defaultHeaderBackground: "",
  };

  const validationSchema = Yup.object({
    limitedText1: Yup.string().required("Required"),
    limitedText2: Yup.string().required("Required"),
    defaultOfferTitle: Yup.string().required("Required"),
    defaultOfferDescription: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    let {
      defaultKiiLogo: logo,
      defaultHeaderBackground: backgroundImage,
      ...rest
    } = values;
    if (logo && logo != "") {
      rest = { ...rest, defaultKiiLogo: logo };
    }
    if (backgroundImage && backgroundImage != "") {
      rest = { ...rest, defaultHeaderBackground: backgroundImage };
    }

    dispatch(
      submitDefaultGlobalContentData(
        { pageName: "defaultContent", ...rest },
        () => {
          setDefaultHeaderBackground("");
          setDefaultKiiLogo("");
          toast.success("Data Submitted Successfully");
          resetForm();
          setTimeout(() => {
            dispatch(fetchGlobalContentData("defaultContent"));
          }, 500);
        }
      )
    );
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setFieldValue(event.target.name, file);
      } else {
        toast.error("Selected file is not a valid image.");
        document.getElementById(event.target.name).value = "";
      }
    } else {
      document.getElementById(event.target.name).value = "";
    }
  };

  useEffect(() => {
    if (defaultContent?.defaultKiiLogo) {
      setDefaultKiiLogo(defaultContent?.defaultKiiLogo);
    }
    if (defaultContent?.defaultHeaderBackground) {
      setDefaultHeaderBackground(defaultContent?.defaultHeaderBackground);
    }
  }, [defaultContent]);

  return (
    <div>
      {isFetchFunctionLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form className="p-4">
              <Row className="mt-3">
                <Col lg="6">
                  <div className="d-flex">
                    <FormGroup>
                      <Label for="brandLogo">
                        <h4>Kii Logo</h4>
                      </Label>

                      <Input
                        id="defaultKiiLogo"
                        name="defaultKiiLogo"
                        type="file"
                        accept=".jpg, .jpeg, .svg, .png"
                        onChange={(event) => {
                          setDefaultKiiLogo("");
                          handleFileChange(event, formik.setFieldValue);
                        }}
                      />
                      <ErrorMessage
                        name="defaultKiiLogo"
                        component="div"
                        className="text-danger"
                      />
                      {/* <small className="text-muted mt-2">
                        Recommended dimensions: 51x54 pixels
                      </small> */}
                    </FormGroup>
                    {defaultKiiLogo?.length > 0 && (
                      <img
                        src={defaultKiiLogo}
                        alt="logo"
                        width={45}
                        height={45}
                      />
                    )}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="d-flex">
                    <FormGroup>
                      <Label for="brandLogo">
                        <h4>Header background</h4>
                      </Label>

                      <Input
                        id="defaultHeaderBackground"
                        name="defaultHeaderBackground"
                        type="file"
                        accept=".jpg, .jpeg, .svg, .png"
                        onChange={(event) => {
                          setDefaultHeaderBackground("");
                          handleFileChange(event, formik.setFieldValue);
                        }}
                      />
                      <ErrorMessage
                        name="defaultHeaderBackground"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                    {defaultHeaderBackground?.length > 0 && (
                      <img
                        src={defaultHeaderBackground}
                        alt="logo"
                        width={45}
                        height={45}
                      />
                    )}
                  </div>
                </Col>
                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>Offer Title</h4>
                    </Label>
                    <Field type="text" name="defaultOfferTitle" as={Input} />
                    <ErrorMessage
                      name="defaultOfferTitle"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>

                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>Limited Text 1</h4>
                    </Label>
                    <Field type="text" name="limitedText1" as={Input} />
                    <ErrorMessage
                      name="limitedText1"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>Limited Text 2</h4>
                    </Label>
                    <Field type="text" name="limitedText2" as={Input} />
                    <ErrorMessage
                      name="limitedText2"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>

                <Col lg="6" xl="4">
                  <FormGroup>
                    <Label>
                      <h4>Offer Description</h4>
                    </Label>
                    <Field
                      type="textarea"
                      name="defaultOfferDescription"
                      as={Input}
                    />
                    <ErrorMessage
                      name="defaultOfferDescription"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mb-4">
                <Button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-success"
                >
                  {isLoading ? "Submitting" : "Submit"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default DefaultGlobalContent;
