import firebase from "../../config/firebase";
import { FETCH_CODES_SUCCESS } from "store/types";
import { UPDATE_CODE_SUCCESS } from "store/types";
import { UPDATE_CODE_START } from "store/types";
import { DELETE_CODE_START } from "store/types";
import { DELETE_CODE_ERROR } from "store/types";
import { DELETE_CODE_SUCCESS } from "store/types";
import { FETCH_CODES_START } from "store/types";
import { UPDATE_CODE_ERROR } from "store/types";
import { FETCH_CODES_ERROR } from "store/types";
import { SUBMIT_CODE_ERROR } from "store/types";
import { SUBMIT_CODE_SUCCESS } from "store/types";
import { SUBMIT_CODE_START } from "store/types";
import { codesIndex } from "./subAdminAction";
import { SET_CODE_TOTAL_PAGES } from "store/types";
import { toast } from "react-toastify";
import { ADD_CODE_PAYLOAD } from "store/types";
import { UPDATE_CODE_PAYLOAD } from "store/types";
import { setDeleteLoader } from "./globalContentAction";

let offersCollection = firebase.firestore().collection("offers");

// ============CREATE============
export const submitCodeData =
  (data, onSuccess = () => {}, onBeforeSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: SUBMIT_CODE_START });

    let offerDetails = await offersCollection.doc(data?.offer).get();

    let { codeType } = offerDetails.data();

    if (data.codeType === "generic") {
      if (codeType && codeType == "batch") {
        toast.error(
          "A batch code already exists for this offer! you can only create batch code for this offer"
        );
        dispatch({ type: SUBMIT_CODE_ERROR, payload: "" });
      } else {
        if (codeType && codeType == "generic") {
          toast.error("Generic code of this offer already exists!");
          dispatch({ type: SUBMIT_CODE_ERROR, payload: "" });
        } else {
          firebase
            .firestore()
            .collection("codes")
            .add({
              offer: data.offer,
              brandName: data.brandName,
              showCodeType: data.showCodeType,
              code: data.generic,
              status: "unassigned",
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((response) => {
              if (!codeType) {
                console.log("Going to add first time");
                offersCollection.doc(data?.offer).update({
                  codeType: "generic",
                });
              }

              setTimeout(() => {
                dispatch({ type: SUBMIT_CODE_SUCCESS });
                dispatch({
                  type: ADD_CODE_PAYLOAD,
                  payload: {
                    offer: data.offer,
                    brandName: data.brandName,
                    showCodeType: data.showCodeType,
                    code: data.generic,
                    status: "unassigned",
                    id: response.id,
                  },
                });
                toast.success("Code Added Successfully");
              }, 5000);
              onSuccess();
            })
            .catch((error) => {
              dispatch({ type: SUBMIT_CODE_ERROR, payload: error });
            });
        }
      }
    } else if (data?.batchFile) {
      if (codeType && codeType == "generic") {
        toast.error(
          "A generic code already exists for this offer! you can only create generic code for this offer"
        );
        dispatch({ type: SUBMIT_CODE_ERROR, payload: "" });
      } else {
        console.log("Going to call on succsess");
        onBeforeSuccess();
        dispatch({ type: SUBMIT_CODE_SUCCESS });
        const batch = firebase.firestore().batch(); // Initialize the batch write

        // Update codeType using batch write
        if (!codeType) {
          const offerRef = offersCollection.doc(data?.offer);
          batch.update(offerRef, { codeType: "batch" });
        }

        const fileReader = new FileReader();
        fileReader.onload = async (event) => {
          const csvData = event.target.result;
          const dataArray = csvData.split("\n").map((row) => row.trim());
          const flattenedArray = dataArray
            .slice(1)
            .flat()
            .filter((value) => value !== "");

          const totalCodes = flattenedArray.length;
          let codesAdded = 0;

          console.log("Total codes", totalCodes);
          for (let i = 0; i < flattenedArray.length; i++) {
            const code = flattenedArray[i];

            const codeRef = firebase.firestore().collection("codes").doc();
            batch.set(codeRef, {
              offer: data.offer,
              brandName: data.brandName,
              showCodeType: data.showCodeType,
              code: code,
              status: "unassigned",
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });

            codesAdded++;

            const progress = (codesAdded / totalCodes) * 100;
            dispatch({
              type: "UPDATE_CODES_PROGRESS",
              payload: Math.floor(progress),
            });
            await new Promise((resolve) => setTimeout(resolve, 1)); // Introduce a delay
          }

          try {
            // await Promise.all(codesPromises);
            setTimeout(() => {
              dispatch({ type: "UPDATE_CODES_PROGRESS", payload: 0 });
            }, 1000);
            await batch.commit(); // Commit the batch write
            setTimeout(() => {
              dispatch({ type: SUBMIT_CODE_SUCCESS });
            }, 5000);
            onSuccess();
          } catch (error) {
            dispatch({ type: SUBMIT_CODE_ERROR, payload: error });
            console.log(error);
            dispatch({ type: "UPDATE_CODES_PROGRESS", payload: 0 });
          }
        };

        fileReader.readAsText(data.batchFile);
        // if (!codeType) {
        //   offersCollection.doc(data?.offer).update({
        //     codeType: "batch",
        //   });
        // }

        // const fileReader = new FileReader();
        // fileReader.onload = (event) => {
        //   const csvData = event.target.result;
        //   const dataArray = csvData.split("\n").map((row) => row.trim());
        //   const flattenedArray = dataArray
        //     .slice(1)
        //     .flat()
        //     .filter((value) => value !== "");
        //   if (flattenedArray.length > 20) {
        //     toast.error(
        //       "Access denied. Maximum capacity reached. Only 20 records can be added at this time."
        //     );
        //     dispatch({
        //       type: SUBMIT_CODE_ERROR,
        //       payload:
        //         "Access denied. Maximum capacity reached. Only 20 records can be added at this time.",
        //     });
        //     return;
        //   }
        //   const codesPromises = flattenedArray.map(async (row) => {
        //     const code = row.trim();

        //     const response = await firebase
        //       .firestore()
        //       .collection("codes")
        //       .add({
        //         offer: data.offer,
        //         // codeType: data.codeType,
        //         brandName: data.brandName,
        //         code: code,
        //         status: "unassigned",
        //         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        //       });
        //     dispatch({
        //       type: ADD_CODE_PAYLOAD,
        //       payload: {
        //         offer: data.offer,
        //         brandName: data.brandName,
        //         code: code,
        //         status: "unassigned",
        //         id: response.id,
        //         objectID: response.id,
        //       },
        //     });
        //     return response;
        //   });

        //   Promise.all(codesPromises)
        //     .then(() => {
        //       setTimeout(() => {
        //         dispatch({ type: SUBMIT_CODE_SUCCESS });
        //         toast.success("Multiple Code Added Successfully");
        //       }, 5000);
        //       onSuccess();
        //     })
        //     .catch((error) => {
        //       dispatch({ type: SUBMIT_CODE_ERROR, payload: error });
        //       console.log(error);
        //     });
        // };

        // fileReader.readAsText(data.batchFile);
      }
    } else {
      toast.warning("Something went wrong!");
      dispatch({ type: SUBMIT_CODE_ERROR, payload: "" });
      // firebase
      //   .firestore()
      //   .collection("codes")
      //   .add({
      //     offer: data.offer,
      //     brandName: data.brandName,
      //     // codeType: data.codeType,
      //     code: data.generic,
      //     status: "unassigned",
      //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      //   })
      //   .then(() => {
      //     setTimeout(() => {
      //       dispatch({ type: SUBMIT_CODE_SUCCESS });
      //       toast.success("Code Added Successfully");
      //     }, 5000);
      //     onSuccess();
      //   })
      //   .catch((error) => {
      //     dispatch({ type: SUBMIT_CODE_ERROR, payload: error });
      //   });
    }
  };

// =========GET==========
export const fetchCodesData = (page, hitsPerPage, selectedBrand, sortBy) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CODES_START });

    let settings = await codesIndex.getSettings();

    let sort = sortBy ? sortBy : "asc";

    settings.ranking = [`${sort}(createdAt_unix)`];
    await codesIndex.setSettings(settings);

    await new Promise((resolve) =>
      setTimeout(() => {
        resolve();
      }, 2000)
    );

    codesIndex
      .search("", {
        page,
        hitsPerPage,
        filters: selectedBrand?.value
          ? `brandName:${selectedBrand?.value}`
          : "",
      })
      .then(async (response) => {
        const codes = [];

        for (let doc of response.hits) {
          // let { brandName, offer } = doc;
          // let brandDetails = await firebase
          //   .firestore()
          //   .collection("brands")
          //   .doc(brandName)
          //   .get();
          // let offerDetails = await firebase
          //   .firestore()
          //   .collection("offers")
          //   .doc(offer)
          //   .get();
          // codes.push({
          //   id: doc.objectID,
          //   ...doc,
          //   brandDetails: brandDetails.data()
          //     ? { id: brandDetails.id, ...brandDetails.data() }
          //     : {},
          //   offerDetails: offerDetails.data()
          //     ? { id: offerDetails.id, ...offerDetails.data() }
          //     : {},
          // });

          const { brandName, offer } = doc;

          // Fetch brandDetails and offerDetails in parallel using Promise.all
          const [brandDetails, offerDetails] = await Promise.all([
            firebase.firestore().collection("brands").doc(brandName).get(),
            firebase.firestore().collection("offers").doc(offer).get(),
          ]);

          codes.push({
            id: doc.objectID,
            ...doc,
            brandDetails: brandDetails.exists
              ? { id: brandDetails.id, ...brandDetails.data() }
              : {},
            offerDetails: offerDetails.exists
              ? { id: offerDetails.id, ...offerDetails.data() }
              : {},
          });
        }

        // response.hits.forEach((doc) => {

        //   codes.push({ id: doc.objectID, ...doc });
        // });
        dispatch({ type: FETCH_CODES_SUCCESS, payload: codes });
        dispatch({
          type: SET_CODE_TOTAL_PAGES,
          payload: response.nbPages,
        });
      })
      .catch((error) => {
        dispatch(fetchCodeError(error));
      });
  };
};

export const fetchCodeError = (error) => {
  return { type: FETCH_CODES_ERROR, payload: error };
};

// ==========UPDATE==============
export const updateCodeData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_CODE_START });

    // const { id, offer, generic, showCodeType } = codeDetails;
    firebase
      .firestore()
      .collection("codes")
      .doc(data.id)
      .update({
        offer: data.offer,
        showCodeType: data.showCodeType,
        code: data.generic,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((res) => {
        dispatch({ type: UPDATE_CODE_SUCCESS });

        dispatch({
          type: UPDATE_CODE_PAYLOAD,
          payload: {
            offer: data.offer,
            showCodeType: data.showCodeType,
            code: data.generic,
            id: data.id,
            objectID: data.id,
          },
        });
        toast.success("Code Updated Successfully");

        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: UPDATE_CODE_ERROR, payload: error });
      });
  };

// ==========DELETE=========
export const deleteCode = (id, onSuccess = () => {}) => {
  return async (dispatch) => {
    dispatch(setDeleteLoader(true));

    firebase
      .firestore()
      .collection("codes")
      .doc(id)
      .delete()
      .then(() => {
        setTimeout(() => {
          dispatch(setDeleteLoader(false));

          toast.success("Code Deleted Successfully");
        }, 5000);
        onSuccess();
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(setDeleteLoader(false));
      });

    // let isFoundInUsers = await firebase
    //   .firestore()
    //   .collection("users")
    //   .where("codeId", "==", id)
    //   .get();

    // if (isFoundInUsers?.size > 0) {
    //   dispatch(setDeleteLoader(false));

    //   toast.warning(
    //     "This code is assigned to a user, and you cannot delete it"
    //   );

    // } else {
    //   firebase
    //     .firestore()
    //     .collection("codes")
    //     .doc(id)
    //     .delete()
    //     .then(() => {
    //       setTimeout(() => {
    //         // dispatch({ type: DELETE_CODE_SUCCESS, payload: id });
    //         dispatch(setDeleteLoader(false));

    //         toast.success("Code Deleted Successfully");
    //       }, 5000);
    //       onSuccess();
    //     })
    //     .catch((error) => {
    //       toast.error(error.message);
    //       dispatch(setDeleteLoader(false));
    //     });
    // }
  };
};
