import React, { useEffect } from "react";
import { Col, Button, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
import { toast } from "react-toastify";

const MyAccountMenu = () => {
  const dispatch = useDispatch();
  const { isLoading, myAccountMenu, isFetchFunctionLoading } = useSelector(
    (state) => state.globalContent
  );

  useEffect(() => {
    dispatch(fetchGlobalContentData("myAccountMenu"));
  }, [dispatch]);

  const initialValues = {
    myAccountTitle: myAccountMenu?.myAccountTitle || "",
    myAccountText: myAccountMenu?.myAccountText || "",
    myDetailsMenuField: myAccountMenu?.myDetailsMenuField || "",
    kiiIDMenuField: myAccountMenu?.kiiIDMenuField || "",
  };

  const validationSchema = Yup.object({
    myAccountTitle: Yup.string().required("Required"),
    myAccountText: Yup.string().required("Required"),
    myDetailsMenuField: Yup.string().required("Required"),
    kiiIDMenuField: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(
      submitGlobalContentData({ pageName: "myAccountMenu", ...values }, () => {
        toast.success("Data Submitted Successfully");
      })
    );
  };

  return (
    <div>
      {isFetchFunctionLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <Form className="p-4">
            <Row className="mt-3">
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>My Account Title</h4>
                  </Label>
                  <Field type="text" name="myAccountTitle" as={Input} />
                  <ErrorMessage
                    name="myAccountTitle"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>My Account Text</h4>
                  </Label>
                  <Field type="text" name="myAccountText" as={Input} />
                  <ErrorMessage
                    name="myAccountText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>My Details Menu Field</h4>
                  </Label>
                  <Field type="text" name="myDetailsMenuField" as={Input} />
                  <ErrorMessage
                    name="myDetailsMenuField"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>kii ID Menu Field</h4>
                  </Label>
                  <Field type="text" name="kiiIDMenuField" as={Input} />
                  <ErrorMessage
                    name="kiiIDMenuField"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-4">
              <Button
                type="submit"
                disabled={isLoading}
                className="btn btn-success"
              >
                {isLoading ? "Submitting" : "Submit"}
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default MyAccountMenu;
