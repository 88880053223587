import React, { useEffect, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader.js";

import {
  Container,
  Row,
  Card,
  CardHeader,
  Table,
  Media,
  Button,
  CardFooter,
  Badge,
  Input,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { fetchBrands } from "store/actions/brandAction";
import Loader from "components/Loader";
import { deleteBrand } from "store/actions/brandAction";
import ReactPaginate from "react-paginate";
import { SET_BRAND_PAGE } from "store/types";
import AddBrandModal from "../components/Modals/AddBrandModal";
import EditBrandModal from "../components/Modals/EditBrandModal";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { setDeleteLoader } from "store/actions/globalContentAction";

const BrandsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [brandID, setBrandID] = useState("");
  const dispatch = useDispatch();
  const { brands, isBrandLoading, hitsPerPage, page, totalPages } = useSelector(
    (state) => state.brand
  );

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [confirmationId, setConfirmationId] = useState("");

  const [search, setSearch] = useState("");

  let searchTimeout;

  const handleSearchChange = (e) => {
    let { value } = e.target;
    setSearch(value);

    // Clear any previous timeout to prevent an immediate search
    clearTimeout(searchTimeout);

    // Set a new timeout to call the search function after 500ms (adjust as needed)
    searchTimeout = setTimeout(() => {
      dispatch(fetchBrands(0, hitsPerPage, value));
    }, 500);
  };

  const confirmationToggle = () => {
    setConfirmationModal(!confirmationModal);
  };

  useEffect(() => {
    dispatch(fetchBrands(page, hitsPerPage, search));
  }, [dispatch, page]);
  function handlePageClick({ selected: selectedPage }) {
    dispatch({ type: SET_BRAND_PAGE, payload: selectedPage });
  }
  const toggleBrandModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const toggleEditBrandModal = (id) => {
    setBrandID(id);
    setIsEditModalOpen(!isEditModalOpen);
  };
  console.log(page - 1);
  const handleDelete = (id) => {
    dispatch(
      deleteBrand(id, () => {
        setTimeout(() => {
          dispatch(
            fetchBrands(
              brands.length > 1 ? page : page === 0 ? page : page - 1,
              hitsPerPage,
              search
            )
          );
        }, 5000);
      })
    );
  };

  return (
    <>
      <OnlyHeader />
      <AddBrandModal
        isModalOpen={isModalOpen}
        toggleBrandModal={toggleBrandModal}
        search={search}
      />
      <EditBrandModal
        isEditModalOpen={isEditModalOpen}
        toggleEditBrandModal={toggleEditBrandModal}
        brandID={brandID}
        search={search}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <div className="d-flex flex-md-row flex-column">
                  <h3 className="mb-0 mr-2 mb-2">Brands</h3>{" "}
                  <Input
                    type="text"
                    value={search}
                    placeholder="Search by brand name"
                    onChange={handleSearchChange}
                  />
                </div>

                <div>
                  <Button onClick={toggleBrandModal}>
                    {/* <Button onClick={() => history.push("/admin/addbrand")}> */}
                    Add Brand
                  </Button>
                </div>
              </CardHeader>
              {!isBrandLoading && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Brand Name</th>
                      <th scope="col">Brand Logo</th>
                      <th scope="col">Brand Image</th>
                      <th scope="col">Brand Active</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brands &&
                      brands.map((brand, index) => (
                        <tr key={index}>
                          <td>{brand.id}</td>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {brand.brandName}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>
                            <img
                              src={brand.brandLogo}
                              width={40}
                              height={40}
                              alt="logo"
                            />
                          </td>
                          <td>
                            <img
                              src={brand.brandImage}
                              width={40}
                              height={40}
                              alt="image"
                            />
                          </td>

                          <td>
                            {brand?.brandActive ? (
                              <Badge color="success">active</Badge>
                            ) : (
                              <Badge color="danger">inactive</Badge>
                            )}
                          </td>
                          <td>
                            <Button
                              size="sm"
                              className="btn btn-success"
                              onClick={
                                () => toggleEditBrandModal(brand.id)
                                // history.push(`/admin/editbrand/${brand.id}`)
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              size="sm"
                              className="btn btn-danger"
                              // onClick={() => handleDelete(brand.id)}
                              onClick={() => {
                                setConfirmationId(brand?.id);
                                confirmationToggle();
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
              {isBrandLoading && <Loader />}
              {!isBrandLoading && (
                <CardFooter className="py-4 d-flex justify-content-end">
                  <nav>
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={totalPages}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link "}
                      nextLinkClassName={"pagination__link border "}
                      pageLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={page}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>

        <ConfirmationModal
          isOpen={confirmationModal}
          isToggle={confirmationToggle}
          id={confirmationId}
          handleDeletion={() => {
            dispatch(
              deleteBrand(confirmationId, () => {
                setTimeout(() => {
                  dispatch(setDeleteLoader(false));

                  confirmationToggle();
                  dispatch(
                    fetchBrands(
                      brands.length > 1 ? page : page === 0 ? page : page - 1,
                      hitsPerPage,
                      search
                    )
                  );
                }, 5000);
              })
            );
          }}
        />
      </Container>
    </>
  );
};

export default BrandsPage;
