import {
  FETCH_OFFERS_START,
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_ERROR,
  SUBMIT_OFFER_START,
  SUBMIT_OFFER_SUCCESS,
  SUBMIT_OFFER_ERROR,
  UPDATE_OFFER_START,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_ERROR,
  DELETE_OFFER_START,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_ERROR,
  SET_OFFER_TOTAL_PAGES,
  SET_OFFER_PAGE,
  ADD_OFFER_PAYLOAD,
  UPDATE_OFFER_PAYLOAD,
  FETCH_SEARCH_OFFERS_SUCCESS,
  FETCH_SINGLE_OFFER_SUCCESS,
} from "../types";

const initialState = {
  offers: [],
  searchedOffers: [],
  singleOfferList: [],
  singleOffer: null,
  page: 0,
  totalPages: 0,
  hitsPerPage: 10,
  isLoading: false,
  error: null,
};

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OFFERS_START:
    case SUBMIT_OFFER_START:
    case UPDATE_OFFER_START:
    case DELETE_OFFER_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        offers: action.payload,
      };
    case FETCH_SINGLE_OFFER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        singleOffer: action.payload,
        singleOfferList: [
          ...state.singleOfferList.filter(
            (offer) => offer.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case FETCH_SEARCH_OFFERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchedOffers: action.payload,
      };

    case ADD_OFFER_PAYLOAD:
      return {
        ...state,
        isSubAdminPageLoading: false,
        offers: [action.payload, ...state.offers],
      };
    case UPDATE_OFFER_PAYLOAD:
      return {
        ...state,
        isSubAdminPageLoading: false,
        offers: state.offers.map((offer) =>
          offer.id == action.payload.id ? action.payload : offer
        ),
      };
    case SET_OFFER_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    case SET_OFFER_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case SUBMIT_OFFER_SUCCESS:
    case UPDATE_OFFER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_OFFER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        offers: state.offers.filter((offer) => offer.id !== action.payload),
      };
    case FETCH_OFFERS_ERROR:
    case SUBMIT_OFFER_ERROR:
    case UPDATE_OFFER_ERROR:
    case DELETE_OFFER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default offerReducer;
