import React from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const ConfirmationModal = ({ isOpen, isToggle, id, handleDeletion }) => {
  const { isDeletingInfo } = useSelector((state) => state.globalContent);
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={isToggle} className="pb-0">
        <h2>Confirmation</h2>
      </ModalHeader>

      <ModalBody>
        <h2>Are you sure you want to delete?</h2>
        <div className="d-flex justify-content-end">
          <Button
            className="bg-danger text-white"
            onClick={isToggle}
            disabled={isDeletingInfo}
          >
            No
          </Button>
          <Button
            className="bg-success text-white"
            onClick={handleDeletion}
            disabled={isDeletingInfo}
          >
            {isDeletingInfo ? "Deleting" : "Yes"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
