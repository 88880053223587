import Loader from "components/Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { fetchDropdownData } from "store/actions/dropdownAction";
import { deleteDropdown } from "store/actions/dropdownAction";
import { deletePronoun } from "store/actions/pronounAction";
import { updatePronounData } from "store/actions/pronounAction";
import { fetchPronouns } from "store/actions/pronounAction";
import { submitPronounData } from "store/actions/pronounAction";
import * as Yup from "yup";

const PronounDropdown = ({ show, toggle }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const dispatch = useDispatch();
  const { pronounData, isLoading } = useSelector((state) => state.pronoun);

  const initialValues = {
    pronounOption: isEdit ? editData.pronounOption : "",
  };

  const validationSchema = Yup.object({
    pronounOption: Yup.string().required("Required"),
  });

  const handleCancel = () => {
    toggle();
  };

  const handleSubmit = (values, { resetForm }) => {
    if (isEdit) {
      dispatch(
        updatePronounData({ ...values, id: editData.id }, () => {
          dispatch(fetchPronouns());
          resetForm();
          setIsEdit(false);
          setEditData();
        })
      );
    } else {
      dispatch(
        submitPronounData(values, () => {
          dispatch(fetchPronouns());
          resetForm();
        })
      );
    }
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setEditData(data);
  };

  useEffect(() => {
    dispatch(fetchPronouns());
  }, []);

  return (
    <div>
      <Modal isOpen={show} toggle={toggle}>
        <ModalHeader toggle={toggle}>Pronoun Field</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>
                        <h4>Pronoun Field Dropdown Option</h4>
                      </Label>
                      <Field
                        as={Input}
                        type="text"
                        name="pronounOption"
                        required
                      />
                      <ErrorMessage
                        name="pronounOption"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {pronounData.length > 0 && (
                  <div className="industry-table-body mb-3">
                    <Table>
                      <thead className="thead-light">
                        <tr>
                          <th>Option</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading &&
                          pronounData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.pronounOption}</td>

                              <td>
                                <Button
                                  size="sm"
                                  className="btn btn-success"
                                  onClick={() => handleEdit(item)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn btn-danger"
                                  disabled={isEdit}
                                  onClick={() =>
                                    dispatch(deletePronoun(item.id))
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                )}
                {isLoading && <Loader />}
                <div className="d-flex justify-content-end">
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {isEdit
                      ? formik.isSubmitting
                        ? "Updating"
                        : "Update"
                      : formik.isSubmitting
                      ? "Submitting"
                      : "Submit"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PronounDropdown;
