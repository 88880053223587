import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitDomainData } from "store/actions/domainAction";
import sampleCSV from "assets/domainSample.csv";
import { fetchDomainsData } from "store/actions/domainAction";

const AddDomain = ({
  verify,
  toggleVerify,
  filterBy,
  filterField,
  fetchDomains,
}) => {
  const [activeTab, setActiveTab] = useState("domainName");
  const { isLoading, page, hitsPerPage } = useSelector((state) => state.domain);
  const { dropdownData } = useSelector((state) => state.dropdown);
  const dispatch = useDispatch();
  const handleToggle = () => {
    toggleVerify();
  };
  const initialValues = {
    industrySector: "",
    domainName: "",
    domainFile: null,
    activeTab: "domainName",
  };

  const validationSchema1 = Yup.object().shape({
    industrySector: Yup.string().required("Industry Sector is required"),
    domainName: Yup.string()
      .required("Domain Name is required")
      .matches(
        /^(?:\*(?:\.[a-zA-Z0-9-]+)*\.)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
        "Invalid domain name format"
      ),
  });
  const validationSchema2 = Yup.object().shape({
    domainFile: Yup.mixed()
      .test(
        "fileFormat",
        "Invalid file format. Please upload a .csv or .xlsx file",
        (value) => {
          if (!value) return false; // No file selected
          const allowedFormats = [
            "text/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ];
          return allowedFormats.includes(value.type);
        }
      )
      .test(
        "fileSize",
        "File size must be less than or equal to 1MB",
        (value) => value && value.size <= 1024 * 1024 // 1MB in bytes
      ),
  });

  const handleSubmit = (values) => {
    dispatch(
      submitDomainData(values, () => {
        setTimeout(() => {
          toggleVerify();
          // dispatch(fetchDomainsData(page, hitsPerPage, filterBy, filterField));
          fetchDomains();
        }, 5000);
      })
    );
  };
  return (
    <div>
      <Modal isOpen={verify} toggle={toggleVerify}>
        <ModalHeader toggle={toggleVerify}>Add Domain</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={
              activeTab === "domainName" ? validationSchema1 : validationSchema2
            }
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col sm="8" className="mb-3">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            formik.values.activeTab === "domainName"
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            formik.setFieldValue("activeTab", "domainName");
                            setActiveTab("domainName");
                          }}
                        >
                          Domain Name
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            formik.values.activeTab === "multipleDomains"
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            formik.setFieldValue(
                              "activeTab",
                              "multipleDomains"
                            );
                            setActiveTab("multipleDomains");
                          }}
                        >
                          Multiple Domains
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  <Col md="12">
                    <TabContent activeTab={formik.values.activeTab}>
                      <TabPane tabId="domainName">
                        <Col>
                          <FormGroup>
                            <Label for="industrySector">
                              Select Industry Sector
                            </Label>
                            <Field
                              as="select"
                              id="industrySector"
                              name="industrySector"
                              className="form-control"
                            >
                              <option>Choose</option>
                              {dropdownData.map((item, index) => (
                                <option key={index} value={item.option}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="industrySector"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="domainName">Domain Name</Label>
                            <Field
                              as={Input}
                              type="text"
                              id="domainName"
                              name="domainName"
                              placeholder="Enter Domain Name Here"
                            />
                            <ErrorMessage
                              name="domainName"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                      </TabPane>
                      <TabPane tabId="multipleDomains">
                        <Col>
                          <FormGroup>
                            <Label for="domainFile">Multiple Domains</Label>
                            <Input
                              type="file"
                              id="domainFile"
                              name="domainFile"
                              accept=".csv, .xlsx"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "domainFile",
                                  event.currentTarget.files[0]
                                )
                              }
                            />
                            <ErrorMessage
                              name="domainFile"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12" md="12">
                          <a href={sampleCSV} download="sample.csv">
                            Download Sample CSV
                          </a>
                        </Col>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button onClick={handleToggle}>Cancel</Button>
                  <Button color="primary" type="submit" disabled={isLoading}>
                    {isLoading ? "Submitting" : "Submit"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddDomain;
