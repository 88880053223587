import {
  FETCH_BRANDS_START,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_ERROR,
  SUBMIT_BRAND_START,
  SUBMIT_BRAND_SUCCESS,
  SUBMIT_BRAND_ERROR,
  UPDATE_BRAND_START,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_ERROR,
  DELETE_BRAND_START,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_ERROR,
  FETCH_SINGLE_BRAND_START,
  FETCH_SINGLE_BRAND_SUCCESS,
  FETCH_SINGLE_BRAND_ERROR,
  SET_BRAND_TOTAL_PAGES,
  SET_BRAND_PAGE,
  ADD_BRAND_PAYLOAD,
  FETCH_SEARCH_BRANDS_SUCCESS,
  UPDATE_BRAND_PAYLOAD,
} from "../types";

const initialState = {
  brands: [],
  searchedBrands: [],
  page: 0,
  totalPages: 0,
  hitsPerPage: 10,
  isBrandLoading: false,
  isUpdateLoading: false,
  singleBrand: null,
  singleBrandList: [],
  error: null,
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRANDS_START:
    case FETCH_SINGLE_BRAND_START:
    case SUBMIT_BRAND_START:
    case DELETE_BRAND_START:
      return {
        ...state,
        isBrandLoading: true,
        error: null,
      };
    case UPDATE_BRAND_START:
      return {
        ...state,
        isUpdateLoading: true,
        error: null,
      };

    case FETCH_BRANDS_SUCCESS:
      // if (state.brands.length - 1 === action.payload.length) {
      //   return {
      //     ...state,
      //     isBrandLoading: false,
      //   };
      // } else {
      return {
        ...state,
        isBrandLoading: false,
        brands: action.payload,
      };
    // }
    case FETCH_SEARCH_BRANDS_SUCCESS:
      return {
        ...state,
        isBrandLoading: false,
        searchedBrands: action.payload,
      };
    case ADD_BRAND_PAYLOAD:
      return {
        ...state,
        isSubAdminPageLoading: false,
        brands: [action.payload, ...state.brands],
      };
    case SET_BRAND_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    case SET_BRAND_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case FETCH_SINGLE_BRAND_SUCCESS:
      return {
        ...state,
        isBrandLoading: false,
        singleBrand: action.payload,
        singleBrandList: [
          ...state.singleBrandList.filter(
            (brand) => brand.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case SUBMIT_BRAND_SUCCESS:
    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        isBrandLoading: false,
        isUpdateLoading: false,
        singleBrand: null,
      };
    case UPDATE_BRAND_PAYLOAD:
      return {
        ...state,
        isBrandLoading: false,
        isUpdateLoading: false,
        brands: state.brands.map((brand) =>
          brand.id == action.payload.id ? action.payload : brand
        ),
      };
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        isBrandLoading: false,
        brands: state.brands.filter((brand) => brand.id !== action.payload),
      };
    case FETCH_BRANDS_ERROR:
    case FETCH_SINGLE_BRAND_ERROR:
    case SUBMIT_BRAND_ERROR:
    case UPDATE_BRAND_ERROR:
    case DELETE_BRAND_ERROR:
      return {
        ...state,
        isBrandLoading: false,
        isUpdateLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default brandReducer;
