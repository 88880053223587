export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "2px solid #cad1d7" : "1px solid #cad1d7",
    borderRadius: "4px",
    boxShadow: "none",
    borderRadius: " 0.375rem",
    padding: "2.5px",
    color: "#8898aa",
    outline: "none",
  }),
};
