import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { DropdownIndicator } from "components/DropdownIndicator";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { submitCodeData } from "store/actions/codeAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import sampleCSV from "assets/sampleCSV.csv";
import { customStyles } from "assets/customStyle/reactSelectCustomStyle";
import Select from "react-select";
import { searchOffers } from "store/actions/offerAction";
import { fetchSearchedBrands } from "store/actions/brandAction";
import { fetchCodesData } from "store/actions/codeAction";

const AddCode = ({ AddCodeOpen, addCodeToggle, sortBy, selectedBrand }) => {
  const dispatch = useDispatch();
  const [brandId, setBrandId] = useState("");
  const { isLoading, page, hitsPerPage, progress } = useSelector(
    (state) => state.code
  );
  const { searchedOffers } = useSelector((state) => state.offer);
  const { searchedBrands } = useSelector((state) => state.brand);
  const [selectedOffer, setSelectedOffer] = useState({});

  let dynamicBrandName = searchedBrands.map((brand) => ({
    label: `${brand.brandName} (${brand.id})`,
    value: brand.objectID,
  }));
  let dynamicOffers = searchedOffers.map((offer) => ({
    // label: offer.offerTitle,
    label: `${offer.offerTitle} (${offer.id})`,
    value: offer.id,
  }));

  useEffect(() => {
    dispatch(fetchSearchedBrands("", page, hitsPerPage));
  }, [dispatch]);

  const initialValues = {
    brandName: "",
    offer: "",
    codeType: "",
    generic: "",
    batchFile: "",
    showCodeType: "",
  };

  const validationSchema = Yup.object({
    brandName: Yup.string().required("Required"),
    offer: Yup.string().required("Required"),
    codeType: Yup.string().required("Required"),
    showCodeType: Yup.string().required("Required"),
    batchFile: Yup.mixed().test(
      "fileType",
      "Only CSV files are allowed",
      (value) => {
        if (!value) return true; // If batchFile field doesn't exist, consider it as valid
        return value && value.type === "text/csv";
      }
    ),
  });
  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      submitCodeData(
        values,
        () => {
          setTimeout(() => {
            if (AddCodeOpen) {
              addCodeToggle();
            }
            setBrandId("");
            setSelectedOffer({});
            resetForm();
            dispatch(fetchCodesData(page, hitsPerPage, selectedBrand, sortBy));
          }, 5000);
        },
        () => {
          addCodeToggle();
          setBrandId("");
          setSelectedOffer({});
          resetForm();
        }
      )
    );
  };

  useEffect(() => {
    dispatch(searchOffers(brandId));
  }, [brandId]);

  return (
    <div>
      <Modal isOpen={AddCodeOpen}>
        <ModalHeader
          toggle={() => {
            setBrandId("");
            setSelectedOffer({});
            addCodeToggle();
          }}
        >
          Add Code
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="brandName">Brand Name</Label>
                      <Select
                        styles={customStyles}
                        placeholder="Choose brand"
                        components={{ DropdownIndicator }}
                        options={dynamicBrandName}
                        onChange={(newValue) => {
                          setBrandId(newValue?.value);
                          setFieldValue("brandName", newValue?.value);
                          setFieldValue("offer", "");
                          setSelectedOffer({});
                        }}
                        onInputChange={(searchQuery) => {
                          dispatch(
                            fetchSearchedBrands(searchQuery, page, hitsPerPage)
                          );
                        }}
                      />
                      <ErrorMessage
                        name="brandName"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Offer</Label>
                      <Select
                        styles={customStyles}
                        id="offer"
                        // isClearable={true}
                        value={selectedOffer}
                        components={{ DropdownIndicator }}
                        placeholder="Choose offer"
                        options={dynamicOffers}
                        onChange={(newValue) => {
                          setFieldValue("offer", newValue?.value);
                          setSelectedOffer(newValue);
                        }}
                      />
                      <ErrorMessage
                        name="offer"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Code Type</Label>
                      <Field
                        as="select"
                        className="form-control"
                        name="codeType"
                      >
                        <option defaultValue={0}>Select</option>
                        <option value="generic">Generic</option>
                        <option value="batchFile">Batch</option>
                      </Field>
                      <ErrorMessage
                        name="codeType"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <Label>Show Code Type</Label>
                      <Field
                        as="select"
                        className="form-control"
                        name="showCodeType"
                      >
                        <option defaultValue={0}>Select</option>
                        <option value="qrCode">Qr Code</option>
                        <option value="textCode">Text Code</option>
                      </Field>
                      <ErrorMessage
                        name="showCodeType"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12">
                    <FormGroup>
                      {values.codeType === "generic" && (
                        <>
                          <Label>Generic</Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="generic"
                            required
                          />
                          <ErrorMessage
                            name="generic"
                            component="div"
                            className="text-danger"
                          />
                        </>
                      )}
                      {values.codeType === "batchFile" && (
                        <>
                          <Label className="d-block">Choose File</Label>
                          <Input
                            type="file"
                            name="batchFile"
                            accept=".csv"
                            required
                            onChange={(event) =>
                              setFieldValue(
                                "batchFile",
                                event.currentTarget.files[0]
                              )
                            }
                          />

                          <ErrorMessage
                            name="batchFile"
                            component="div"
                            className="text-danger"
                          />
                        </>
                      )}
                    </FormGroup>
                  </Col>
                  {values.codeType === "batchFile" && (
                    <Col lg="12" md="12">
                      <a href={sampleCSV} download="sample.csv">
                        Download Sample CSV
                      </a>
                    </Col>
                  )}
                </Row>
                <Row className="justify-content-end px-3">
                  <Button
                    color="secondary"
                    onClick={() => {
                      setSelectedOffer("");
                      setBrandId("");
                      addCodeToggle();
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isLoading} color="primary">
                    {isLoading ? "Submitting" : "Submit"}
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddCode;
