import CodeIssue from "components/Globalcontents/CodeIssue";
import Congrgulations from "components/Globalcontents/Congrgulations";
import DefaultGlobalContent from "components/Globalcontents/DefaultGlobalContent";
import Dropdowns from "components/Globalcontents/Dropdown";
import EmailVeify from "components/Globalcontents/EmailVerify";
import ImageVerify from "components/Globalcontents/ImageVerify";
import JoinFree from "components/Globalcontents/JoinFree";
import Login from "components/Globalcontents/Login";
import ManualReview from "components/Globalcontents/ManualReview";
import MyAccountDetails from "components/Globalcontents/MyAccountDetails";
import MyAccountMenu from "components/Globalcontents/MyAccountMenu";
import OTP from "components/Globalcontents/OTP";
import Offer from "components/Globalcontents/Offer";
import VerifiedPage from "components/Globalcontents/VerifiedPage";
import YourDetails from "components/Globalcontents/YourDetails";
import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Card, CardBody, CardHeader, Collapse, Container } from "reactstrap";

const components = [
  {
    id: "14",
    title: "Default Site Content",
    component: <DefaultGlobalContent />,
  },
  { id: "1", title: "Offer", component: <Offer /> },
  { id: "2", title: "Login", component: <Login /> },
  { id: "3", title: "Code Issue", component: <CodeIssue /> },
  { id: "4", title: "Join Free", component: <JoinFree /> },
  { id: "5", title: "Your Details", component: <YourDetails /> },
  { id: "6", title: "Email Verify", component: <EmailVeify /> },
  { id: "7", title: "OTP", component: <OTP /> },
  { id: "8", title: "Image Verify", component: <ImageVerify /> },
  { id: "9", title: "Manual Review", component: <ManualReview /> },
  { id: "10", title: "Verified Page", component: <VerifiedPage /> },
  { id: "11", title: "My Account Menu", component: <MyAccountMenu /> },
  { id: "12", title: "My Account Details", component: <MyAccountDetails /> },
  { id: "13", title: "Dropdowns", component: <Dropdowns /> },
];

const Globalcontent = () => {
  const [open, setOpen] = useState("");

  const toggle = (id) => {
    setOpen((prevOpen) => (prevOpen === id ? "" : id));
  };

  const renderArrow = (id) => {
    if (open === id) {
      return <FaChevronUp />;
    } else {
      return <FaChevronDown />;
    }
  };

  return (
    <div>
      <OnlyHeader />
      <Container className="pb-5 mt--7">
        <Card>
          <CardBody>
            {components.map(({ id, title, component }) => (
              <div key={id}>
                <CardHeader
                  onClick={() => toggle(id)}
                  className="d-flex justify-content-between globalhead"
                >
                  <strong>{title}</strong> {renderArrow(id)}
                </CardHeader>
                <Collapse isOpen={open === id}>{component}</Collapse>
              </div>
            ))}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Globalcontent;
