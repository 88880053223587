import { FETCH_SUBADMINS_SUCCESS } from "store/types";
import { UPDATE_SUBADMIN_SUCCESS } from "store/types";
import { DELETE_SUBADMIN_START } from "store/types";
import { DELETE_SUBADMIN_ERROR } from "store/types";
import { SET_TOTAL_PAGES } from "store/types";
import { FETCH_MEMBERS_START } from "store/types";
import { SET_TOTAL_MEMBER_PAGES } from "store/types";
import { DELETE_MEMBER_SUCCESS } from "store/types";
import { UPDATE_SUBADMIN_PAYLOAD } from "store/types";
import { FETCH_SINGLE_USER_SUCCESS } from "store/types";
import { UPDATE_MEMBER_PAYLOAD } from "store/types";
import { ADD_SUBADMIN_PAYLOAD } from "store/types";
import { SET_MEMBER_PAGE } from "store/types";
import { FETCH_MEMBERS_ERROR } from "store/types";
import { FETCH_MEMBERS_SUCCESS } from "store/types";
import { SET_PAGE } from "store/types";
import { DELETE_SUBADMIN_SUCCESS } from "store/types";
import { UPDATE_SUBADMIN_ERROR } from "store/types";
import { UPDATE_SUBADMIN_START } from "store/types";
import { FETCH_SUBADMINS_ERROR } from "store/types";
import {
  ADD_SUBADMIN_ERROR,
  ADD_SUBADMIN_SUCCESS,
  ADD_SUBADMIN_START,
  FETCH_SUBADMINS_START,
} from "store/types";

const initialState = {
  isSubAdminPageLoading: false,
  subAdmins: [],
  members: [],
  singleUser: null,
  page: 0,
  memberPage: 0,
  totalPages: 0,
  totalMemberPages: 0,
  hitsPerPage: 10,
  error: null,
};

const subAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUBADMIN_START:
    case FETCH_SUBADMINS_START:
    case FETCH_MEMBERS_START:
    case UPDATE_SUBADMIN_START:
    case DELETE_SUBADMIN_START:
      return {
        ...state,
        isSubAdminPageLoading: true,
        error: null,
      };
    case ADD_SUBADMIN_SUCCESS:
    case UPDATE_SUBADMIN_SUCCESS:
      return {
        ...state,
        isSubAdminPageLoading: false,
      };
    case DELETE_SUBADMIN_SUCCESS:
      return {
        ...state,
        isSubAdminPageLoading: false,
        subAdmins: state.subAdmins.filter((user) => user.id !== action.payload),
      };
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        isSubAdminPageLoading: false,
        members: state.members.filter((member) => member.id !== action.payload),
      };
    case FETCH_SUBADMINS_SUCCESS:
      return {
        ...state,
        isSubAdminPageLoading: false,
        subAdmins: action.payload,
      };
    case ADD_SUBADMIN_PAYLOAD:
      return {
        ...state,
        isSubAdminPageLoading: false,
        subAdmins: [...state.subAdmins, action.payload],
      };
    case UPDATE_SUBADMIN_PAYLOAD:
      return {
        ...state,
        isSubAdminPageLoading: false,
        subAdmins: state.subAdmins.map((subAdmin) =>
          subAdmin.id == action.payload.id ? action.payload : subAdmin
        ),
      };
    case FETCH_SINGLE_USER_SUCCESS:
      return {
        ...state,
        isSubAdminPageLoading: false,
        singleUser: action.payload,
      };
    case UPDATE_MEMBER_PAYLOAD:
      return {
        ...state,
        isSubAdminPageLoading: false,
        members: state.members.map((member) =>
          member.id == action.payload.id ? action.payload : member
        ),
      };
    case FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        isSubAdminPageLoading: false,
        members: action.payload,
      };

    case SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    case SET_TOTAL_MEMBER_PAGES:
      return {
        ...state,
        totalMemberPages: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_MEMBER_PAGE:
      return {
        ...state,
        memberPage: action.payload,
      };
    case ADD_SUBADMIN_ERROR:
    case FETCH_SUBADMINS_ERROR:
    case UPDATE_SUBADMIN_ERROR:
    case DELETE_SUBADMIN_ERROR:
    case FETCH_MEMBERS_ERROR:
      return {
        ...state,
        isSubAdminPageLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default subAdminReducer;
