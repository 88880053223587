import React, { useEffect } from "react";
import { Col, Button, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
import { toast } from "react-toastify";

const VerifiedPage = () => {
  const dispatch = useDispatch();
  const { isLoading, verifiedPage, isFetchFunctionLoading } = useSelector(
    (state) => state.globalContent
  );

  useEffect(() => {
    dispatch(fetchGlobalContentData("verifiedPage"));
  }, [dispatch]);

  const initialValues = {
    congratulationsTitle: verifiedPage?.congratulationsTitle || "",
    congratulationsText: verifiedPage?.congratulationsText || "",
    offersButtonText: verifiedPage?.offersButtonText || "",
    accessKiiIDText: verifiedPage?.accessKiiIDText || "",
    myAccountButtonText: verifiedPage?.myAccountButtonText || "",
  };

  const validationSchema = Yup.object({
    congratulationsTitle: Yup.string().required("Required"),
    congratulationsText: Yup.string().required("Required"),
    offersButtonText: Yup.string().required("Required"),
    accessKiiIDText: Yup.string().required("Required"),
    myAccountButtonText: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(
      submitGlobalContentData({ pageName: "verifiedPage", ...values }, () => {
        toast.success("Data Submitted Successfully");
      })
    );
  };

  return (
    <div>
      {isFetchFunctionLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <Form className="p-4">
            <Row className="mt-3">
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Congratulations Title</h4>
                  </Label>
                  <Field type="text" name="congratulationsTitle" as={Input} />
                  <ErrorMessage
                    name="congratulationsTitle"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Congratulations Text</h4>
                  </Label>
                  <Field type="text" name="congratulationsText" as={Input} />
                  <ErrorMessage
                    name="congratulationsText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Offers Button Text</h4>
                  </Label>
                  <Field type="text" name="offersButtonText" as={Input} />
                  <ErrorMessage
                    name="offersButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Access kii ID Text</h4>
                  </Label>
                  <Field type="textarea" name="accessKiiIDText" as={Input} />
                  <ErrorMessage
                    name="accessKiiIDText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>My Account Button Text</h4>
                  </Label>
                  <Field type="text" name="myAccountButtonText" as={Input} />
                  <ErrorMessage
                    name="myAccountButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-4">
              <Button
                type="submit"
                disabled={isLoading}
                className="btn btn-success"
              >
                {isLoading ? "Submitting" : "Submit"}
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default VerifiedPage;
