/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Brandpage from "views/Brandpage";
import Offers from "views/OffersPage";
import Codes from "views/CodesPage";
import ForgetPassword from "views/auth/ForgetPassword";
import Users from "views/Users";
import Members from "views/Members";
import Verifieddomain from "views/Verifieddomain";
import Globalcontent from "views/Globalcontent";
// import AddBrandModal from "views/AddBrandModal";
// import EditBrandModal from "views/EditBrandModal";
import ImageVerification from "views/ImageVerification";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Sub Admins",
    icon: "fa fa-users text-yellow",
    component: Users,
    layout: "/admin",
  },
  // {
  // 	path: '/icons',
  // 	name: 'Icons',
  // 	icon: 'ni ni-planet text-blue',
  // 	component: Icons,
  // 	layout: '/admin',
  // },
  // {
  // 	path: '/maps',
  // 	name: 'Maps',
  // 	icon: 'ni ni-pin-3 text-orange',
  // 	component: Maps,
  // 	layout: '/admin',
  // },
  // {
  // 	path: '/user-profile',
  // 	name: 'User Profile',
  // 	icon: 'ni ni-single-02 text-yellow',
  // 	component: Profile,
  // 	layout: '/admin',
  // },
  {
    path: "/brandspage",
    name: "Brands",
    icon: "fas fa-chart-bar text-warning",
    component: Brandpage,
    layout: "/admin",
  },
  // {
  //   path: "/addbrand",
  //   component: AddBrandModal,
  //   layout: "/admin",
  //   name: "Add Brand",
  //   isMenu: false,
  // },
  // {
  //   path: "/editbrand/:id",
  //   component: EditBrandModal,
  //   layout: "/admin",
  //   name: "Edit Brand",
  //   isMenu: false,
  // },
  {
    path: "/Offers",
    name: "Offers",
    icon: "fas fa-chart-pie text-warning",
    component: Offers,
    layout: "/admin",
  },

  {
    path: "/code",
    name: "Code",
    icon: "fa fa-code text-primary",
    component: Codes,
    layout: "/admin",
  },
  {
    path: "/members",
    name: "Member",
    icon: "fa fa-user text-red",
    component: Members,
    layout: "/admin",
  },
  {
    path: "/imageverification",
    name: "Image Verification",
    icon: "fa fa-user text-red",
    component: ImageVerification,
    layout: "/admin",
  },

  {
    path: "/globalcontent",
    name: "Global Content",
    icon: "fa fa-globe text-green",
    component: Globalcontent,
    layout: "/admin",
  },
  {
    path: "/domain",
    name: "Verified Domain",
    icon: "fa fa-user text-red",
    component: Verifieddomain,
    layout: "/admin",
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    icon: "ni ni-circle-08 text-pink",
    component: ForgetPassword,
    layout: "/auth",
    isMenu: false,
  },
];
export default routes;
