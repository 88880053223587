import {
  FETCH_PRONOUN_START,
  SUBMIT_PRONOUN_START,
  UPDATE_PRONOUN_START,
  DELETE_PRONOUN_ERROR,
  DELETE_PRONOUN_START,
  FETCH_PRONOUN_SUCCESS,
  SUBMIT_PRONOUN_SUCCESS,
  UPDATE_PRONOUN_SUCCESS,
  DELETE_PRONOUN_SUCCESS,
  FETCH_PRONOUN_ERROR,
  SUBMIT_PRONOUN_ERROR,
  UPDATE_PRONOUN_ERROR,
} from "../types";

const initialState = {
  pronounData: [],
  isLoading: false,
  error: null,
};

const pronounReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRONOUN_START:
    case SUBMIT_PRONOUN_START:
    case UPDATE_PRONOUN_START:
    case DELETE_PRONOUN_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_PRONOUN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pronounData: action.payload,
      };
    case SUBMIT_PRONOUN_SUCCESS:
    case UPDATE_PRONOUN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_PRONOUN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pronounData: state.pronounData.filter(
          (pronoun) => pronoun.id !== action.payload
        ),
      };
    case FETCH_PRONOUN_ERROR:
    case SUBMIT_PRONOUN_ERROR:
    case UPDATE_PRONOUN_ERROR:
    case DELETE_PRONOUN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default pronounReducer;
