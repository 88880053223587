import Repository from "./Repository";

export default {
  createUser(payload) {
    return Repository.post("/user", payload);
  },
  updateUser(payload) {
    return Repository.put("/user", payload);
  },
  updateMember(payload) {
    return Repository.post("/user/verify", payload);
  },
  deleteUser(payload) {
    return Repository.delete(`/user/${payload.userId}`);
  },
};
