import React, { useEffect, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader.js";

import {
  Container,
  Row,
  Card,
  CardHeader,
  Table,
  Media,
  Button,
  CardFooter,
  Badge,
  Input,
} from "reactstrap";
import EditMemberModal from "components/Modals/EditMemberModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembersData } from "store/actions/subAdminAction";
import ReactPaginate from "react-paginate";
import Loader from "components/Loader";
import { SET_MEMBER_PAGE } from "store/types";
import { deleteMember } from "store/actions/subAdminAction";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { fetchAllMembersData } from "store/actions/subAdminAction";
import { BsDownload } from "react-icons/bs";

const Members = () => {
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    members,
    isSubAdminPageLoading,
    hitsPerPage,
    memberPage,
    totalMemberPages,
  } = useSelector((state) => state.users);
  const [editMember, setEditMember] = useState(false);
  const [editMemberData, setEditMemberData] = useState();

  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("memberId");

  const searchTypes = [
    {
      name: "Member ID",
      value: "id",
    },
    {
      name: "Last Name",
      value: "lastName",
    },
    {
      name: "Email",
      value: "email",
    },
    {
      name: "Industry Sector",
      value: "industrySector",
    },
  ];
  const editToggle = () => {
    setEditMember(!editMember);
  };

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [confirmationId, setConfirmationId] = useState("");

  const confirmationToggle = () => {
    setConfirmationModal(!confirmationModal);
  };

  useEffect(() => {
    dispatch(fetchMembersData(memberPage, hitsPerPage));
  }, [dispatch, memberPage]);

  function handlePageClick({ selected: selectedPage }) {
    dispatch({ type: SET_MEMBER_PAGE, payload: selectedPage });
  }

  let searchTimeout;

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);

    // Clear any previous timeout to prevent an immediate search
    clearTimeout(searchTimeout);

    // Set a new timeout to call the search function after 500ms (adjust as needed)
    searchTimeout = setTimeout(() => {
      dispatch(fetchMembersData(0, hitsPerPage, value));
    }, 500);
  };

  const getSearchPlaceholder = () => {
    let obj = searchTypes.find((item) => item?.value == searchType);
    if (obj) {
      return obj?.name;
    } else {
      return "";
    }
  };

  const handleExport = () => {
    setIsDownloading(true);
    dispatch(
      fetchAllMembersData(
        (response) => {
          const header =
            "ID,First Name,Last Name,Primary Email,Industry Sector,Verified";

          const csvContent =
            "data:text/csv;charset=utf-8," +
            header +
            "\n" +
            response
              .map(
                (member) =>
                  `${member?.id || ""},${member?.firstName || ""},${
                    member?.lastName || ""
                  },${member?.primaryEmail || ""},${
                    member?.industrySectorDetails?.option || ""
                  },${member?.verified || ""}`
              )
              .join("\n");

          const linkElement = document.createElement("a");
          linkElement.setAttribute("href", encodeURI(csvContent));
          linkElement.setAttribute("download", "Members.csv");
          document.body.appendChild(linkElement);

          linkElement.click();

          document.body.removeChild(linkElement);
          setIsDownloading(false);
        },
        () => {
          setIsDownloading(false);
        }
      )
    );
  };
  return (
    <div>
      <OnlyHeader />
      <EditMemberModal
        editMember={editMember}
        editToggle={editToggle}
        editMemberData={editMemberData}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-start">
                <h3 className="mb-0">Members</h3>
                <div className="d-flex flex-md-row flex-column ml-4">
                  {" "}
                  {/* <Input
                    type="select"
                    value={searchType}
                    onChange={(e) => {
                      setSearchType(e.target.value);
                      if (search != "") {
                        setSearch("");
                      }
                    }}
                  >
                    <option value="" disabled>
                      Search
                    </option>
                    {searchTypes?.map((type) => (
                      <option value={type?.value}>{type?.name}</option>
                    ))}
                  </Input> */}
                  <Input
                    type="text"
                    value={search}
                    placeholder={`Search`}
                    onChange={handleSearchChange}
                  />
                  <Button
                    onClick={() => handleExport()}
                    disabled={isDownloading}
                    className="ml-md-3 ml-2 mt-md-0 mt-3 btn-primary text-nowrap d-flex align-items-center"
                  >
                    {isDownloading ? (
                      "Downloading..."
                    ) : (
                      <>
                        Download All <BsDownload className="ml-1" size={20} />
                      </>
                    )}
                  </Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Primary Email</th>
                    <th scope="col">Industry Sector</th>
                    <th scope="col">Verified</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!isSubAdminPageLoading &&
                    members.map((member, index) => (
                      <tr key={index}>
                        <td>{member.id}</td>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {member.firstName}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {member.lastName}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{member?.primaryEmail}</td>
                        <td>{member?.industrySectorDetails?.option}</td>
                        <td>
                          {member?.verified == "yes" ? (
                            <Badge color="success">verified</Badge>
                          ) : (
                            <Badge color="danger">no verified</Badge>
                          )}
                        </td>

                        <td>
                          <Button
                            size="sm"
                            className="btn btn-success"
                            onClick={() => {
                              editToggle();
                              setEditMemberData(member);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            size="sm"
                            className="btn btn-danger"
                            onClick={() => {
                              setConfirmationId(member?.id);
                              confirmationToggle();

                              // dispatch(
                              //   deleteMember({ userId: member.id }, () => {
                              //     setTimeout(() => {
                              //       dispatch(
                              //         fetchMembersData(
                              //           members.length > 1
                              //             ? memberPage
                              //             : memberPage === 0
                              //             ? memberPage
                              //             : memberPage - 1,
                              //           hitsPerPage
                              //         )
                              //       );
                              //     }, 5000);
                              //   })
                              // );
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {isSubAdminPageLoading && <Loader />}
              {!isSubAdminPageLoading && (
                <CardFooter className="py-4 d-flex justify-content-end">
                  <nav>
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={totalMemberPages}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link "}
                      nextLinkClassName={"pagination__link border "}
                      pageLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={memberPage}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
        <ConfirmationModal
          isOpen={confirmationModal}
          isToggle={confirmationToggle}
          id={confirmationId}
          handleDeletion={() => {
            dispatch(
              deleteMember({ userId: confirmationId }, () => {
                setTimeout(() => {
                  confirmationToggle();
                  dispatch(
                    fetchMembersData(
                      members.length > 1
                        ? memberPage
                        : memberPage === 0
                        ? memberPage
                        : memberPage - 1,
                      hitsPerPage
                    )
                  );
                }, 5000);
              })
            );
          }}
        />
      </Container>
    </div>
  );
};

export default Members;
