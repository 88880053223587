import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitGlobalContentData } from "store/actions/globalContentAction";
import { fetchGlobalContentData } from "store/actions/globalContentAction";
import Loader from "components/Loader";
import { toast } from "react-toastify";

const Offer = () => {
  const dispatch = useDispatch();
  const { isLoading, offer, isFetchFunctionLoading } = useSelector(
    (state) => state.globalContent
  );
  const initialValues = {
    loginButtonText: offer?.loginButtonText || "",
    joinNowButtonText: offer?.joinNowButtonText || "",
    offerTermsTextlink: offer?.offerTermsTextlink || "",
  };

  useEffect(() => {
    dispatch(fetchGlobalContentData("offer"));
  }, [dispatch]);

  const validationSchema = Yup.object({
    loginButtonText: Yup.string().required("Required"),
    joinNowButtonText: Yup.string().required("Required"),
    offerTermsTextlink: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(
      submitGlobalContentData({ pageName: "offer", ...values }, () => {
        toast.success("Data Submitted Successfully");
      })
    );
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(values) => (
          <Form className="p-4">
            <Row className="mt-3">
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Login Button Text</h4>
                  </Label>
                  <Field type="text" name="loginButtonText" as={Input} />
                  <ErrorMessage
                    name="loginButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Join Now Button Text</h4>
                  </Label>
                  <Field type="text" name="joinNowButtonText" as={Input} />
                  <ErrorMessage
                    name="joinNowButtonText"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
              <Col lg="6" xl="4">
                <FormGroup>
                  <Label>
                    <h4>Offer Terms Textlink</h4>
                  </Label>
                  <Field type="text" name="offerTermsTextlink" as={Input} />
                  <ErrorMessage
                    name="offerTermsTextlink"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-4">
              <Button
                type="submit"
                disabled={isLoading}
                className="btn btn-success"
              >
                {isLoading ? "Submitting" : "Submit"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Offer;
