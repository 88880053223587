import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { FETCH_BRANDS_SUCCESS } from "store/types";
import { UPDATE_BRAND_SUCCESS } from "store/types";
import { UPDATE_BRAND_START } from "store/types";
import { DELETE_BRAND_START } from "store/types";
import { DELETE_BRAND_ERROR } from "store/types";
import { DELETE_BRAND_SUCCESS } from "store/types";
import { FETCH_BRANDS_START } from "store/types";
import { UPDATE_BRAND_ERROR } from "store/types";
import { FETCH_BRANDS_ERROR } from "store/types";
import { SUBMIT_BRAND_ERROR } from "store/types";
import { SUBMIT_BRAND_SUCCESS } from "store/types";
import { SUBMIT_BRAND_START } from "store/types";
import { FETCH_SINGLE_BRAND_START } from "store/types";
import { FETCH_SINGLE_BRAND_SUCCESS } from "store/types";
import { FETCH_SINGLE_BRAND_ERROR } from "store/types";
import { brandsIndex } from "./subAdminAction";
import { SET_BRAND_TOTAL_PAGES } from "store/types";
import { toast } from "react-toastify";
import { ADD_BRAND_PAYLOAD } from "store/types";
import { FETCH_SEARCH_BRANDS_SUCCESS } from "store/types";
import { FETCH_BRANDS_START_2 } from "store/types";
import { FETCH_BRANDS_SUCCESS_2 } from "store/types";
import { FETCH_BRANDS_ERROR_2 } from "store/types";
import { UPDATE_BRAND_PAYLOAD } from "store/types";
import { setDeleteLoader } from "./globalContentAction";

// ============CREATE============
export const submitBrandData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: SUBMIT_BRAND_START });
    const res = await brandsIndex.search("", {
      page: 0,
      hitsPerPage: 20,
      filters: `brandName:${data.brandName ? '"' + data.brandName + '"' : ""}`,
    });
    if (res.hits.length > 0) {
      toast.error("Brand name already exists.");
      dispatch({
        type: SUBMIT_BRAND_ERROR,
        payload: "Brand name already exists.",
      });
    } else {
      if (data.brandLogo) {
        const imageFile = data.brandLogo;
        const fileName = data.brandLogo.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("brand logos/" + fileNameWithExtension);
        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
        data.brandLogo = downloadURL;
      }
      if (data.brandImage) {
        const imageFile = data.brandImage;
        const fileName = data.brandImage.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("brand images/" + fileNameWithExtension);
        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
        data.brandImage = downloadURL;
      }

      const createSlug = (brandName) => {
        // const sanitizedBrandName = brandName.toLowerCase().replace(/\s+/g, "-");
        // const slug = sanitizedBrandName.replace(/[^a-z0-9-]/g, "");
        // return slug;
        const sanitizedBrandName = brandName.toLowerCase().replace(/\s+/g, "-");
        const slug = sanitizedBrandName.replace(/[.&'?!-]/g, "-");
        const finalSlug = slug.replace(/-+/g, "-").replace(/^-+|-+$/g, "");
        return finalSlug;
      };

      // Usage:
      const brandName = data.brandName;
      const slug = createSlug(brandName);
      firebase
        .firestore()
        .collection("brands")
        .add({
          slug,
          ...data,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((response) => {
          setTimeout(() => {
            dispatch({ type: SUBMIT_BRAND_SUCCESS });
            toast.success("Brand Created Successfully");
            dispatch({
              type: ADD_BRAND_PAYLOAD,
              payload: { ...data, id: response.id, objectID: response.id },
            });
          }, 5000);
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: SUBMIT_BRAND_ERROR, payload: error });
          toast.error(error?.response?.data?.error || "There was an error");
        });
    }
  };

// =========GET==========
export const fetchBrands = (page, hitsPerPage, searchQuery) => {
  return (dispatch) => {
    dispatch({ type: FETCH_BRANDS_START });

    const search = searchQuery ? `${searchQuery}` : "";

    brandsIndex
      .search(search, {
        page,
        hitsPerPage,
      })
      .then((response) => {
        const brands = [];
        response.hits.forEach((doc) => {
          brands.push({ id: doc.objectID, ...doc });
        });
        dispatch({ type: FETCH_BRANDS_SUCCESS, payload: brands });
        dispatch({ type: SET_BRAND_TOTAL_PAGES, payload: response.nbPages });
      })
      .catch((error) => {
        dispatch({ type: FETCH_BRANDS_ERROR, payload: error });
      });
  };
};
export const fetchQueryBrand = (page, hitsPerPage, filter) => {
  return (dispatch) => {
    dispatch({ type: FETCH_BRANDS_START });
    brandsIndex
      .search("", {
        page,
        hitsPerPage,
        filters: `brandName:${filter}`,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        dispatch({ type: FETCH_BRANDS_ERROR, payload: error });
      });
  };
};

// =========GET FOR ADD OFFER==========
export const fetchSearchedBrands = (searchQuery, page, hitsPerPage) => {
  return (dispatch) => {
    console.log("Search", searchQuery, hitsPerPage, page);
    brandsIndex
      .search(searchQuery, {
        page: 0,
        hitsPerPage,
      })
      .then((response) => {
        const searchedBrands = [];
        response.hits.forEach((doc) => {
          searchedBrands.push({ id: doc.objectID, ...doc });
        });
        dispatch({
          type: FETCH_SEARCH_BRANDS_SUCCESS,
          payload: searchedBrands,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// ==========GET SINGLE BRAND=========
export const fetchSingleBrand = (id, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SINGLE_BRAND_START });
    firebase
      .firestore()
      .collection("brands")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const brand = { id: doc.id, ...doc.data() };
          dispatch({ type: FETCH_SINGLE_BRAND_SUCCESS, payload: brand });
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_SINGLE_BRAND_ERROR, payload: error });
      });
  };
};

// ==========UPDATE==============
export const updateBrandData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_BRAND_START });
    const res = await brandsIndex.search("", {
      page: 0,
      hitsPerPage: 20,
      filters: `brandName:${data.brandName ? '"' + data.brandName + '"' : ""}`,
    });
    if (res.hits.length > 0 && res?.hits[0]?.objectID !== data.id) {
      toast.error("Brand name already exists.");
      dispatch({
        type: UPDATE_BRAND_ERROR,
        payload: "Brand name already exists.",
      });
    } else {
      let details = await firebase
        .firestore()
        .collection("brands")
        .doc(data.id)
        .get();

      let { brandImage, brandLogo } = details.data();

      if (typeof data.brandLogo === "object") {
        if (brandLogo) {
          await firebase.storage().refFromURL(brandLogo).delete();
        }
        const imageFile = data.brandLogo;
        const fileName = data.brandLogo.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("brand logos/" + fileNameWithExtension);
        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
        data.brandLogo = downloadURL;
      }
      if (typeof data.brandImage === "object") {
        if (brandImage) {
          await firebase.storage().refFromURL(brandImage).delete();
        }
        const imageFile = data.brandImage;
        const fileName = data.brandImage.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("brand images/" + fileNameWithExtension);
        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
        data.brandImage = downloadURL;
      }

      const { id, ...updatedData } = data;
      firebase
        .firestore()
        .collection("brands")
        .doc(id)
        .update(updatedData)
        .then(() => {
          dispatch({ type: UPDATE_BRAND_SUCCESS });
          dispatch({
            type: UPDATE_BRAND_PAYLOAD,
            payload: {
              ...updatedData,
              id,
              objectID: id,
            },
          });
          toast.success("Brand Updated Successfully");
          onSuccess();
        })
        .catch((error) => {
          dispatch({ type: UPDATE_BRAND_ERROR, payload: error });
          toast.error(error?.response?.data?.error || "There was an error");
        });
    }
  };

// ==========DELETE=========
export const deleteBrand =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    // dispatch({ type: DELETE_BRAND_START });
    dispatch(setDeleteLoader(true));

    try {
      // const brandAssignedToOffer = await firebase
      //   .firestore()
      //   .collection("offers")
      //   .where("brandName", "==", id)
      //   .get();

      const details = await firebase
        .firestore()
        .collection("brands")
        .doc(id)
        .get();
      const { brandImage, brandLogo } = details.data();

      if (brandImage) {
        await firebase.storage().refFromURL(brandImage).delete();
      }
      if (brandLogo) {
        await firebase.storage().refFromURL(brandLogo).delete();
      }

      await firebase.firestore().collection("brands").doc(id).delete();

      setTimeout(() => {
        // dispatch({ type: DELETE_BRAND_SUCCESS, payload: id });
        dispatch(setDeleteLoader(false));
        toast.success("Brand Deleted Successfully");
      }, 5000);
      onSuccess();

      // if (brandAssignedToOffer.empty) {
      //   const details = await firebase
      //     .firestore()
      //     .collection("brands")
      //     .doc(id)
      //     .get();
      //   const { brandImage, brandLogo } = details.data();

      //   if (brandImage) {
      //     await firebase.storage().refFromURL(brandImage).delete();
      //   }
      //   if (brandLogo) {
      //     await firebase.storage().refFromURL(brandLogo).delete();
      //   }

      //   await firebase.firestore().collection("brands").doc(id).delete();

      //   setTimeout(() => {
      //     // dispatch({ type: DELETE_BRAND_SUCCESS, payload: id });
      //     dispatch(setDeleteLoader(false));
      //     toast.success("Brand Deleted Successfully");
      //   }, 5000);
      //   onSuccess();

      // } else {
      //   dispatch(setDeleteLoader(false));

      //   toast.error(
      //     "This Brand is assigned to one or more offers. Please unassign the brand from the offers before deleting."
      //   );
      // }
    } catch (error) {
      // dispatch({ type: DELETE_BRAND_ERROR, payload: error });
      dispatch(setDeleteLoader(false));

      toast.error(error?.response?.data?.error || "There was an error");
    }
  };
