import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { FETCH_IMG_VERIFICATION_ERROR } from "store/types";
import { FETCH_IMG_VERIFICATION_SUCCESS } from "store/types";
import { FETCH_IMG_VERIFICATION_START } from "store/types";
import { RepositoryFactory } from "repository/RepositoryFactory";
import { UPDATE_VERIFY_IMAGE_START } from "store/types";
import { UPDATE_VERIFY_IMAGE_SUCCESS } from "store/types";
import { UPDATE_VERIFY_IMAGE_ERROR } from "store/types";
import { setDeleteLoader } from "./globalContentAction";
let User = RepositoryFactory.get("subAdmin");

// ============POST=============
export const updateVerifyImage =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VERIFY_IMAGE_START });
      const response = await User.updateMember(data);
      if (response) {
        toast.success(`Image ${data.status} Successfully`);
        dispatch({ type: UPDATE_VERIFY_IMAGE_SUCCESS, response });
        // dispatch({
        //   type: UPDATE_SUBADMIN_PAYLOAD,
        //   payload: { ...data, id: data.userId },
        // });
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: UPDATE_VERIFY_IMAGE_ERROR, error });
      toast.error(error?.response?.data?.error || "There was an error");
    }
  };

// ============GET=============

export const setFetchImageVerificationLoading = (val) => async (dispatch) => {
  dispatch({ type: "SET_GET_IMAGE_VERIFICATION_LOADING", payload: val });
};
export const setFetchMoreImageVerificationLoading =
  (val) => async (dispatch) => {
    dispatch({ type: "SET_GET_MORE_IMAGE_VERIFICATION_LOADING", payload: val });
  };

export const fetchImageVerification = () => async (dispatch) => {
  dispatch(setFetchImageVerificationLoading(true));
  await firebase
    .firestore()
    .collection("imageVerification")
    .where("status", "==", "pending")
    .orderBy("createdAt", "desc")
    .limit(10)
    .onSnapshot(async (query) => {
      let tempVerifications = [];
      for (let doc of query.docs) {
        tempVerifications.push({ id: doc.id, ...doc.data() });
      }
      dispatch({
        type: "GET_IMAGE_VERIFICATION",
        payload: tempVerifications,
      });
      dispatch(setFetchImageVerificationLoading(false));
    });
};

export const fetchMoreImageVerification = (item) => async (dispatch) => {
  dispatch(setFetchMoreImageVerificationLoading(true));
  await firebase
    .firestore()
    .collection("imageVerification")
    .where("status", "==", "pending")
    .orderBy("createdAt", "desc")
    .limit(10)
    .startAfter(item?.createdAt)
    .onSnapshot(async (query) => {
      let tempVerifications = [];
      for (let doc of query.docs) {
        tempVerifications.push({ id: doc.id, ...doc.data() });
      }
      if (tempVerifications?.length > 0) {
        dispatch({
          type: "GET_MORE_IMAGE_VERIFICATION",
          payload: tempVerifications,
        });
      } else {
        toast.warning("No more data to show!");
      }
      dispatch(setFetchMoreImageVerificationLoading(false));
    });
};

export const deleteImageVerification =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(setDeleteLoader(true));
      const details = await firebase
        .firestore()
        .collection("imageVerification")
        .doc(id)
        .get();
      const { fileUrl } = details.data();
      if (fileUrl) {
        await firebase.storage().refFromURL(fileUrl).delete();
      }
      firebase
        .firestore()
        .collection("imageVerification")
        .doc(id)
        .delete()
        .then((res) => {
          dispatch(setDeleteLoader(false));
          toast.success("Image Verification document deleted successfully");
          onSuccess();
        })
        .catch((err) => {
          dispatch(setDeleteLoader(false));

          toast.error(err?.response?.data?.error || "There was an error");
        });
    } catch (error) {
      dispatch(setDeleteLoader(false));

      toast.error(error?.response?.data?.error || "There was an error");
    }
  };
