import {
  FETCH_IMG_VERIFICATION_ERROR,
  FETCH_IMG_VERIFICATION_START,
  FETCH_IMG_VERIFICATION_SUCCESS,
  UPDATE_VERIFY_IMAGE_ERROR,
  UPDATE_VERIFY_IMAGE_START,
  UPDATE_VERIFY_IMAGE_SUCCESS,
} from "../types";

const initialState = {
  imageVerificationData: [],
  lastVisible: null,
  totalCount: 0,
  isLoading: false,
  error: null,
  isGettingData: false,
  isGettingMore: false,
};

const imageVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VERIFY_IMAGE_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case UPDATE_VERIFY_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case UPDATE_VERIFY_IMAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case "GET_MORE_IMAGE_VERIFICATION":
      return {
        ...state,
        imageVerificationData: state.imageVerificationData?.concat(
          action.payload
        ),
      };
    case "GET_IMAGE_VERIFICATION":
      return {
        ...state,
        imageVerificationData: action.payload,
      };

    case "SET_GET_IMAGE_VERIFICATION_LOADING":
      return {
        ...state,
        isGettingData: action.payload,
      };
    case "SET_GET_MORE_IMAGE_VERIFICATION_LOADING":
      return {
        ...state,
        isGettingMore: action.payload,
      };

    default:
      return state;
  }
};

export default imageVerificationReducer;
