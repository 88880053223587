import { SUBMIT_PRONOUN_START } from "store/types";
import firebase from "../../config/firebase";
import { SUBMIT_PRONOUN_SUCCESS } from "store/types";
import { SUBMIT_PRONOUN_ERROR } from "store/types";
import { FETCH_PRONOUN_START } from "store/types";
import { FETCH_PRONOUN_SUCCESS } from "store/types";
import { FETCH_PRONOUN_ERROR } from "store/types";
import { UPDATE_PRONOUN_START } from "store/types";
import { UPDATE_PRONOUN_SUCCESS } from "store/types";
import { UPDATE_PRONOUN_ERROR } from "store/types";
import { DELETE_PRONOUN_START } from "store/types";
import { DELETE_PRONOUN_SUCCESS } from "store/types";
import { DELETE_PRONOUN_ERROR } from "store/types";
import { toast } from "react-toastify";

// ============CREATE============
export const submitPronounData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: SUBMIT_PRONOUN_START });
    firebase
      .firestore()
      .collection("pronounDropdown")
      .add({
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        dispatch({ type: SUBMIT_PRONOUN_SUCCESS });
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: SUBMIT_PRONOUN_ERROR, payload: error });
      });
  };

// =========GET==========
export const fetchPronouns = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PRONOUN_START });
    firebase
      .firestore()
      .collection("pronounDropdown")
      .get()
      .then((querySnapshot) => {
        const pronouns = [];
        querySnapshot.forEach((doc) => {
          pronouns.push({ id: doc.id, ...doc.data() });
        });
        dispatch({ type: FETCH_PRONOUN_SUCCESS, payload: pronouns });
      })
      .catch((error) => {
        dispatch({ type: FETCH_PRONOUN_ERROR, payload: error });
      });
  };
};

// ==========UPDATE==============
export const updatePronounData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_PRONOUN_START });
    const { id, ...updatedData } = data;
    firebase
      .firestore()
      .collection("pronounDropdown")
      .doc(id)
      .update(updatedData)
      .then(() => {
        dispatch({ type: UPDATE_PRONOUN_SUCCESS });
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: UPDATE_PRONOUN_ERROR, payload: error });
      });
  };

// ==========DELETE=========
export const deletePronoun = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_PRONOUN_START });

    let isFoundInUsers = await firebase
      .firestore()
      .collection("users")
      .where("gender", "==", id)
      .get();

    if (isFoundInUsers?.size > 0) {
      toast.warning("This pronoun is used in a user and you cannot delete it");
      dispatch({
        type: DELETE_PRONOUN_ERROR,
        payload: "This pronoun is used in a user and you cannot delete it",
      });
    } else {
      firebase
        .firestore()
        .collection("pronounDropdown")
        .doc(id)
        .delete()
        .then(() => {
          dispatch({ type: DELETE_PRONOUN_SUCCESS, payload: id });
        })
        .catch((error) => {
          dispatch({ type: DELETE_PRONOUN_ERROR, payload: error });
        });
    }
  };
};
